import React, { useState } from 'react'
import BackToPickTypeBtn from './BackToPickTypeBtn'
import SystemSettingsWrapper from '../../settings/system-settings/SystemSettingsWrapper'
import { Trans } from '../../../lang/Trans'
import { useWorkey } from '../../../WorkeyContext'

const EmployerFlow = ({activateUser}) => {
    const { globalState, setGlobalState } = useWorkey()

    const [employerType, setEmployerType] = useState(null)

    const setStatusToUser = () => {
        setGlobalState({
            ...globalState,
            user: {
                ...globalState.user,
                status: "user"
            }
        })
    }

    return (
        employerType ?
            <SystemSettingsWrapper activateUser={activateUser} employerType={employerType} setEmployerType={setEmployerType} />
            :
            <div>
                <div className='top'>
                    <BackToPickTypeBtn callback={() => setStatusToUser()} />
                </div>
                <div className='new-employer-flow-body'>
                    <div className='fun-text my-3'>
                        <div>קודם כל, כל הכבוד לך! לא פשוט לנהל עסק בימינו...</div>
                        <div>בדיוק בשביל זה אנחנו כאן</div>
                    </div>
                    <div>
                        <div className='d-flex flex-row justify-content-center mt-5 text-center'>
                            <div className='card clickable setup-btn mx-5 p-5' onClick={() => setEmployerType('branch')}>
                                <div className='p-2'>
                                    <div><Trans keys={"branch"} /></div>
                                    <div className='btn-explain'><Trans keys={"branch_explain"} /></div>
                                </div>
                            </div>
                            <div className='card clickable setup-btn mx-5 p-5' onClick={() => setEmployerType('corporation')}>
                                <div className='p-2'>
                                    <div><Trans keys={"corporation"} /></div>
                                    <div className='btn-explain'><Trans keys={"corporation_explain"} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default EmployerFlow