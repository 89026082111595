import React, { useState } from 'react'
import './UserActive.css'
import { makeApiRequest, userDisplayName } from '../../../utils/utils'
import { useWorkey } from '../../../WorkeyContext'
import ProfileAvatarCard from '../profile/profile-avatar-card/ProfileAvatarCard'
import ProfileInfoCard from '../profile/profile-info-card/ProfileInfoCard'
import { Trans } from '../../../lang/Trans'
import User from '../../../utils/user_utils'
import ProfileAuthorizedActionsCard from '../profile/profile-authorized-actions-card/ProfileAuthorizedActionsCard'
import ProfileSchedulingSettings from '../profile/profile-scheduling-settings/ProfileSchedulingSettings'
import BranchManagement from '../../user/BranchManagement'
import WorkDisplay from '../../user/WorkDisplay'

const UserActive = () => {
    const { globalState, setGlobalState } = useWorkey()
    const [infoEditMode, setInfoEditMode] = useState(false)
    const [avatarEditMode, setAvatarEditMode] = useState(false)
    const userUtils = new User(globalState)

    const onSaveScheduling = (user) => {
        setGlobalState({ ...globalState, user })
    }

    return (
        <div className='user-active-page-layout'>
            <div className='user-sections'>

                <div className='large-sections'>
                    <div className='profile-info-container'>
                        <ProfileInfoCard
                            showEdit={true}
                            user={globalState.user}
                        />
                    </div>
                    {/* <div className='branch-settings-for-user-container card'>
                        <div className='sub-section'>
                            <ProfileSchedulingSettings
                                onSaveCallback={onSaveScheduling}
                                user={globalState.user}
                            />
                        </div>
                    </div> */}
                    {/*
                        <div className='card mt-2'>
                    </div> */}
                </div>
                <div className={`profile-avatar-container card ${avatarEditMode ? "show-avatar" : ''}`}>
                    <div>
                        <ProfileAvatarCard
                            avatarType={'avatar'}
                            user={globalState.user}
                            showEdit={true}
                        />
                        <div className={`hide-avatar-btn ${avatarEditMode ? 'show-avatar' : ''}`}>
                            <div className={`btn btn-sm btn-app-light-blue w-50 algin-self-center mx-auto`} onClick={() => setAvatarEditMode(false)}>
                                <Trans keys={'hide'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`btn btn-sm btn-app-blue mx-2 avatar-btn ${!avatarEditMode ? 'show-avatar' : ''}`} onClick={() => setAvatarEditMode(true)}>
                    <Trans keys={'want_to_change_image'} />
                </div>

            </div>
            {(userUtils.allowedTo('manage_employee') || userUtils.allowedTo('manage_schedule')) && <div className='manegers-sections mt-2'>
                {userUtils.allowedTo('manage_schedule') && <div className='card manage-scheduling  p-3'>
                    <ProfileSchedulingSettings
                        onSaveCallback={onSaveScheduling}
                        user={globalState.user}
                        />
                </div>}
            </div>}
        </div >
    )
}

export default UserActive