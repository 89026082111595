import { Route, Routes } from "react-router-dom"
import Dashboard from "./components/dashboard/Dashboard"
import PageNotFound from "./components/page_not_found/PageNotFound"
import { isNewUser } from "./utils/utils"
import Structure from "./components/schedule/structure/Structure"
import { Login } from "./components/login/Login"
import Unauthorized from "./components/unauthorized/Unauthorized"
import { useWorkey } from "./WorkeyContext"
import WelcomeNewUser from "./components/welcome/welcome-new-user/WelcomeNewUser"
import SystemSettingsWrapper from "./components/settings/system-settings/SystemSettingsWrapper"
import User from "./utils/user_utils"
import QuickInviteEmployee from "./components/dashboard/cards/widgets/QuickInviteEmployee"
import RolesList from "./components/settings/employees-settings/roles-list/RolesList"
import EmployeesList from "./components/settings/employees-settings/employees-list/EmployeesList"

import UserLayout from "./components/user-page/user-layout/UserLayout"
import SmartSchedule from "./components/schedule/smart-schedule/SmartSchedule"
import PriorityRequests from "./components/schedule/requests/PriorityRequests"
import ReplacementsPage from "./components/schedule/replacements/ReplacementsPage"
import ScheduleManagerDashboard from "./components/schedule/dashboards/ScheduleManagerDashboard"
import ScheduleEmployeeDashboard from "./components/schedule/dashboards/ScheduleEmployeeDashboard"
import ContactUs from "./components/contact-us/ContactUs"
import Wages from "./components/wages/Wages"
import BranchSettingsPage from "./components/settings-pages/branch/BranchSettingsPage"
import AdminDashboard from "./components/admin/AdminDashboard"
import BranchInfo from "./components/settings/branch-page/BranchInfo"
import BranchPage from "./components/settings/branch-page/BranchPage"
import PlanPicker from "./components/plan-picker/PlanPicker"

const AppRoutes = () => {
  const { globalState } = useWorkey();
  const userUtils = new User(globalState);

  const isLogged = () => !!globalState?.user?.id;

  const dynamicRoutes = () => {
    return routes_objects.map((route) => {
      let { protection_names, element, key, path } = route;
      let userPermissionStatus = 0;
      
      if (protection_names) {
        if (protection_names[0]) {
          if (!isLogged()) {
            userPermissionStatus = 1;
          } else {
            if (protection_names [0] !== 'login' && !protection_names.some(name => userUtils.allowedTo(name))) {
              userPermissionStatus = 2;
            }
            
          }
        }
      }

      switch (userPermissionStatus) {
        case 1:
          element = <Login protectedRoute={true} />
          break;
        case 2:
          element = <Unauthorized />;
          break;
      }

      return <Route key={key} path={path} element={element} />;
    });
  };

  const comingSoon = <div className="card m-5 p-5 text-center fw-bold">Comming Soon</div>

  const adminRoutes = [
    {
      key: "route_/admin",
      path: "/admin",
      element: <AdminDashboard/>,
      protection_names: ["sys_admin"],
    },
  ]
  const routes_objects = [
    ...adminRoutes,
    {
      key: "route_/",
      path: "/",
      element: isLogged() ? <Dashboard /> : <Login />,
    },
    {
      key: "route_/contact-us",
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      key: "route_/login",
      path: "/login",
      element: <Login />,
    },
    {
      key: "route_/login/admin",
      path: "/login/admin",
      element: <Login />,
      protection_names: ["sys_admin"],
    },
    {
      key: "route_/login/join/code",
      path: "/login/join/code?",
      element: <Login />,
    },
    {
      key: "route_/schedule",
      path: "/schedule",
      element: <SmartSchedule edit={false} />,
      protection_names: ["view_schedule"],
    },
    {
      key: "route_/schedule/control",
      path: "/schedule/control",
      element: <SmartSchedule edit={false} />,
      protection_names: ["manage_schedule"],
    },
    {
      key: "route_/schedule/dashboard",
      path: "/schedule/dashboard",
      element: <ScheduleEmployeeDashboard />,
      protection_names: ["view_schedule"],
    },
    {
      key: "route_/schedule/settings",
      path: "/schedule/settings",
      element: comingSoon,
      protection_names: ["manage_schedule"],
    },
    {
      key: "route_/schedule/requests",
      path: "/schedule/requests",
      element: <PriorityRequests />,
      protection_names: ["view_schedule"],
    },
    {
      key: "route_/schedule/structure/create",
      path: "/schedule/structure/create",
      element: <Structure />,
      protection_names: ["manage_schedule"],
    },
    {
      key: "route_/schedule/edit",
      path: "/schedule/edit",
      element: <SmartSchedule edit={true} />,
      protection_names: ["manage_schedule"],
    },
    {
      key: "route_/schedule/replacements",
      path: "/schedule/replacements",
      element: <ReplacementsPage />,
      protection_names: ["view_schedule"],
    },
    {
      key: "route_/settings/schedule",
      path: "/settings/schedule",
      element: comingSoon,
      protection_names: ["manage_schedule"],
    },
    {
      key: "route_/settings/user",
      path: "/settings/user",
      element: comingSoon,
      protection_names: ["manage_schedule"],
    },
    {
      key: "route_/settings/branch",
      path: "/settings/branch",
      element: <BranchSettingsPage />,
      protection_names: ["branch_manager"],
    },
    {
      key: "route_/settings/branch/account",
      path: "/settings/branch/account",
      element: <PlanPicker />,
      protection_names: ["branch_manager"],
    },
    {
      key: "route_/settings/new",
      path: "/settings/new",
      element: isNewUser(globalState) ? <WelcomeNewUser /> : <SystemSettingsWrapper />,
      protection_names: ["login"],
    },
    {
      key: "route_/employees",
      path: "/employees",
      element: isNewUser(globalState) ? <WelcomeNewUser /> : <EmployeesList />,
      protection_names: ["manage_employee"],
    },
    {
      key: "route_/employees/roles",
      path: "/employees/roles",
      element: isNewUser(globalState) ? <WelcomeNewUser /> : <RolesList />,
      protection_names: ["manage_employee"],
    },
    {
      key: "route_/employees/wages",
      path: "/employees/wages",
      element: <Wages />,
      protection_names: ["manage_employee", "manage_schedule", "view_schedule"],
    },
    {
      key: "route_/user/create",
      path: "/user/create",
      element: <UserLayout />,
      protection_names: ["manage_employee"],
    },
    {
      key: "route_/user/invite",
      path: "/user/invite",
      element: <QuickInviteEmployee widget={false} />,
      protection_names: ["manage_employee"],
    },
    {
      key: "route_/user",
      path: "/user/:userId",
      element: isNewUser(globalState) ? <WelcomeNewUser /> : <UserLayout />,
      protection_names: ["login"],
    },
    {
      key: "route_/*",
      path: "*",
      element: <PageNotFound />,
    },
  ];



  return <Routes>{dynamicRoutes()}</Routes>;
};

export default AppRoutes