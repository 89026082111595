import React from 'react'
import './ShiftSettings.css'
import { Trans, trans } from '../../../../lang/Trans'

const ShiftSettings = ({ setShowShiftSettings }) => {
    return (
        <div className='shift-settings card p-2'>
            ShiftSettings
            <div className='card-footer'>
                <div className='btn btn-sm btn-danger' onClick={() => setShowShiftSettings(false)}>
                    <Trans keys={"cancel"} />
                </div>
                <div className='btn btn-sm btn-success'>
                    <Trans keys={"save"} />
                </div>
            </div>
        </div>
    )
}

export default ShiftSettings