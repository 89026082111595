import React, { useEffect, useState } from 'react'
import './SideTools.css'
import { useWorkey } from '../../../../../WorkeyContext'
import { IconSize, apiReq, makeApiRequest, userDisplayName } from '../../../../../utils/utils'
import { Trans } from '../../../../../lang/Trans'
import { rebuildWeek } from '../../../../../utils/week_utils'
import EmployeeSideTool from './EmployeeSideTool'
import { Icon } from '@mdi/react';
import { mdiMenuDown, mdiMenuLeft } from '@mdi/js'
import ScheduleSideBtns from './schedule-side-tools/ScheduleSideBtns'
import { toast } from 'react-hot-toast'
import _ from 'lodash'

const SideTools = ({ currentWeek, setCurrentWeek, selectedSlotId, setSelectedSlotId, employeeWatch, setEmployeeWatch, edit }) => {
  const { globalState, setGlobalState } = useWorkey()
  const [folded, setFolded] = useState(true)
  const [slot, setSlot] = useState()

  useEffect(() => {
    setSlot(currentWeek?.slots.find(slot => slot.id == selectedSlotId))
    if (selectedSlotId) {
      setFolded(false)
    }
  }, [selectedSlotId])

  const employeesList = () => {
    if (folded) { return [] }
    let list = {}
    let html = []
    globalState.user.branch?.roles.sort((a, b) => a.order - b.order)?.forEach(role => {
      list[role.name] = globalState.user.branch?.employees.filter(emp => emp.main_role?.id == role.id)
    })
    list['general'] = globalState.user.branch?.employees.filter(emp => !emp.main_role)
    let roles_names = Object.keys(list)
    for (let index = 0; index < roles_names.length; index++) {
      html.push(<div className='fw-bold app-yellow-txt'>{roles_names[index] == 'general' ? <Trans keys={"general"} /> : roles_names[index]}</div>)
      const role_users_list = list[roles_names[index]];
      if (role_users_list.length == 0) {
        html.push(<><Trans keys={'empty'} /></>)

      } else {
        role_users_list.sort((a, b) => userDisplayName(a).localeCompare(userDisplayName(b), "he")).map(employee => {

          html.push(<EmployeeSideTool
            edit={edit}
            currentWeek={currentWeek}
            setCurrentWeek={setCurrentWeek}
            selectedSlotId={selectedSlotId}
            slot={slot}
            employee={employee}
            sideToolAssginUserToSlot={sideToolAssginUserToSlot}
            employeeWatch={employeeWatch}
            setEmployeeWatch={setEmployeeWatch} />)
        })

      }
    }
    return html
  }

  const sideToolAssginUserToSlot = async (employee) => {
    if (!edit) {
      return
    }
    // If the object type is 'employee', update the relevant slot with the object ID
    let current_week = _.clone(currentWeek) ;
    // if (object_type === 'employee') {
    let slotIndex = current_week.slots.findIndex(slot => slot?.id === selectedSlotId);
    current_week.slots[slotIndex] = {
      ...current_week.slots[slotIndex],
      user_id: employee?.id
    }
    let slot_data = { ...current_week.slots[slotIndex] }
    delete slot_data['shift']
    delete slot_data['day']
    // const response = await apiReq(globalState, `/slots/${slot.id}`, "PUT", {}, slot_data)
    // let res
    // await response.json().then(data => {
    //   res = data
    // }).catch((err) => {
    //   console.error(err)
    //   res = err
    // });
    // if (response.status === 201 || response.status === 200) {
      // }
      current_week = await rebuildWeek(current_week, globalState);
      setCurrentWeek(current_week);
      setSelectedSlotId(false);
    // } else {
    //   toast.error(<Trans keys={'server_errors.could_not_update'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
    // }
    // }
  };

  const toggleFold = () => {
    if (folded) {
      setGlobalState({ ...globalState, largeSidebar: false })
    }
    setFolded(!folded)
  }


  return (
    <div className={`schedule-users ${folded ? "sm" : "xl"}`}>
      <ScheduleSideBtns
      currentWeek={currentWeek}
        folded={folded}
        edit={edit}
        sideToolAssginUserToSlot={sideToolAssginUserToSlot}
        selectedSlotId={selectedSlotId}
        setSelectedSlotId={setSelectedSlotId}
        employeeWatch={employeeWatch}
        setEmployeeWatch={setEmployeeWatch} />
      <div className='header'>
        <div onClick={() => toggleFold()} className='mx-2 pointer text-light'>
          <Icon path={folded ? mdiMenuLeft : mdiMenuDown} size={IconSize} />
        </div>
      </div>
      <div className='body'>
        <div className='schedule-users-list'>
          {employeesList()}

        </div>
      </div>
    </div>
  )
}

export default SideTools