import React, { useEffect, useState } from 'react';
import './Wages.css';
import { Trans } from '../../lang/Trans';
import moment from 'moment';
import { displayTime, makeApiRequest, userDisplayName } from '../../utils/utils';
import { useWorkey } from '../../WorkeyContext';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import Tip from './Wage';
import SlotTip from './tips/SlotTip';
import { useLocation } from 'react-router-dom';
import Wage from './Wage';
import Icon from '@mdi/react';
import { mdiCurrencyIls } from '@mdi/js';
import UnavailableFeature from '../blocks-ui/UnavailableFeature';

const Wages = () => {
  const { globalState, setGlobalState } = useWorkey();
  const [wages, setWages] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(globalState.user.id);
  const [selectedWageType, setSelectedWageType] = useState('tips');
  const location = useLocation();
  // useEffect(() => {
  //   getWages();
  // }, []);

  const getWages = async () => {
    const req = await makeApiRequest(globalState, setGlobalState, `/wages/${selectedEmployeeId}/${selectedWageType}/${startDate}/${endDate}/`, 'GET');
    const data = req[0];
    const res = req[1];
    if (res.status === 200 && data) {
      setWages(data.filter(wage => wage.wage_type !== 'didaction' && wage.user_id).filter(wage => wage.wage_percent != 0));
    } else {
      toast.error(res.statusText);
    }
  };

  const options = globalState.user.branch.employees.map((employee) => ({
    value: employee.id,
    label: userDisplayName(employee, true),
  }));

  const handleSelectChange = (selectedOption) => {
    setSelectedEmployeeId(selectedOption.value);
  };

  const handleWageTypeChange = (e) => {
    setSelectedWageType(e.target.value);
  };

  const renderWages = () => {
    return wages.map((wage) => {
      return <Wage key={`wage_${wage.id}`} wage={wage} wages={wages} setWages={setWages} />
      // return (<div key={wage.id}>{JSON.stringify(wage)}</div>);
    })
  }

  if (!globalState.user.branch?.active_subscriptions.find(subscription => subscription.subscription_type === 'wages')) {
    return <div>
      <UnavailableFeature />
    </div>
  }

  return (
    <div className='wages-container'>
      <div className='card'>
        <div className='title'><Trans keys={'wages.title'} /></div>
        <div className='wages-filters'>
          <div className='filters'>
            <div className='top-section'>
              <div className='employee-filter'>
                <div className='employee-select'>
                  <div className='label'><Trans keys={'wages.for_employee'} />: </div>
                  <Select
                    className='action-as'
                    value={options.find((option) => option.value === selectedEmployeeId)}
                    options={[{ value: null, label: <Trans keys={'wages.all'} /> }, ...options]}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <div className='wage-type-filter'>
                <div className='wage-type-select'>
                  <div className='label'><Trans keys={'wages.wage_type'} />: </div>
                  <div className='radio-group'>
                    <label>
                      <input type='radio' value='all' checked={selectedWageType === 'all'} onChange={handleWageTypeChange} />
                      <Trans keys={'wages.all'} />
                    </label>
                    <label>
                      <input type='radio' value='tips' checked={selectedWageType === 'tips'} onChange={handleWageTypeChange} />
                      <Trans keys={'wages.tips'} />
                    </label>
                    <label>
                      <input type='radio' value='salery' checked={selectedWageType === 'salery'} onChange={handleWageTypeChange} />
                      <Trans keys={'wages.salery'} />
                    </label>
                  </div>
                </div>
              </div>

            </div>
            <div className='dates-filter'>
              <div className='date-select'>
                <div className='label'><Trans keys={'wages.from_date'} />:</div> <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className='form-control' />
              </div>
              <div className='date-select'>
                <div className='label'><Trans keys={'wages.to_date'} />: </div><input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className='form-control' />
              </div>
            </div>
          </div>
          <div className='btn btn-app-blue' onClick={getWages}><Trans keys={'show'} /></div>

        </div>
        <div className='wages-list'>
          {wages.length > 0 && <div className='tips-list-container'>
            <table class="table table-striped table-hover">
              <thead>
                <tr className="text-right">
                  <th className='col'></th>
                  <th className='col'></th>
                  <th className='col'></th>
                  <th className='col'></th>
                  <th className='col'></th>
                  <th className='col'></th>
                  <th className='col'></th>
                  <th className='col'>{displayTime(wages.reduce((a, b) => a + b.total_hours, 0))}</th>
                  <th className='col'>{(Number(wages.reduce((a, b) => a + Number(b.total_wage), 0)).toFixed(2) / wages.reduce((a, b) => a + b.total_hours, 0))} <Icon path={mdiCurrencyIls} size={0.5} /></th>
                  <th className='col'>{(Number(wages.reduce((a, b) => a + Number(b.total_wage), 0)).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} <Icon path={mdiCurrencyIls} size={0.5} /> </th>
                  <th className='col'></th>
                </tr>
                <tr className="text-right">
                  <th className='col'><Trans keys={'date'} /></th>
                  <th className='col'><Trans keys={'name'} /></th>
                  <th className='col'><Trans keys={'wages.wage_type'} /></th>
                  <th className='col'><Trans keys={'role'} /></th>
                  <th className='col'><Trans keys={'wage_percent'} /></th>
                  <th className='col'><Trans keys={'work_time_start'} /></th>
                  <th className='col'><Trans keys={'work_time_end'} /></th>
                  <th className='col'><Trans keys={'total_work_time'} /></th>
                  <th className='col'><Trans keys={'total_per_hour'} /></th>
                  <th className='col'><Trans keys={'total'} /></th>
                  <th className='col'><Trans keys={'actions'} /></th>
                </tr>
              </thead>
              <tbody>
                {renderWages()}
              </tbody>
            </table>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Wages;
