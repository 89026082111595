import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, FormLabel, FormControl, Badge } from 'react-bootstrap';
import { useWorkey } from '../../../WorkeyContext';
import { makeApiRequest, userDisplayName } from '../../../utils/utils';
import { Trans } from '../../../lang/Trans';
import BranchInfo from './BranchInfo';
import BranchActions from './BranchActions';
import './BranchPage.css'
import User from '../../../utils/user_utils';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import PlanPicker from '../../plan-picker/PlanPicker';
import { getBranchData } from '../../../utils/data_utils';
const BranchPage = ({ activateUser }) => {
    const { globalState, setGlobalState } = useWorkey();
    const userUtils = new User(globalState);
    const navigate = useNavigate();
    const [branchData, setBranchData] = useState(globalState.user?.branch || {
        name: '',
        company_number: '',
        city: '',
        owner_id: globalState.user?.id,
        corporation_id: null,
    });
    const [isEditable, setIsEditable] = useState(false);
    const [errors, setErrors] = useState({});
    
    useEffect(() => {
        if (globalState.user.branch_id && !globalState.user.branch) {
            getBranchData(globalState, setGlobalState, true).then((branch) => {
                if (branch) {
                    setBranchData(branch);
                    setGlobalState({
                        ...globalState,
                        user: {
                            ...globalState.user,
                            branch: branch
                        }
                    })
                }
            })
        }
    }, [globalState.user.branch_id]);

    useEffect(() => {
        setBranchData({
            ...branchData,
            name: globalState.user.branch?.name || '',
            company_number: globalState.user.branch?.company_number || '',
            city: globalState.user.branch?.city || globalState.user.city || '',
            ownerName: globalState.user.branch?.employees?.find(employee => employee.id === globalState.user.branch.owner_id)?.name || userDisplayName(globalState.user, true) || '',
            corporationName: globalState.user.corporation?.name || '',
        });
    }, [globalState.user.branch?.id]);

    const handleEditClick = () => {
        setIsEditable(true);
    };
    // if(!globalState.user) {
    //     navigate('/')
    //     return null;
    // }

    const validateBranchData = (branchData) => {
        const errors = {};

        if (!branchData.name) errors.name = 'Name is required.';
        else if (branchData.name.length < 3) errors.name = 'Name Must be at least 3 characters.';

        if (!branchData.company_number) errors.company_number = 'Company number is required.';
        else if (branchData.company_number.length !== 10) errors.company_number = 'Company number must be 10 digits.';

        if (!branchData.city) errors.city = 'City is required.';
        else if (branchData.city.length < 3) errors.city = 'City must be at least 3 characters.';

        return errors;
    };

    const getFormattedErrors = (errors) => {
        const validErrorNames = ['name', 'company_number', 'city', 'owner', 'corporation'];

        const formattedErrors = Object.entries(errors)
            .filter(([key, value]) => validErrorNames.some(name => key.includes(name)))
            .reduce((acc, [key, value]) => ({
                ...acc,
                [validErrorNames.find(name => key.includes(name))]: value
            }), {});

        return formattedErrors;
    };


    const handleSaveClick = async () => {
        const errors = validateBranchData(branchData);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsEditable(false);
            const branch = {
                id: branchData.id,
                name: branchData.name,
                company_number: branchData.company_number,
                city: branchData.city,
                owner_id: branchData.owner_id,
            };

            const method = branch?.id ? 'PUT' : 'POST';
            const path = method === 'PUT' ? `branches/${branch.id}` : '/branches';
            const req = await makeApiRequest(globalState, setGlobalState, path, method, branch);
            const res = req[1]
            const data = req[0]
            if (res.status !== 201 && res.status !== 200) {
                let new_errors = getFormattedErrors(res)
                if (new_errors && Object.keys(new_errors).length > 0) {
                    // TODO if the errors are only for owner and corporation and
                    setErrors(new_errors)
                } else {
                    setErrors({ general: <Trans keys={"error"} /> })
                };
                toast.error(data.error || res.statusText)
            } else {
                if (data.id) {
                    let user = { ...globalState.user }
                    user['branch'] = data
                    user['branch_id'] = data.id
                    user['status'] = user.status == new User().status_list['normal'] ? new User().status_list['normal'] : new User().status_list['completed_branch_setup']
                    user['data']['authorized_actions'] = Object.keys(user['data']['authorized_actions']).reduce((acc, key) => {
                        acc[key] = key != "own_branch";
                        return acc;
                    }, {});
                    await setBranchData(user.branch)
                    await setGlobalState({ ...globalState, user });
                    return <Link to='/' />;
                } else {
                    toast.error(data.error ||<Trans keys={'toast.feild_to_retrive.branch_data'} />);
                }
            }
        }
    };

    const handleCancelClick = () => {
        setBranchData({
            ...branchData,
            name: globalState.user.branch?.name || '',
            company_number: globalState.user.branch?.company_number || '',
            city: globalState.user.branch?.city || globalState.user.city || '',
            ownerName: globalState.user.branch?.employees.find(employee => employee.id === globalState.user.branch.owner_id)?.name || userDisplayName(globalState.user, true) || '',
            corporationName: globalState.user.corporation?.name || '',
        });
        setIsEditable(false);
    };

    const completedBranchSetup = () => {
        activateUser()
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBranchData({ ...branchData, [name]: value });
    };

    if (false && globalState.user.plan === 'free') {
        return <PlanPicker />
    }

    return (
        <Form className='branch-page d-flex flex-column'>
            <div className='d-flex branch-page-layout'>
                <BranchInfo branch={branchData} isEditable={isEditable} setBranchData={setBranchData} handleChange={handleChange} errors={errors} />
                {globalState.user.branch && <BranchActions />}
            </div>

            {errors.general && <div className="app-red-txt">{errors.general}</div>}
            <div className="d-flex justify-content-center mt-5">
                {isEditable ? (
                    <>
                        <Button className="mx-2" variant="app-red" onClick={handleCancelClick}>
                            <Trans keys={"cancel"} />
                        </Button>
                        <Button className="mx-2" variant="app-green" onClick={handleSaveClick}>
                            <Trans keys={"save"} />
                        </Button>
                    </>
                ) : (
                    <>
                        {globalState.user.branch && globalState.user.status !== userUtils.status_list['normal'] ?
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <Button className="" variant="app-green" onClick={completedBranchSetup}>
                                    <Trans keys={"complete_branch_setup_btn_text"} />
                                </Button>
                                {/* <Trans keys={'you_can_change_it_later'} /> */}
                            </div>
                            :
                            <Button className="mx-2" variant="app-light-blue" onClick={handleEditClick}>
                                <Trans keys={"edit"} />
                            </Button>
                        }
                    </>
                )}
            </div>
        </Form>
    );
};

export default BranchPage;