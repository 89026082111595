import React from 'react'
import { useWorkey } from '../../../WorkeyContext'
import User from '../../../utils/user_utils'

const Widget = ({title='וודיגט',callback, selected, widgetComponent, className = ''}) => {
    const {globalState} = useWorkey()
    const userUtils = new User(globalState.user)
    return (
        <div className={className} onClick={() => callback()}>
            <div>{title}</div>
            <div className='widget-component'>{widgetComponent}</div>
        </div>
    )
}

export default Widget