import React, { useEffect } from 'react'
import './Login.css'
import { makeApiRequest } from '../../utils/utils'
import { useState } from 'react'
import { Link, redirect, useLocation, useNavigate } from 'react-router-dom'
import { Trans } from '../../lang/Trans'
import { useWorkey } from '../../WorkeyContext'
// import { tuotrialList } from '../../utils/tutorial/tutorial'
import jwt_decode from 'jwt-decode';
import logo from '../../assets/images/logo.svg'
import CustomBtn from '../custome-btn/CustomBtn'
import { mdiAlert, mdiLogin } from '@mdi/js'
import { toast } from 'react-hot-toast'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

export const Login = ({ protectedRoute = false }) => {
    const { globalState, setGlobalState } = useWorkey()
    const location = useLocation()
    const navigate = useNavigate()

    const [fakeEmail, setFakeEmail] = useState('')
    const [password, setPassword] = useState('')
    const [invetationCode, setInvetationCode] = useState(null)
    const [consent, setConsent] = useState(false)
    const [companyName, setCompanyName] = useState(false)

    if (location.pathname.includes('login') && !location.pathname.includes('admin')) {
        navigate('/')
    }

    useEffect(() => {
        if (location.pathname.includes('login/join/code')) {
            if (location.search) {
                getCodeData()
            }
        }

    }, [])

    const getCodeData = async () => {
        // return
        let code = location.search.split('?')[1]
        const req = await makeApiRequest(globalState, setGlobalState, `/invitation/by_code/${code}`, "GET")
        const res = req[1]
        let data = req[0]
        if (res.status === 200) {
            await setInvetationCode(code)
            setCompanyName(data.invitation.company_name)
        } else {
            toast.error(data.error || <Trans keys={'toast.feild_to_retrive.code'} />);
        }
    }
    const loginReq = async (user) => {
        const req = await makeApiRequest(globalState, setGlobalState, '/login', "POST", user)
        const res = req[1]
        const data = req[0]
        if (res['status'] != 200 && res['status'] != 201) {
            toast.error(data.error || <Trans keys={'toast.feild_to_retrive.user_data'} />);
        } else {
            let data = req[0]
            let new_state = { ...globalState }
            data.user['token'] = data.token
            new_state['user'] = data.user
            new_state['user']['email'] = data.user.email || user.email
            new_state['user']['mobile'] = data.user.mobile || user.mobile
            new_state['user']['first_name'] = data.user.first_name || user.first_name
            new_state['user']['last_name'] = data.user.last_name || user.last_name
            new_state['user']['username'] = data.user.username || user.name
            new_state['user']['avatar'] = data.user.avatar || user.avatar
            // new_state['user']['data']['authorized_actions'] = data.user.data?.authorized_actions || new User(globalState).initialize_actions_list
            // new_state['user']['tutorial'] = {
            //     disabled: true,
            //     done: [],
            //     target_id: null,
            //     list: tuotrialList,
            // }
            await setGlobalState(new_state)
            return window.location.href = '/'
        }

    }

    useEffect(() => {
        globalState.user?.id && location.pathname === '/login' && navigate('/')
    }, [])

    const googleLogin = (response) => {
        var userObject = jwt_decode(response.credential)
        const user = {
            //   const user = {
            google_id: userObject?.sub,
            first_name: userObject?.given_name,
            last_name: userObject?.family_name,
            email: userObject?.email,
            avatar: userObject?.picture,
            username: userObject?.name,
            code: invetationCode

        };
        //   setGlobalState({ ...globalState, user });
        loginReq(user)
    }

    const googleLoginFail = (response) => {
        toast.error(response.error || <Trans keys={'toast.google_login_fail'} />)
    }

    const fakeLogin = () => {
        const user = {
            //   const user = {
            email: fakeEmail,

        };
        loginReq(user)

    }

    // useEffect(() => {
    //     if (!globalState.user?.id) {
    //         /* global google */
    //         if (google) {
    //             google.accounts.id.initialize({
    //                 client_id: "",
    //                 callback: (response) => {
    //                     googleLogin(response)
    //                 }
    //             });
    //             google.accounts.id.renderButton(
    //                 document.getElementById("google-login"),
    //                 { theme: "outline", size: "large", shape: "pill" }
    //             )
    //         }
    //     }
    // }, [consent])

    const letMeIn = () => {
        setConsent(true)
    }

    const reportBadInvite = () => {
        setInvetationCode(null)
        navigate('/login')
    }


    return (
        <div className={`login ${protectedRoute && "protected"}`}>
            <div className='card p-5' style={{ backgroundColor: "#000932", color: "#FFFFFF" }}>
                <form className="form-signin d-flex flex-column justify-content-center align-items-center" onSubmit={(e) => e.preventDefault()}>
                    <div className='mb-1'><img style={{ width: '50%' }} src={logo} /></div>
                    {(!invetationCode && (!location.pathname.includes('login') && location.pathname !== '/')) && <p className="h5 mb-3 font-weight-normal mb-4">
                        <Trans keys={"not_authorized_for_this_page"} />
                    </p>}
                    <>
                        {invetationCode && !consent ? (
                            <div>
                                <div className='fs-4'><Trans keys={'login.welcome'} /></div>
                                <div className='fs-6'><Trans keys={'login.before_you_join'} /></div>
                                <div className='fs-6'><Trans keys={'login.you_are_about_to_join'} /></div>
                                <div className='fs-6'>{companyName}</div>
                                <div className=''>
                                    <CustomBtn btnText={<Trans keys={'login.i_know_let_me_in'} />} iconPath={mdiLogin} btnClass={'mt-3 btn-app-green'} callback={letMeIn} />
                                    <CustomBtn btnText={<Trans keys={'login.dont_know_them_sign_in'} />} iconPath={mdiAlert} btnClass={'mt-3 btn-app-red'} callback={reportBadInvite} />
                                </div>
                            </div>
                        ) :
                            (
                                <>
                                    <div className="d-flex flex-column flex-wrap fs-6 col-6 h3 mb-3 font-weight-normal mb-4">
                                        {/* <Trans keys={"login"} /> */}
                                        <div className='mb-1'><Trans keys={"login.new_or_exisiting"} /></div>
                                        <div className='mb-1'><Trans keys={"login.doesnt_matter"} /></div>
                                        <div className='mt-3 fs-4'><Trans keys={"login.login_here"} /></div>

                                    </div>
                                    {location.pathname.includes('admin') ? <><div className='mb-4'>
                                        <label htmlFor="inputEmail" className="sr-only">
                                            <Trans keys={"email_address"} />
                                        </label>
                                        <input type="email" id="inputEmail" className="ltr mb-2 form-control" value={fakeEmail} onChange={(e) => setFakeEmail(e.target.value)} placeholder="Email address" required autoFocus />
                                        <button className="btn btn-lg btn-primary btn-block" type="submit" onClick={() => fakeLogin()}>
                                            <Trans keys={"sign_in"} />
                                        </button>
                                    </div>
                                    </> : null}

                                    <div className='google-btn-footer d-flex flex-row justify-content-center text-center'>
                                        {/* <div id="google-login"></div> */}
                                        <GoogleOAuthProvider
                                            clientId="196526498037-rraivtrcd407jl7ii862onhho3qpjmhi.apps.googleusercontent.com"
                                        >

                                            <GoogleLogin
                                                buttonText="Login"
                                                // useOneTap
                                                shape="pill"
                                                size="large"
                                                onSuccess={googleLogin}
                                                onFailure={googleLoginFail}
                                                cookiePolicy={'single_host_origin'} />
                                        </GoogleOAuthProvider>
                                    </div>
                                </>
                            )}
                    </>
                </form>
            </div>
        </div>)
}
