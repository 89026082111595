import React from 'react'
import { useWorkey } from '../../../WorkeyContext'
import ProfileInfoCard from '../profile/profile-info-card/ProfileInfoCard'
import ProfileAvatarCard from '../profile/profile-avatar-card/ProfileAvatarCard'
import { Trans } from '../../../lang/Trans'

const UserFake = ({}) => {
    const {globalState} = useWorkey()
    const fakeUser = {
        id: null,
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        avatar: '',
        status: 'fake',
        gender: 'unknown',
        birth_date: '',
        branch_id: globalState.user.branch_id
    }
    return (
        <div className='user-active-page-layout'>
            {/* <div className='d-flex flex-row justify-content-center mb-3'> */}
            <div className='fw-bold app-yellow-txt app-blue-bg text-center card mb-3 p-3'>
                <div>עם יצירת המשתמש אנחנו נשלח למייל של המשתמש שיצרת הזמנה לקחת בעלות על החשבון שיצרת עבורו.</div>
                <div>על מנת שהמשתמש יוכל לקחת בעלות על החשבון - חשוב ליצור אותו עם אימייל אמיתי שבבעלותו</div>
            </div>
            {/* </div> */}
            <div className='user-sections'>

                <div className='large-sections'>
                    <div className='profile-info-container'>
                        <ProfileInfoCard
                            showEdit={true}
                            user={fakeUser}
                        />
                    </div>
                    {/* <div className='branch-settings-for-user-container card'>
                <div className='sub-section'>
                    <ProfileSchedulingSettings
                        onSaveCallback={onSaveScheduling}
                        user={user}
                    />
                </div>
            </div> */}
                    {/*
                <div className='card mt-2'>
            </div> */}
                </div>
                {/* <div className={`profile-avatar-container card ${avatarEditMode ? "show-avatar" : ''}`}>
                    <div>
                        <ProfileAvatarCard
                            avatarType={'hide'}
                            user={fakeUser}
                            showEdit={true}
                        />
                        <div className={`hide-avatar-btn ${avatarEditMode ? 'show-avatar' : ''}`}>
                            <div className={`btn btn-sm btn-app-light-blue w-50 algin-self-center mx-auto`} onClick={() => setAvatarEditMode(false)}>
                                <Trans keys={'hide'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`btn btn-sm btn-app-blue mx-2 avatar-btn ${!avatarEditMode ? 'show-avatar' : ''}`} onClick={() => setAvatarEditMode(true)}>
                    <Trans keys={'want_to_change_image'} />
                </div> */}
            </div>
        </div >
    )
}

export default UserFake