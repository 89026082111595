import { Icon } from '@mdi/react';

import './Shift.css'

import React, { useEffect } from 'react'
import { mdiCog, mdiMenuDown, mdiMenuLeft, mdiMinusCircle } from '@mdi/js';
import { toolsSettings } from '../tools/tools_utils'
import { useState } from 'react'
import ShiftSettings from './ShiftSettings'
import Slot from './Slot'
import FillSlot from './FillSlot'
import { Trans } from '../../../../lang/Trans'
import { useWorkey } from '../../../../WorkeyContext'
import { shiftClass } from '../../../../utils/scheduling_utils'
import { IconSize, makeApiRequest } from '../../../../utils/utils';
import { rebuildWeek } from '../../../../utils/week_utils';

const Shift = (props) => {
  const { mark, toggleForceUpdate, maxPerRole, currentWeek, setCurrentWeek, tools, edit, shift_day_num, day_id, shift_id, selectedSlotId, setSelectedSlotId, toggleTimeModal, employeeWatch, toggleUpdate } = props
  const { globalState, setGlobalState } = useWorkey()
  const [hideShift, setHideShift] = useState(false)
  const [shift, setShift] = useState(currentWeek?.days?.find(day => day.id == day_id)?.shifts?.find(s => s.id == shift_id) || {})
  const [slots, setSlots] = useState(shift?.slots ? [...shift?.slots?.filter(slot => slot.shift_id == shift?.id)] : [])
  const [showShiftSettings, setShowShiftSettings] = useState(false)

  const removeShift = async () => {
    if (!window.confirm('are you sure? this action can\'t be undone!')) {
      return;
    }
    const req = await makeApiRequest(globalState, setGlobalState, `/shifts/${shift_id}`, 'DELETE');
    const [data, res] = await req;
    if (res.status === 200) {
      let new_current_week = { ...currentWeek };
      new_current_week = await rebuildWeek(data, globalState);
      setCurrentWeek(new_current_week);
    }
  }

  useEffect(() => {
    setShift(currentWeek?.days?.find(day => day.id == shift?.day_id)?.shifts?.find(s => s.id == shift?.id))
    setSlots(currentWeek?.slots.filter(slot => slot.shift_id == shift?.id))
  }, [currentWeek.id, currentWeek?.slots.length, currentWeek?.shifts.length])

  const sortedShoftSlots = () => {
    
  }
  const toggleHideShift = async () => {
    let current_week = { ...currentWeek }
    const day_num = current_week.days.findIndex(day => day.id == day_id)
    const shift_num = current_week.days[day_num]?.shifts.findIndex(shift => shift.id == shift_id)
    if (!current_week.days[day_num]) {
      return
    }
    current_week.days[day_num].shifts[shift_num]['hide'] = !current_week.days[day_num]?.shifts[shift_num]['hide']
    await setGlobalState({ ...globalState, currentWeek: current_week })
    await setHideShift(!hideShift)
    toggleForceUpdate()
  }

  const sortedRoles = () => {
    return globalState.user.branch?.roles.sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      } else if (a.order) {
        return -1;
      } else if (b.order) {
        return 1;
      } else {
        return a.id - b.id;
      }
    })
  }
  return (
    shift?.delete ? <></> :
      <div className={`shift ${edit ? shiftClass(currentWeek, shift, employeeWatch) : ''}`}>
        {showShiftSettings ? <ShiftSettings setShowShiftSettings={setShowShiftSettings} /> : <></>}
        <div className='shift-header pointer'>
          <div className='shift-fold-btn ' onClick={() => toggleHideShift()}><Icon path={hideShift ? mdiMenuLeft : mdiMenuDown} size={IconSize} /></div>
          <div className='shift-title text-center' onClick={() => toggleHideShift()}>
            <Trans keys={'shift'} /> {shift_day_num + 1}
          </div>
          <div className='mx-1 actions'>
            {tools?.remove_shifts ? <Icon path={mdiMinusCircle} size={IconSize} onClick={() => removeShift()} className={`text-${toolsSettings.remove_shifts.color}`} /> : <></>}
            {tools?.shift_settings ? <Icon path={mdiCog} size={IconSize} onClick={() => setShowShiftSettings(!showShiftSettings)} className={`text-${toolsSettings.shift_settings.color}`} /> : <></>}
          </div>
        </div>
        <div className={`shift-body ${false ? "scroll" : ""} ${hideShift && 'folded'}`} >
          <div>
            {/* <div className='shift-btns'>
              {tools?.add_slots ? <Icon path={mdiPlusCircle} size={IconSize} onClick={() => { }} className={`btn text-${toolsSettings.add_slots.color}`}  /> : <></>}
            </div> */}
            {sortedRoles().map((role, role_num) => {

              return (
                <div className={`role card roleId${role.id}`}>
                  <div className='text-center fw-bold bg-secondary'>{role.name}</div>
                  <div>
                    {shift?.slots?.sort((a, b) => a.id - b.id).filter(slot => slot.role_id == role.id).map((slot, slot_num) => {
                      return (
                        <>
                          <Slot
                            mark={mark}
                            tools={tools}
                            currentWeek={currentWeek}
                            setCurrentWeek={setCurrentWeek}
                            toggleUpdate={toggleUpdate}
                            edit={edit}
                            day_id={shift?.day_id}
                            shift_id={shift?.id}
                            slot_num={slot_num}
                            slot_id={slot.id}
                            selectedSlotId={selectedSlotId}
                            setSelectedSlotId={setSelectedSlotId}
                            toggleTimeModal={toggleTimeModal}
                            employeeWatch={employeeWatch}
                          />
                        </>
                      )
                    })}
                    <FillSlot
                      maxPerRole={maxPerRole}
                      currentWeek={currentWeek}
                      setCurrentWeek={setCurrentWeek}
                      toggleUpdate={toggleUpdate}
                      edit={edit}
                      day_id={shift?.day_id}
                      role_id={role.id}
                      shift_id={shift?.id}
                      slot_num={slots.length}
                      selectedSlotId={selectedSlotId}
                      setSelectedSlotId={setSelectedSlotId}
                      toggleTimeModal={toggleTimeModal}
                    />
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      </div>
  )
}

export default Shift