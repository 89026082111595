import React, { useState } from 'react'
import SubLocation from './SubLocation';


const SidebarTools = ({ actionsList }) => {
    const [showSubTool, setShowSubTool] = useState(false)

    const displayList = () => {
        return actionsList.map((action, i) => {
            return (
                <>
                    <div onClick={() => setShowSubTool(!showSubTool)}>
                        <SubLocation key={`action_${i}`} action={action} />
                    </div>
                    <div className={`${showSubTool ? "" : "hidden"}`}>
                        subTool
                    </div>
                </>
            )

        });
    }

    return (
        <>
            {displayList()}
        </>
    )
}

export default SidebarTools