import React, { useEffect, useRef, useState } from "react";
import { Trans } from "../../../../lang/Trans";
import { mdiAlertCircleCheck, mdiAlertCircleOutline, mdiCancel, mdiCheck, mdiClock, mdiEmailFast, mdiExclamation, mdiRedo, mdiReloadAlert, mdiSend, mdiWhatsapp } from "@mdi/js";
import Icon from "@mdi/react";
import { useWorkey } from "../../../../WorkeyContext";
import { makeApiRequest } from "../../../../utils/utils";
import CustomBtn from "../../../custome-btn/CustomBtn";
import { appDomain } from "../../../../utils/routing";
import { toast } from "react-hot-toast";

const QuickInviteEmployee = ({ widget = true }) => {
    const { globalState, setGlobalState } = useWorkey();
    const windowFeatures = 'width=600,height=400,resizable=yes';
    const initialize_invitation = {
        send_type: "email",
        mobile: "",
        email: "",
        branch_id: globalState.user.branch_id,
    }
    const [invitation, setInvitation] = useState(initialize_invitation);
    const [inputError, setInputError] = useState("");
    const [sendingStatus, setSendingStatus] = useState(null);
    const [whatsappInviteText, setWhatsappInviteText] = useState(null);
    const [whatsappInviteCode, setWhatsappInviteCode] = useState(null);


    const handleInputChange = (field, value) => {
        const newInvitation = { ...invitation, [field]: value };
        const error = validateInput(newInvitation);
        setInputError(error);
        setInvitation(newInvitation);
    };
    const validateInput = (invitation) => {
        if (invitation.send_type === "email") {
            if (!invitation.email) {
                return "Email is required";
            }
            if (
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(.)+(\[a-zA-Z0-9-]+)*$/.test(
                    invitation.email
                )
            ) {
                return "Invalid email format";
            }
        } else if (invitation.send_type === "whatsapp") {
            if (!invitation.mobile) {
                return "Mobile number is required";
            }
            if (!/^\+[1-9]\d{1,14}$/g.test(invitation.mobile)) {
                return "Invalid mobile number format";
            }
        }
        return "";
    };

    const sendInvitation = async (retry = false) => {
        if (!retry && sendingStatus) {
            if (sendingStatus === "sending") {
                return;
            } else {
                return clearInvitation()
            }
        }
        const { email, mobile, send_type } = invitation;
        const address = send_type === "email" ? email : mobile;
        if (send_type === "whatsapp") {
            sendWhatsappInvite(address)
        } else {
            sendEmailInvite(address, send_type);
        }

    };

    const sendWhatsappInvite = async (address) => {
        await setSendingStatus("sending")
        const req = await makeApiRequest(
            globalState,
            setGlobalState,
            "/invitations",
            "POST",
            { sender_id: globalState.user.id, send_type: "whatsapp", address }
            );
            if (req?.[1].status === 201) {
            let data = req[0]
            const whatsapp_invite_res = data.whatsapp_invite_text
            const link = `${appDomain}/login/join/code?${whatsapp_invite_res.code}`
            await setWhatsappInviteCode(link)
            await setWhatsappInviteText(whatsapp_invite_res.text)
            setSendingStatus("done")
            if(widget){
                return window.open(`https://wa.me/${address}?text=${whatsapp_invite_res.text}${link}`, 'הזמנה ל-Workey', windowFeatures);
            }
        } else {
            toast.error(req[0].error || <Trans keys={'toast.feild_to_invite'} />);
            await setSendingStatus(null)
        }


    }

    const sendEmailInvite = async (address, send_type) => {
        await setSendingStatus("sending");
        const req = await makeApiRequest(
            globalState,
            setGlobalState,
            "/invitations",
            "POST",
            { sender_id: globalState.user.id, send_type, address }
        );
        if (req?.[1].status === 201) {
            let data = req[0]
            if (data.invitation) {
                const invitations = globalState.user.invitations
                    ? [...globalState.user.invitations]
                    : [];
                invitations.push(data.invitation);
                setGlobalState({
                    ...globalState,
                    user: { ...globalState.user, invitations },
                });
                await setSendingStatus("done");
            } else {
                await setSendingStatus("failed");
            }
        } else {
            await setSendingStatus("failed");

        }
    }

    const clearInvitation = async () => {
        await setInvitation(initialize_invitation)
        await setInputError("")
        await setSendingStatus(null)
    }

    const iconTitle = () => {
        switch (sendingStatus) {
            case null:
                return <Trans keys={"invite"} />
            case 'sending':
                return <Trans keys={"wait"} />
            case 'done':
            case 'failed':
                return <Trans keys={"clear"} />
            default:
                break;
        }
    }

    const iconIcon = () => {
        switch (sendingStatus) {
            case 'sending':
                return mdiClock
            case 'done':
                return mdiCheck
            case 'failed':
                return mdiCancel
            default:
                return mdiSend
        }
    }

    const sendBtnBgColor = () => {
        switch (sendingStatus) {
            case 'done':
                return 'btn-app-green'
            case 'failed':
                return 'btn-app-red'
            default:
                return 'btn-app-blue'
        }
    }

    const editText= (e) => {
        setWhatsappInviteText(e.target.value)
    }

    const scrollableRef = useRef(null);
    useEffect(() => {
        const scrollableNode = scrollableRef.current;
        if (scrollableNode) {
            scrollableNode.scrollTop = scrollableNode.scrollHeight;
        }
    }, [sendingStatus]);

    const sendWhatsapp = () => {
        let mobile = invitation.mobile
        return window.open(`https://wa.me/${mobile}?text=${whatsappInviteText}${whatsappInviteCode}`, 'הזמנה ל-Workey', windowFeatures);
    }

    if (!widget && invitation.send_type === 'whatsapp' && whatsappInviteText) {
        return <div className="m-3">
            <div className="card p-5 text-center">
                <textarea className="form-control" value={whatsappInviteText} onChange={(e) => editText(e)} readOnly={true} rows={7} />
                <Trans keys={"we_will_add_the_link_while_sending"} />
            </div>
            <div>
                <CustomBtn
                btnText={<Trans keys={'send'}/>}
                btnClass={'btn-app-green'}
                iconPath={mdiWhatsapp}
                iconClass="mx-2"
                callback={sendWhatsapp}/>
            </div>
        </div>
    }
    return (
        sendingStatus !== 'sending' ? (
            <div className={`${!widget ? "m-3 p-5 card" : "pb-3"}`} ref={scrollableRef} >
                {!widget && (
                    <div className="invite-employee-explain text-center">
                        <Trans keys={"quick_add_employee.explain"} />
                    </div>
                )}
                <div className="my-2 d-flex flex-row justify-content-center">
                    <div
                        onClick={() => setInvitation({ ...invitation, send_type: "email" })}
                        className={`btn btn-sm mx-1 btn-app-light-blue ${invitation.send_type === "email" ? "disabled" : ""
                            }`}
                    >
                        <Icon
                            title={<Trans keys={"quick_add_employee.via_email"} />}
                            path={mdiEmailFast}
                            size={1}
                        />
                    </div>
                    <div
                        onClick={() =>
                            setInvitation({ ...invitation, send_type: "whatsapp" })
                        }
                        className={`btn btn-sm mx-1 btn-app-green ${invitation.send_type === "whatsapp" ? "disabled" : ""
                            }`}
                    >
                        <Icon
                            title={<Trans keys={"quick_add_employee.via_whatsapp"} />}
                            path={mdiWhatsapp}
                            size={1}
                        />
                    </div>
                </div>
                <div
                    className="d-flex flex-column justify-content-between align-items-center">
                    {(sendingStatus === 'failed' || inputError) && <label className="text-danger">{inputError.length > 0 ? inputError : <Trans keys={"error"} />}</label>}
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div
                            onClick={() => sendInvitation()}
                            className={`${validateInput(invitation).length < 1 ? "" : "hidden"}`}
                        >
                            <div className={`btn btn-sm ${sendBtnBgColor()}`}>
                                <Icon
                                    title={iconTitle()}
                                    path={iconIcon()}
                                    size={1}
                                />
                            </div>
                        </div>
                        {sendingStatus === 'failed' && (
                            <div
                                onClick={() =>
                                    sendInvitation(true)
                                }
                                className={`btn btn-sm mx-1 btn-app-blue ${invitation.send_type === "whatsapp" ? "disabled" : ""
                                    }`}
                            >
                                <Icon
                                    title={<Trans keys={"retry"} />}
                                    path={mdiReloadAlert}
                                    size={1}
                                />
                            </div>
                        )}
                        <div className="mx-2">
                            {invitation.send_type === "whatsapp" && (
                                <input
                                    className={`form-control ltr ${sendingStatus === "sending" ? "disabled" : ""}`}
                                    onChange={(e) => handleInputChange("mobile", e.target.value)}
                                    type="tel"
                                    disabled={sendingStatus == "sending"}
                                    placeholder={
                                        globalState.local === "he" ? "טלפון נייד" : "Mobile phone"
                                    }
                                    value={invitation.mobile}
                                />
                            )}
                            {invitation.send_type === "email" && (
                                <input
                                    className={`form-control ltr ${sendingStatus === "sending" ? "disabled" : ""}`}
                                    onChange={(e) => handleInputChange("email", e.target.value)}
                                    type="email"
                                    placeholder={"email"}
                                    disabled={sendingStatus == "sending"}
                                    value={invitation.email}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) :
            (
                <div><Trans keys={'sending'} /></div>
            )
    );
};
export default QuickInviteEmployee;
