import React, { useState } from 'react'
import { useWorkey } from '../../../WorkeyContext'
import { displayTime, userDisplayName } from '../../../utils/utils'
import { employeePositions } from '../../../utils/scheduling_utils'
import { mdiCurrencyIls } from '@mdi/js'
import Icon from '@mdi/react';
const SlotTip = ({ tip, slot, tip_num }) => {
    const { globalState, setGlobalState } = useWorkey()
    const employee = globalState.user.branch.employees.find(employee => employee.id == slot.user_id)
    return (<>
        <tr>
            <th scope="row">{tip_num + 1}</th>
            <td>{userDisplayName(employee)}</td>
            <td>{globalState.user.branch.roles.find(role => role.id == slot.role_id).name}</td>
            <td>{tip.wage_percent}</td>
            <td>{displayTime(slot.total_hours)}</td>
            {/* <td>{position?.minimun_wage || MINIMUM_WAGE}</td> */}
            <td>
                <div className='d-flex flex-row justify-content-center align-items-baseline'>
                    {(tip.total_wage / tip.total_hours).toFixed(2)} <Icon className='mx-1' path={mdiCurrencyIls} size={0.5} />
                </div>
            </td>
            <td>
                <div className='d-flex flex-row justify-content-center align-items-baseline'>
                    {tip.total_wage} <Icon className='mx-1' path={mdiCurrencyIls} size={0.5} />
                </div>
            </td>
        </tr>

    </>
    )
}

export default SlotTip