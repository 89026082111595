import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { useWorkey } from "../../../WorkeyContext";
import { useEffect, useState } from "react";
import moment from "moment";
import { trans } from "../../../lang/translation";
import { toast } from "react-hot-toast";
import { isValidEmail, isValidMobile } from "../../../utils/data_utils";
import Icon from "@mdi/react";
import { mdiContentSave, mdiFloppy, mdiPlusBox, mdiPrinter } from "@mdi/js";
import { makeApiRequest } from "../../../utils/utils";
const DEFUALT_AUTHORIZATIONS = [
  'manage_schedule',
  'view_schedule',
  'manage_employee',
  'branch_manager',
];

const emptyRow = (globalState) => {
  return {
    id: `fake_${Math.random()}`,
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    gender: "",
    birth_date: "",
    main_role: "",
    roles: globalState.user.branch.roles.map(role => {
      return {
        role: role.name,
        id: role.id,
        hasRole: "לא"
      };
    }),
    authorized_actions: DEFUALT_AUTHORIZATIONS.map(action => {
      return {
        action: action,
        authorized: "לא"
      };
    })
  }
}

const getEmployees = (globalState, employees) => {
  let people_rows_data = [];

  employees?.forEach(employee => {
    const employeeRoles = globalState.user.branch.roles.map(role => {
      return {
        role: role.name,
        id: role.id,
        hasRole: employee.roles.find(r => r.id === role.id) ? "כן" : "לא"
      };
    });
    const employeeAuthorizedActions = DEFUALT_AUTHORIZATIONS.map(action => {
      return {
        action: action,
        authorized: employee.data?.['authorized_actions']?.[action] || employee['authorized_actions']?.[action] ? "כן" : "לא"
      };
    })
    const authorizedActions = Object.keys(employeeAuthorizedActions).map((action_key) => {
      return {
        action: employeeAuthorizedActions[action_key],
        authorized: employeeAuthorizedActions[action_key].authorized ? "כן" : "לא"
      };
    })

    people_rows_data.push({
      id: employee.id,
      mobile: employee.mobile || '',
      email: employee.email,
      last_name: employee.last_name || '',
      first_name: employee.first_name || '',
      gender: employee.gender ? trans(globalState, employee.gender) : '',
      birth_date: employee.birth_date ? moment(employee.birth_date).format('DD/MM/YYYY') : '',
      main_role: employee.main_role || '',
      roles: employeeRoles || [],
      authorized_actions: authorizedActions || []
    });
  });

  // people_rows_data = [...people_rows_data];
  // people_rows_data.push(emptyRow(globalState));
  return people_rows_data;
};

const addInfoColumns = (columns) => {
  columns.unshift({ columnId: "email", width: 130 });
  columns.unshift({ columnId: "mobile", width: 130 });
  columns.unshift({ columnId: "gender", width: 130 });
  return columns;
};

const getColumns = (filters, roles) => {
  let cols = [
    { columnId: "last_name", width: 130 },
    { columnId: "first_name", width: 130 },
    { columnId: "number", width: 40 },
  ];
  if (filters.includes('info')) {
    cols = addInfoColumns(cols);
  }
  if (filters.includes('roles')) {
    cols = addRolesCols(cols, roles);
  }
  if (filters.includes('authorized')) {
    cols = addAuthorizedCols(cols, roles);
  }
  return cols;
};

const headerRow = (filters, globalState) => {
  let roles = globalState.user?.branch.roles;
  let cells_arr = [
    { type: "header", text: "* שם משפחה" },
    { type: "header", text: "* שם פרטי" },
    { type: "header", text: "מס" },
  ];
  if (filters.includes('info')) {
    cells_arr = addInfoHeaders(cells_arr);
  }
  if (filters.includes('roles')) {
    cells_arr = addRolesHeaders(cells_arr, roles);
  }
  if (filters.includes('authorized')) {
    cells_arr = addAuthorizedHeaders(cells_arr, globalState);
  }
  return {
    rowId: "header",
    cells: cells_arr
  };
};

const getRows = (globalState, employees, filters) => {
  // let employees = globalState.user.branch.employees
  let cells = (employee, idx) => {
    let cells_arr = [
      { type: "text", text: employee.last_name },
      { type: "text", text: employee.first_name },
      { type: "text", text: `${idx + 1}` },
    ];
    if (filters.includes('info')) {
      cells_arr = addInfoCells(cells_arr, employee);
    }
    if (filters.includes('roles')) {
      cells_arr = addRolesCells(cells_arr, employee);
    }
    if (filters.includes('authorized')) {
      cells_arr = addAuthorizedCells(cells_arr, employee);
    }
    return cells_arr;
  };
  return [
    headerRow(filters, globalState),
    ...employees.map((employee, idx) => ({
      rowId: idx,
      cells: cells(employee, idx)
    }))
  ];
};

const addInfoCells = (cells_arr, employee) => {
  cells_arr.unshift({ type: "text", text: employee.email });
  cells_arr.unshift({ type: "text", text: employee.mobile });
  cells_arr.unshift({ type: "text", text: employee.gender });
  return cells_arr;
};
const addInfoHeaders = (cells_arr) => {
  cells_arr.unshift({ type: "header", text: "Email *" });
  cells_arr.unshift({ type: "header", text: "סלולרי" });
  cells_arr.unshift({ type: "header", text: "מגדר" });
  return cells_arr;
};

const addRolesCells = (cells_arr, employee) => {
  cells_arr.unshift({ type: "text", text: employee.main_role?.name || '' });
  employee.roles?.forEach(role => {
    cells_arr.unshift({ type: "text", text: role?.hasRole || '' });
  });
  return cells_arr;
};
const addAuthorizedCells = (cells_arr, employee) => {
  employee.authorized_actions.forEach(action => {
    cells_arr.unshift({ type: "text", text: action.authorized || '' });
  });
  return cells_arr;
};
const addRolesHeaders = (cells_arr, roles) => {
  cells_arr.unshift({ type: "header", text: 'תפקיד ראשי' });
  roles.forEach(role => {
    cells_arr.unshift({ type: "header", text: `תפקיד נוסף - ${role.name}` });
  });
  return cells_arr;
};
const addAuthorizedHeaders = (cells_arr, globalState) => {

  DEFUALT_AUTHORIZATIONS.forEach(action_key => {
    cells_arr.unshift({ type: "header", text: trans(globalState, `authorized_actions.${action_key}`) });
  });
  return cells_arr;
};
const addRolesCols = (cols, roles) => {
  cols.unshift({ columnId: `main_role`, width: 100 });
  roles.forEach(role => {
    cols.unshift({ columnId: `role_${role.id}`, width: 120 + (role.name.length * 10) });
  });
  return cols;
};
const addAuthorizedCols = (cols, roles) => {
  DEFUALT_AUTHORIZATIONS.forEach(action_key => {
    cols.unshift({ columnId: `authorized_${action_key}`, width: 120 });
  });
  return cols;
};




function EmployeesForm() {
  const { globalState, setGlobalState } = useWorkey();
  const [rowsEmployees, setRowsEmployees] = useState(globalState.user.branch.employees.sort((a, b) => a.first_name.localeCompare(b.first_name)) || []);
  const [changedEmployees, setChangedEmployees] = useState([]);
  const [filters, setFilters] = useState([]);

  const rows = getRows(globalState, rowsEmployees, filters);
  const columns = getColumns(filters, globalState.user.branch.roles);
  useEffect(() => {
    let employees = getEmployees(globalState, globalState.user.branch.employees, filters)
    setRowsEmployees(employees);
  }, [globalState.user.branch.employees?.length])

  const toggleFilter = (type) => {
    const newFilters = [...filters];
    if (newFilters.includes(type)) {
      newFilters.splice(newFilters.indexOf(type), 1);
    } else {
      newFilters.push(type);
    }
    setFilters(newFilters);
    const divElement = document.getElementById('employees-grid-list');
    divElement.scrollLeft = -(divElement.scrollWidth); // Adjust the scroll distance as needed

  }

  const handleChanges = (changes) => {
    if (!validateChanges(changes)) {
      return null
    };
    let rowsData = getEmployees(globalState, rowsEmployees);
    let columnId = changes[0].columnId;
    let row_index = changes[0].rowId;
    const employee_id = rowsData[row_index]?.id;
    let employeeIndex = employee_id ? rowsEmployees.findIndex(e => e.id === employee_id) : -1
    let new_employees = [...rowsEmployees]
    let new_data = changes[0].newCell.text
    if (columnId.startsWith('role_')) {
      let new_roles = [...new_employees[employeeIndex].roles]
      let role_index = new_roles.findIndex(role => role.id == columnId.split('_')[1])
      if (new_roles[role_index]) {
        new_roles[role_index].hasRole = new_data
      } else {
        let selected_role = globalState.user.branch.roles.find(r => r.id == columnId.split('_')[1])
        new_roles.push({ role: selected_role.name, id: selected_role.id, hasRole: "כן" })

      }
      new_employees[employeeIndex].roles = new_roles
    } else if (columnId.startsWith('authorized_')) {
      let new_actions = [...new_employees[employeeIndex].authorized_actions]
      let action_index = new_actions.findIndex(action => action.action.action == columnId.replace('authorized_', ''))
      if (new_actions[action_index]) {
        new_actions[action_index].authorized = new_data
      } else {
        new_actions.push({
          action: columnId.replace('authorized_', ''),
          authorized: "כן"
        })
      }
      new_employees[employeeIndex].authorized_actions = new_actions
    } else {
      if (columnId === 'main_role') {
        let new_main_role = globalState.user.branch.roles.find(r => r.name === changes[0].newCell.text)
        if (new_main_role) {
          new_data = new_main_role
        } else {
          toast.error(trans(globalState, 'main_role_not_found') + ' ' + changes[0].newCell.text)
        }

      }
      if (employeeIndex > -1) {
        new_employees[employeeIndex][changes[0].columnId] = new_data
      } else {
        let employee = emptyRow(globalState)
        employee[changes[0].columnId] = new_data
        new_employees.push(employee)
      }
    }
    new_employees[employeeIndex]['changed'] = true

    setRowsEmployees(new_employees);
  };

  const validateChanges = (changes) => {
    let valid = true
    if (changes.length < 1) {
      valid = false
      toast.error(trans(globalState, 'employees_form.NO_CHANGES'))
      return valid
    }
    changes.forEach((change) => {
      let columnId = change.columnId
      let rowsData = getEmployees(globalState, rowsEmployees);
      let row_index = changes[0].rowId;
      const employee_id = rowsData[row_index]?.id;
      const emp = globalState.user.branch.employees.find(e => e.id === employee_id)
      let newCell = change.newCell
      switch (columnId) {
        case 'first_name':
        case 'last_name':
          if (emp?.status === 'active') {
            toast.error(trans(globalState, 'employees_form.CHANGE_NOT_ALLOWED'))
            valid = false
          } else if (newCell.text.trim() === '') {
            toast.error(trans(globalState, 'employees_form.EMPTY_NAME_ERROR'))
            valid = false
          }
          break
        case 'email':
          if (emp?.status === 'active') {
            toast.error(trans(globalState, 'employees_form.CHANGE_NOT_ALLOWED'))
            valid = false
          } else if (!isValidEmail(newCell.text)) {
            toast.error(trans(globalState, 'employees_form.INVALID_EMAIL_ERROR'))
            valid = false
          }
          break
        case 'mobile':
          if (newCell.text.trim().length > 0 && !isValidMobile(newCell.text)) {
            toast.error(trans(globalState, 'employees_form.INVALID_MOBILE_ERROR'))
            valid = false
          }
          break
        case 'gender':
          if (!['זכר', 'נקבה'].includes(newCell.text)) {
            toast.error(trans(globalState, 'employees_form.INVALID_GENDER_ERROR'))
            valid = false
          }
          break
      }

      if (columnId === "main_role") {
        if (!globalState.user.branch.roles.find(r => r.name === newCell.text)) {
          toast.error(`${trans(globalState, 'main_role_not_found')} "${newCell.text}"`)
          valid = false
        }
      }
      if (columnId.startsWith('authorized')) {
        if (!['כן', 'לא'].includes(newCell.text)) {
          toast.error(trans(globalState, 'employees_form.INVALID_AUTHORIZED_ACTIONS_ERROR'))
          valid = false
        }
      }
      if (columnId.startsWith('role')) {
        if (!['כן', 'לא'].includes(newCell.text)) {
          toast.error(trans(globalState, 'employees_form.INVALID_ROLES_ERROR'))
          valid = false
        }
      }
    })
    return valid
  }
  const addEmployee = (globalState) => {
    let new_emp = emptyRow(globalState)
    setRowsEmployees([...rowsEmployees, new_emp])
  }

  const saveChanges = async () => {
    const gender_trans = {
      "לא ידוע": "unknown",
      "זכר": "male",
      "נקבה": "female",
    }
    const bool_trans = {
      "כן": true,
      "לא": false
    }
    let changed_employees = [...rowsEmployees].filter(e => e.changed)
    const original_changed_employees = [...changed_employees]
    changed_employees = changed_employees.map(emp => {
      // delete emp.changed
      if (String(emp['id']).startsWith('fake')) { delete emp.id }
      emp.gender = gender_trans[emp.gender] || "unknown"
      emp.roles = emp.roles.filter(role => role.hasRole === "כן").map(role => {
        // delete role.hasRole
        return role
      })

      emp['authorized_actions'] = emp['authorized_actions'].filter(action => action.authorized === "כן").map(action => action.action)
      console.log('changed_employees', emp['authorized_actions'].filter(action => action.authorized === "כן"))

      return emp
    })
return 
    const req = await makeApiRequest(globalState, setGlobalState, `branches/${globalState.user.branch.id}/upload_employees/`, 'POST', { employees: changed_employees })
    const data = req[0]
    const res = req[1]

    if(res.status === 200) {
      toast.success(trans(globalState, 'employees_form.UPLOAD_SUCCESS'))
      setGlobalState({ ...globalState, user: { ...globalState.user, branch: { ...globalState.user.branch, employees: data } } })
    } else {
      if (data.error) {
        toast.error(data.error || trans(globalState, 'employees_form.ERROR'))
      }
      let old_changed_employees = [...rowsEmployees]
      old_changed_employees.forEach((emp, index) => {
        old_changed_employees[index] = original_changed_employees.find(e => e.id === emp.id)
      })
      setRowsEmployees(old_changed_employees)
    }
  }


  const printDivById = (divId) => {
    const printContents = document.getElementById(divId).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload()
  };


  return <div id="employees-grid-list-container" className="employees-grid-list-container">
    <div className="employees-grid-list-filters">
      <div className="mx-2 pointer" onClick={() => toggleFilter('info')}><input type="checkbox" className="pointer" checked={filters.includes('info')} /> פרטים אישים</div>
      <div className="mx-2 pointer" onClick={() => toggleFilter('roles')}><input type="checkbox" className="pointer" checked={filters.includes('roles')} /> תפקידים</div>
      <div className="mx-2 pointer" onClick={() => toggleFilter('authorized')}><input type="checkbox" className="pointer" checked={filters.includes('authorized')} /> הרשאות</div>
      {/* <div className="btn btn-sm btn-app-blue" onClick={() => toggleFilter('info')}>פרטים אישיים</div> */}
    </div>
    <div id="employees-grid-list" className="employees-grid-list">
      <div className="d-flex flex-row justify-content-end mb-1">
        <div className="mx-1 pointer" onClick={() => addEmployee(globalState)}>
          <Icon path={mdiPlusBox} size={1} className="app-green-txt" />
        </div>
        <div className="mx-1 pointer" onClick={() => printDivById('employees-list')}>
          <Icon path={mdiPrinter} size={1} />
        </div>
        <div className="pointer" onClick={() => saveChanges()}>
          <Icon path={mdiContentSave} size={1} className="" />
        </div>
        {/* <div className="btn btn-sm btn-app-blue" onClick={() => toggleFilter('info')}>פרטים אישיים</div> */}
      </div>
      <div id="employees-list">

        <ReactGrid
          enableRangeSelection
          onCellsChanged={handleChanges}
          rows={rows} columns={columns} />
      </div>
    </div>
  </div>
}
export default EmployeesForm;

