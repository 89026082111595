import SubmittedRequests from "../components/dashboard/cards/SubmittedRequests";
import NewWidgetCard from "../components/dashboard/cards/WidgetsManagemntBtn";
import Widget from "../components/dashboard/cards/Widget";
import QuickInviteEmployee from "../components/dashboard/cards/widgets/QuickInviteEmployee";
import WidgetsManagemntBtn from "../components/dashboard/cards/WidgetsManagemntBtn";
import Telegram from "../components/telegram/Telegram";

export const dashboardCardsOptions = (callback = () => {}, selected = false) => {
    return (
        {
            // NewCardModal: <WidgetsManagemntBtn callback={callback} selected={true} className='hidden'/>,
            SubmittedRequests: <SubmittedRequests callback={callback} selected={selected} authorized={['manage_schedule']}/>,
            Telegram: <Widget title={'התחברות לטלגרם'} callback={callback} selected={selected} widgetComponent={<Telegram/>}/>,
            QuickInviteEmployee: <Widget title={'הוספת עובדים מהירה'} callback={callback} selected={selected} widgetComponent={<QuickInviteEmployee/>} authorized={['manage_schedule']}/>,
            YourGal: <Widget title={'ff'} callback={callback} selected={selected}/>,
            YourA: <Widget title={'s'} callback={callback} selected={selected}/>,
            YourB: <Widget title={'s'} callback={callback} selected={selected}/>,
            YourC: <Widget title={'s'} callback={callback} selected={selected}/>,
            YourD: <Widget title={'s'} callback={callback} selected={selected}/>,
            YourE: <Widget title={'s'} callback={callback} selected={selected}/>,
            YourF: <Widget title={'s'} callback={callback} selected={selected}/>,
            YourG: <Widget title={'s'} callback={callback} selected={selected}/>,
            YourH: <Widget title={'s'} callback={callback} selected={selected}/>,
        }

    )
}