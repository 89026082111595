// import { tuotrialList } from "./utils/tutorial/tutorial";
import { isSmallScreen } from "./utils/utils";

// workeyReducer.js
export const initialGlobalState = {

  local: 'he',
  largeSidebar: !isSmallScreen(),
  loading: true,
  weekRef: 0,
  user: null, // add this line to initialize the user property
  fetchingData: {},
};

const workeyReducer = (globalState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_TUTORIAL':
      return { ...globalState, tutorial: payload };
    case 'UPDATE_GLOBAL_STATE':
      return { ...globalState, ...payload };
    case 'UPDATE_USER':
      return { ...globalState, user: payload };
    case 'SET_LOADING':
      return { ...globalState, loading: payload };
    default:
      throw new Error('Unhandled action type');
  }
};

export default workeyReducer;

