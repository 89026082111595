class State {
  constructor(globalState) {
    this.state = globalState;
  }


  getSlots() {
    this.slots = this.state.currentWeek.slots
    return this
  }
  getDay(key = false, id = false) {
    let days = this.state.currentWeek.days
    if (id) {
      this.day = days.find(day => day.id == id)
    }
    if (key && days[key]) {
      this.day = days[key]
    }
    this.first = this.day
    this.who = "getDay"
    return this
  }

  getShift(key = false, id = false) {
    let shifts = this.state.currentWeek.shifts
    if (id) {
      this.shift = shifts.find(shift => shift.id == id)
      this.shift.slots = this.state.currentWeek.slots.filter(slot => slot.shift_id == this.shift.id)
      this.first = this.shift
      return this
    }
    if (this.day) {
      this.shift = this.day.shifts[key]
    } else {
      this.shift = shifts[key]
    }
    if (this.shift) {
      this.shift.slots = this.state.currentWeek.slots.filter(slot => slot.shift_id == this.shift.id)
      this.first = this.shift
    }
    return this
  }

  getSlot(key = false, id = false, role_id = false) {
    if (!this.slots) {
      this.slots = this.state.currentWeek.slots
    }
    if (id) {
      this.slot = this.slots.find(shift => shift.id == id)
    } else {
      if (this.shift) {
        this.slot = this.shift.slots[key]
      } else {
        this.slot = this.slots[key]
      }
    }
    this.first = this.slot

    return this
  }



  getUser() {
    this.user = this.state.user
    return this
  }

  getRoles(positions = false) {
    this.roles = this.state.user.branch.roles
    if (positions) {
      this.positions = positions
    }
    let roles = this.roles
    if (this.positions) {
      roles?.forEach((role, role_index) => {
        roles[role_index]['positions'] = this.positions.filter(position => roles[role_index].id == position.role_id)
      })
    }
    this.roles = roles
    this.all = roles
    return this
  }

  getRole() {
    let roles = this.state.user.branch.roles
    if (this.position) {
      roles?.forEach((role, role_index) => {
        if (this.position && this.position.role_id == role.id && !roles[role_index]['positions'].find(pos => pos.id == this.position?.id)) {
          roles[role_index]['positions'].push(this.position)
        }
      })
    }
    return this
  }

  getPositions() {
    this.positions = this.state.user.branch.positions
    if (this.roles) {
      this.positions?.forEach((position, position_index) => {
        this.positions[position_index]['roles'] = this.roles.filter(role => role.id == position.role_id)
      })
    }
    return this
  }

  getSlotsByRoleId(role_id) {
    this.all = this.state.currentWeek.slots.filter(slot => slot.role_id == role_id)
    this.who = "getSlotsByRoleId"
    return this
  }
  getShiftSlotsByRoleId(role_id) {
    if (this.shift) {
      this.all = this.shift.slots.filter(slot => {
        return slot.role_id == role_id
      })
      this.first = this.all[0]
      this.count = this.all.length
    }
    this.who = "getShiftSlotsByRoleId"
    return this
  }
  slotStatus(currentWeek, slotId, selectedSlotId, watcherId) {
    currentWeek = currentWeek
    this.slotId = slotId
    this.selectedSlotId = selectedSlotId
    this.watcherId = watcherId
    this.slot = currentWeek.slots.find(slot => slot.id == this.slotId)
    this.fill = false
    this.className = this.classByStatus['natural']
    // fake slot
    if (!this.slot?.id) {
      this.className = this.classByStatus['fake-slot']
      this.fill = true
      return this
    }

    this.slot.shift = currentWeek.shifts.find(shift => shift.id == this.slot.shift_id)
    this.slot.day = currentWeek.days.find(shift => shift.id == this.slot.shift.id)
    this.slot.requests = currentWeek.requests.filter(request => request.shift_id == this.slot.shift.id)

    // selected slot
    if (this.slotId === this.selectedSlotId) {
      this.className = this.classByStatus['watch-slot']
      return this
    }
    // this.slotReplaceAssingments()
    // assgined slot
    if (this.slot.user_id) {
      this.assginedSlotStatus()
      // not assgined slot
    } else {
      this.notAssginedSlotStatus()

    }


    return this
  }

  shift_role_counts = {

  }

  assginedSlotStatus() {
    // if the slot belongs to the watcher and was requested
    if (this.slot.user_id === this.watcherId && this.slot.requests.find(request => request.user_id == this.watcherId)) {
      this.className = this.classByStatus['shift-requested-by-watch-not-assgined']
    }
    // if the slot addgined to a user that did not reequested it
    if (!this.slot.requests.find(request => request.user_id == this.slot.user_id)) {
      this.className = this.classByStatus['slot-assgined-not-requested-by-user']
    }
    return this
  }

  notAssginedSlotStatus() {
    if (!this.watcherId) {
      this.shift ||= this.state.currentWeek.shifts.find(shift => shift.id == this.slot.shift_id)
      let role_slots = this.shift.slots?.filter(slot => slot.role_id == this.slot.role_id)
      let slot_index = role_slots.findIndex(slot => slot.id == this.slot.id)
      let main_employees = this.shift.main_employees[this.slot.role_id]
      let filtered_main_employees = main_employees?.filter(employee_id => !this.shift.slots.map(slot => slot.user_id).includes(employee_id) )
      let number_slots_to_color = filtered_main_employees?.length
      for (let index = 0; index < slot_index; index++) {
        const slot = role_slots[index];
        if(!slot) break
        if(slot.user_id) {
          number_slots_to_color++
        }
      }
      
      if(number_slots_to_color > slot_index) {
        this.className = this.classByStatus['not-assgined-requested-by-main']
      }

    }
    // }
    return this
  }


  classByStatus = {
    "natural": "bg-light", //
    "watch-slot": "watch-slot", //
    "fake-slot": "bg-gray-light", //
    "shift-requested-by-watch-not-assgined": "border", //
    "not-assgined-requested-by-main": "not-assgined-requested-main", //
    "not-assgined-requested-by-other": "not-assgined-requested-other",
    "not-assgined-not-requested": "not-assgined-not-requested",
    "slot-assgined-requested-by-main": "assgined-requested-main", //
    "slot-assgined-requested-by-other": "assgined-requested-other", //
    "slot-assgined-requested-by-watch": "assgined-requested-watch",
    "slot-assgined-not-requested-by-user": "assgined-not-requested",

  }

  shiftRequestedByUser(user_id, shift_id) {
    this.shift ||= this.state.currentWeek.shifts.find(shift => shift.id == shift_id)
    this.shift.requests ||= this.state.currentWeek.requests.filter(request => request.shift_id == shift_id)
    if (this.shift.requests.find(request => request.user_id == user_id)) {
      return true
    } else {
      return false
    }
  }

  userAssginedToShift(shift_id, user_id) {
    this.shift ||= this.state.currentWeek.shifts.find(shift => shift.id == shift_id)
    this.shift.slots ||= this.state.currentWeek.slots.filter(slot => slot.shift_id == shift_id)
    if (this.shift.slots.find(slot => slot.user_id == user_id)) {
      return true
    } else {
      return false
    }
  }

  slotRequestedByType(type = "main", userId) {
    if (userId) {
      if (type == "main") {
        return this.slot.main_available_employees.find(employee => employee.id == userId)
      } else {
        return this.slot.available_employees.find(employee => employee.id == userId) && !this.slot.main_available_employees.find(employee => employee.id == userId)
      }
    } else {
      if (type == "main") {
        return this.slot.main_available_employees.count > 0
      } else {
        return this.slot.available_employees.count - this.slot.main_available_employees.count > 0
      }
    }
  }

  getEmployeeRoles(userId, type = "main") {
    this.employee ||= this.state.user.branch.employees.find(employee => employee.id == userId)
    if (type == "main") {
      return this.employee.main_role
    } else {
      return this.employee.roles
    }
  }

  slotReplaceAssingments() {
    if (this.slot && this.slot.user_id) {
      this.user = this.state.user.branch.employees.find(employee => employee.id == this.slot.user_id)
      this.user.other_requests = this.state.currentWeek.requests.filter(request => request.user_id == this.slot.user_id).filter(request => request.shift_id != this.slot.shift_id)
      let other_shifts_ids = this.user.other_requests.map(request => request.shift_id)
      this.user.other_shifts = this.state.currentWeek.shifts.filter(shift => other_shifts_ids.includes(shift.id))
      // this.user.other_shifts = this.user.other_shifts.filter(shift => !shift.slots.find(slot => slot.user_id))

      return this.user.other_shifts
    }
    return []
  }
}


export default State;