import { langsGeneral } from "./langs_general"

let EN = {
    missing_translation: "missing translation for",
    unknown: "unknown",
    please_sign_in: "please sign in",
    not_authorized_for_this_page: "this page can be accessed only by authorized users",
    signup: "signup",
    sign_in: "sign in",
    logout: "logout",
    new: "new",
    general: "general",
    show: "show",
    change: "change",
    active: "active",
    work_day: "work_day",
    langs: "languages",
    text_404: "The page you are looking for does not exist",
    loading: "thinking about it...",
    hello: "hello",
    build_schedule: "schedule editing",
    schedule_structure: "schedule structure",
    build_schedule_for_this_week: "build new schedule for this week",
    save_changes: "save changes",
    edit: "edit",
    save: "save",
    saved: "saved",
    structure_saved: "the schedule structure was successfully saved!",
    schedule: "schedule",
    no_schedule: "no schedule has been created yet for this week",
    requests: "requests",
    settings: "settings",
    next_week: "week forward",
    current_week: "week backward",
    prev_week: "previous week",
    day_1: "Sunday",
    day_2: "Monday",
    day_3: "Tuesday",
    day_4: "Wednesday",
    day_5: "Thursday",
    day_6: "Friday",
    day_7: "Saturday",
    shift: "shift",
    day: "day",
    day_off: "A Day Off!",
    add_shift: "add shift",
    remove_shift: "remove shift",
    shift_settings: "shifts settings",
    cancel: "cancel",
    employees: "employees",
    system_settigns: "system settings",
    main: "main",
    role: "role",
    roles: "roles",
    main_role: "main role",
    additional_roles: "additional roles",
    as: "as",
    self: "myself",
    submit: 'submit',
    total: "total",
    add_slots: "add employees",
    remove_slots: "remove employees",
    schedule_page: {
        create_new_schedule: "create new schedule",
        create_new_confirmation_msg: "המשך יצור סידור חדש בהתאם למבנה הסידור המוגדר במערכת. האם להמשיך?",
        create_new_confirmation_msg: "confirming will create a new schedule according to the schedule structure saved in the system. do you want to continue?",
    },
    requests_page : {
        title: "requests submition",
        requests_saved: {
            title: "success",
            message: "Shifts Requests submited successfully!",
        },
    },
    user_page: {
        email: "e-mail",
        username: "username",
        first_name: "first name",
        last_name: "last name",
        mobile: "mobile",
        birth_date: "birth date",
    },
    gender: {
        title: "gender",
        male: "male",
        female: "female",
    },
    login: {
        title: "התחברות",
        for_new_and_exisiting: "משתמש חדש? משתמש ותיק? זה לא משנה... כאן נכנסים"
    },
    welcome: {
        title: "ברוכים הבאים",
        all: {},
        new_user: {},
    }

}

// EN += langsGeneral

export default EN