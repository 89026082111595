import React, { useEffect, useState } from 'react'
import { getMaxObjectsByRoleAndShift } from '../../../../utils/scheduling_utils'
import Slot from './Slot'
import { v4 as uuidv4 } from 'uuid';
import State from '../../../../utils/current_week_utils';
import { useWorkey } from '../../../../WorkeyContext';
import { makeApiRequest } from '../../../../utils/utils';
import { rebuildWeek } from '../../../../utils/week_utils';

const FillSlot = (props) => {
    const { maxPerRole, currentWeek, setCurrentWeek, edit, day_id, shift_id, role_id, selectedSlotId, setSelectedSlotId, setShowTimeModal, toggleUpdate } = props
    const { globalState, setGlobalState } = useWorkey()

    // const [maxForRole] = useState(getMaxObjectsByRoleAndShift(currentWeek, null, role_id)?.[0])
    const [maxForShiftAndRole, setMaxForShiftAndRole] = useState(getMaxObjectsByRoleAndShift(currentWeek, shift_id, role_id)?.[0])
    useEffect(() => {
        setMaxForShiftAndRole(getMaxObjectsByRoleAndShift(currentWeek, shift_id, role_id)?.[0])
    }, [currentWeek.id, currentWeek?.slots.length])
    // stateUtils.getDay(false, day_id).getShift(false, shift_id).getShiftSlotsByRoleId(stateUtils.getRole(role_id).first.id).count
    const addSlotToShift = async () => {
        if (!edit) { return }
        const req = await makeApiRequest(globalState, setGlobalState, '/slots', 'POST', { shift_id: shift_id, role_id: role_id })
        const data = req[0]
        const res = req[1]
        if (res.status === 201) {
            let new_current_week = { ...currentWeek }

            const day_num = new_current_week.days.findIndex(day => day.id == day_id)
            const shift_num = new_current_week.days[day_num].shifts.findIndex(shift => shift.id == shift_id)
            new_current_week.days[day_num].shifts[shift_num].slots.push(data)
            new_current_week.shifts[new_current_week.shifts.findIndex(s => s.id == shift_id)].slots.push(data)
            new_current_week.slots.push(data)
            new_current_week = await rebuildWeek(new_current_week, globalState)
            await setCurrentWeek(new_current_week)
            await setGlobalState({ ...globalState, currentWeek: new_current_week })
            // toggleUpdate()
            // setFillStatus(false)
            setMaxForShiftAndRole(getMaxObjectsByRoleAndShift(new_current_week, shift_id, role_id)?.[0])
        }
    }

    const fillers = () => {
        let fill = [];
        const maxForShiftAndRoleCount = maxForShiftAndRole?.count || 0
        // const maxForRoleCount = maxForRole?.count || 0
        
        const day_i= currentWeek.days.findIndex(day => day.id == day_id)
        const shift_i = currentWeek.days[day_i]?.shifts?.findIndex(shift => shift.id == shift_id)
        const maxForRoleCount = maxPerRole?.[shift_i]?.[role_id] || 0    
        for (let i = 0; i < maxForRoleCount - maxForShiftAndRoleCount; i++) {
            fill.push(<div key={i} className="filler">
                <Slot
                    addSlotToShift={addSlotToShift}
                    currentWeek={currentWeek}
                    setCurrentWeek={setCurrentWeek}
                    edit={edit}
                    day_id={day_id}
                    shift_id={shift_id}
                    selectedSlotId={selectedSlotId}
                    setSelectedSlotId={setSelectedSlotId}
                    setShowTimeModal={setShowTimeModal}
                />
            </div>);
        }
        return fill
    }


    return (
        fillers().map((filler, i) => { return filler })
    )
}

export default FillSlot