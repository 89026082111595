import React from 'react'
import { Icon } from '@mdi/react';
import { Link, Navigate } from 'react-router-dom'
import { useWorkey } from '../../WorkeyContext'
import { IconSize, isSmallScreen } from '../../utils/utils';
const defaultLink = {
    text: ""
}
const SubLocation = ({ sizeClass, link = defaultLink, action, toggleSideBar }) => {
    const { globalState } = useWorkey()
    const data = action || link

    const checkAccess = (link, user) => {
        return Object.keys(user.data['authorized_actions']).some((action) => {
            return link.access_levels.hasOwnProperty(action) && link.access_levels[action];
        });
    }




    if (true) {
        return (
            <div className='d-flex flex-row ltr link mt-3 pointer' onClick={() => { action && action.callback('test this') }} >
                <Link to={data.path}
                    onClick={() =>  isSmallScreen() && globalState.largeSidebar ? toggleSideBar() : {} }>
                    <div className={`d-flex flex-row`}>
                        <span className={`${data.path == window.location.pathname ? " fw-bolder" : ""}${sizeClass == 'sm' ? "hidden" : ""}`}>{data?.text}</span>
                        {/* <div className='icon'> */}
                        <Icon title={sizeClass == 'sm' ? (data?.title || data?.text) : null} className={`sidebar-icon ${data.path == window.location.pathname ? "app-light-blue-txt" : ""}`} path={data?.icon} size={IconSize} />
                    </div>
                    {/* </div> */}
                </Link>
            </div>
        )
    }
}

export default SubLocation