import React, { useState } from 'react'
import { Icon } from '@mdi/react';
import RoleItem from './RoleItem'
import { useWorkey } from '../../../../WorkeyContext'
import { mdiMinusCircle, mdiPencil, mdiPlusCircle, mdiToolboxOutline, mdiTrashCan, mdiTrashCanOutline } from '@mdi/js';
import { IconSize, makeApiRequest, setupRunner } from '../../../../utils/utils';
import './RolesList.css'
import '../EmployeesSettingsLists.css'
import { Trans } from '../../../../lang/Trans';
import { useNavigate } from 'react-router-dom';
import AddNewRoleModal from './AddNewRoleModal';
import { toast } from 'react-hot-toast';
const RolesList = () => {
    const { globalState, setGlobalState } = useWorkey()
    const [rolesTools, setRolesTools] = useState(false)
    const [selectedTool, setSelectedTool] = useState(false)
    const [addRoleModal, setAddRoleModal] = useState(false)
    const [editRole, setEditRole] = useState(false)


    const navigate = useNavigate()

    const removeRole = async (id, confirm = false) => {
        if (!confirm) {
            await toast((t) => <div>
                <div>מחיקה של תפקיד תסיר את התפקיד הזה עבור כל העובדים שמשוייכים לתפקיד זה. האם להמשיך?</div>
                <div className='my-3'>
                    <div className='btn btn-sm btn-app-red ms-4' onClick={() => removeRole(id, true)}>מחק</div>
                    <div className='btn btn-sm btn-app-blue' onClick={() => toast.dismiss(t.id)}>לא</div>
                </div>
            </div>)
            return
        }

        const req = await makeApiRequest(globalState, setGlobalState, `/roles/${id}`, 'DELETE')
        const data = req[0]
        const res = req[1]
        if (res.status === 204) {
            await setupRunner(globalState, setGlobalState, { branch: true })
        } else {
            toast.error(data.error ||<Trans keys={'toast.feild_to_delete.role'} />);        }
    }

    const saveEditRole = async () => {
        let new_roles = [...globalState.user.branch.roles]
        new_roles[new_roles.findIndex(role => role.id === editRole.id)].name = editRole.value
        if (editRole.id) {
            const req = await makeApiRequest(globalState, setGlobalState, `/roles/${editRole.id}`, 'PUT', { id: editRole.id, name: editRole.value })
            const res = req[1]
            const data = req[0]
            if (res.status === 201) {
                setGlobalState({
                    ...globalState,
                    user: {
                        ...globalState.user,
                        branch: {
                            ...globalState.user.branch,
                            roles: new_roles
                        }
                    }
                })
            } else {
                toast.error(data.error ||<Trans keys={'toast.feild_to_update.role'} />);            }
        }
        setEditRole({ id: null, value: null })
    }

    return (
        <div className='employees-settings-lists-container'>
            {addRoleModal && <AddNewRoleModal selectedRole={null} setAddRoleModal={setAddRoleModal} />}
            {editRole && <AddNewRoleModal selectedRole={editRole} setAddRoleModal={setEditRole} />}
            <div className='employees-settings-list card'>
                <div className='card-header text-center'>
                    תפקידים
                    <Icon path={mdiToolboxOutline} size={IconSize} className={`pointer toolsbox-btn ${rolesTools ? "lg-btn-selected" : ""}`} onClick={() => setRolesTools(!rolesTools)} />
                </div>
                {rolesTools ? <div className='actions'>
                    <Icon path={mdiMinusCircle} size={IconSize}
                        className={`mx-1 pointer text-danger ${selectedTool == 'remove' ? "lg-btn-selected" : ""}`}
                        onClick={() => setSelectedTool(selectedTool == 'remove' ? false : 'remove')} />
                    <Icon path={mdiPlusCircle} size={IconSize}
                        className={`mx-1 pointer text-success ${selectedTool == 'add' ? "lg-btn-selected" : ""}`}
                        onClick={() => setSelectedTool(selectedTool == 'add' ? false : 'add')} />
                </div> : <></>}
                <div className='card-body'>
                    {globalState.user?.branch?.roles?.length > 0 ? globalState.user.branch.roles.map((role) => {
                        return (
                            <div className='card p-2 m-1 d-flex flex-row justify-content-between'>
                                {role?.name && role?.id && <div className=''>
                                    <RoleItem
                                        key={`role_${role.id}`}
                                        role={role}
                                        editRole={editRole}
                                        saveEditRole={saveEditRole}
                                        setEditRole={setEditRole} />
                                </div>}
                                <div>
                                    <Icon path={mdiTrashCan} size={1} className='mx-2 pointer app-red-txt' onClick={() => { removeRole(role.id) }} />
                                    <Icon path={mdiPencil} size={1} className='pointer app-blue-txt' onClick={() => setEditRole(role)} />
                                </div>
                            </div>
                        )
                    }) : <></>}
                </div>
            </div>

            <div className='employees-settings-lists-actions-container'>
                <div className='btn btn-app-green' onClick={() => setAddRoleModal(true)}>
                    <Trans keys={"employees_settings_lists.roles.add"} />
                </div>
                {/* <div className='btn btn-app-red' onClick={() => setRoleToRemove(0)}>
                    <Trans keys={"employees_settings_lists.roles.remove"} />
                </div> */}
            </div>
        </div>
    )
}

export default RolesList