import React, { useEffect, useState } from 'react'
import { Trans } from '../../../lang/Trans'
import RolesList from '../../settings/employees-settings/roles-list/RolesList'
import { useWorkey } from '../../../WorkeyContext'
import Structure from '../structure/Structure'

const ScheduleSetupFlow = ({ endSetup }) => {
    const { globalState } = useWorkey()
    const [needed, setNeeded] = useState(null)

    useEffect(() => {
        let new_needed = { roles: globalState.user.branch.roles?.length < 1, schedule_structure: !globalState.user.branch.data.week_structure?.days }
        setNeeded(new_needed)
    }, [])

    const removeNeeded = (key) => {
        let new_needed = { ...needed }
        delete new_needed[key]
        setNeeded(new_needed)
    }


    const displayStep = () => {
        // let display
        if (needed?.roles) {
            // return <>
            return <>
                <div className='mt-3'><Trans keys={'schedule_setup_flow.we_need'} /> {needed !== null && <Trans keys={'schedule_setup_flow.also'} />} <Trans keys={'schedule_setup_flow.we_need_roles'} /></div>
                {globalState.user.branch.roles.length > 0 && <div className='mt-2 btn btn-app-blue' onClick={() => removeNeeded('roles')}>
                    <Trans keys={`schedule_setup_flow.next_step`} />
                </div>}
                <RolesList />

            </>

            // </>
        } else if (needed?.schedule_structure) {
            // return <>
            return <div>
                <div className='mt-3'><Trans keys={'schedule_setup_flow.we_need'} /> {needed !== null && <Trans keys={'schedule_setup_flow.also'} />} <Trans keys={'schedule_setup_flow.we_need_schedule_structure'} /></div>
                {globalState.user.branch.schedule_structure?.days && <div className='mt-2 btn btn-app-blue' onClick={() => removeNeeded('schedule_structure')}>
                    <Trans keys={`schedule_setup_flow.next_step`} />
                </div>}
                <div className='mt-2'><Structure removeNeeded={removeNeeded} /> </div>
            </div>

            // </>

        } else {
            return <div className='mt-2 btn btn-app-green' onClick={endSetup}>
                <Trans keys={`schedule_setup_flow.i_am_done`} />
            </div>
        }


    }

    return <>
        <div style={{ position: 'absolute', backgroundColor: '#FFF', width: '100%', height: '100%', zIndex: 100, textAlign: 'center' }}>
            {needed === null && <div className='mt-3'><Trans keys={'schedule_setup_flow.explain'} /></div>}
            {displayStep()}
        </div>
    </>

}

export default ScheduleSetupFlow