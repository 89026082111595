import React, { useEffect, useState } from 'react'
import './SmartSchedule.css'
import { getCurrentWeek, isSmallScreen, makeApiRequest } from '../../../utils/utils'
import { useWorkey } from '../../../WorkeyContext'
import Navigation from '../display/tools/Navigation'
import { initialTools } from '../display/tools/tools_utils'
import Toolsbox from '../display/tools/Toolsbox'
import AddSlotsModal from '../display/tools/add-slots-modal/AddSlotsModal'
import SideTools from '../display/tools/side-tools/SideTools'
import SlotTimeModal from '../display/tools/slot_time_modal/SlotTimeModal'
import toast from 'react-hot-toast';
import { lastFirstDay } from '../../../utils/scheduling_utils'
import NoSchedule from '../NoSchedule'
import Day from '../display/day/Day'
import { Trans } from '../../../lang/Trans'
import { useLocation } from 'react-router-dom'
import Tips from '../../wages/tips/Tips'
import _, { isEqual } from 'lodash'
import secureLocalStorage from 'react-secure-storage'
import { rebuildWeek } from '../../../utils/week_utils'
import UnavailableFeature from '../../blocks-ui/UnavailableFeature'
const SmartSchedule = ({ edit }) => {
    const { globalState, setGlobalState } = useWorkey()
    const [currentWeek, setCurrentWeek] = useState(null)
    const [toolsboxMenu, setToolsboxMenu] = useState(false);
    const [tools, setTools] = useState(initialTools)
    const [selectedSlotId, setSelectedSlotId] = useState(false);
    const [employeeWatch, setEmployeeWatch] = useState(false)
    const [showTimeModal, setShowTimeModal] = useState(false)
    const [timeModalSlotId, setTimeModalSlotId] = useState(null)
    const [maxPerRole, setMaxPerRole] = useState({})
    const [forceUpdate, setForceUpdate] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [showTips, setShowTips] = useState(false)
    const [originalWeek, setOriginalWeek] = useState(null)
    const [mark, setMark] = useState(false)
    useEffect(() => {
        pageLoad()
    }, [])

    useEffect(() => {
        if(edit && currentWeek?.id) {
            toast((t) => <div>
            <div>פעולה זו תביא למחיקת שינויים שלא נשמרו אם יש כאלה, האם להמשיך?</div>
            <div className='d-flex flex-row justify-content-center mt-2'>
                <div className='btn btn-sm mx-3 btn-app-red' onClick={() => {pageLoad(); toast.dismiss(t.id)}}>המשך</div>
                <div className='btn btn-sm mx-3 btn-app-blue' onClick={() => {toast.dismiss(t.id)}}>לא, רוצה לשמור קודם</div>
            </div>
        </div>)
        } else {
            pageLoad()
        }
        
    }, [globalState.weekRef, edit])

    const pageLoad = async (method = "GET") => {
        await setCurrentWeek(null)
        const current_week = await getCurrentWeek(globalState, setGlobalState, method)

        if (current_week) {
            // if (current_week.status === "published" || edit) {
            getMaxPerRole(current_week)
            await setCurrentWeek({ ...current_week })
            let new_global_state = { ...globalState }
            new_global_state.currentWeek = { ...current_week }
            await setOriginalWeek({ ...current_week })
            await setGlobalState(new_global_state)
            // }
        } else {
            toast.error(<Trans keys={'server_error'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })

        }
    }

    const saveWeek = async (t = false) => {
        if (currentWeek.status === "published") {
            if (!t) {
                toast((t) => <div>
                    <div><Trans keys={`schedule_page.are_you_sure_save_changes`} /></div>
                    <div className='d-flex flex-row justify-content-center mt-2'>
                        <div className='btn btn-sm btn-app-green mx-2' onClick={() => saveWeek(t)}><Trans keys="yes" /></div>
                        <div className='btn btn-sm btn-app-red mx-2' onClick={() => toast.dismiss(t.id)}><Trans keys={"cancel"} /></div>
                    </div>
                </div>, { duration: 10 * 1000, position: 'top-right' })
                return
            } else {
                toast.dismiss(t.id)

            }
        }
        let save_slots = []
        currentWeek.slots?.forEach((slot) => {
            let slot_data = {
                id: slot.id,
                user_id: slot.user_id,
                start: slot.start,
                end: slot.end,
                role_id: slot.role_id,
                shift_id: slot.shift_id,
                expected_start: slot.expected_start,
                expected_end: slot.expected_end,
                total_hours: slot.total_hours,
                data: slot.data,
            }
            save_slots.push(slot_data)
        })
        const req = await makeApiRequest(globalState, setGlobalState, `/weeks/${currentWeek.id}`, 'PUT', {
            slots: save_slots,
        });
        const res = req[1]
        const data = req[0]
        if (res.status === 200) {
            let week = await rebuildWeek(data)
            setGlobalState({ ...globalState, currentWeek: week })
            setCurrentWeek(week)
        }
    }


    useEffect(() => {
        if (currentWeek?.id && currentWeek?.slots) {
            getMaxPerRole(currentWeek)
        }
    }, [forceUpdate, currentWeek?.slots?.length, currentWeek?.shifts?.length, currentWeek?.days?.length])
    const getMaxPerRole = (current_week = null) => {
        let week = current_week || currentWeek;
        const bla = globalState?.user?.branch?.roles?.reduce((obj, role) => ({ ...obj, [role.id]: 0 }), {});

        let result = [];

        week?.days?.filter(day => !day.hide)?.forEach((day) => {
            day?.shifts?.forEach((shift, shit_i) => {
                if (shift.hide) {
                    return
                }
                if (!result[shit_i]) {
                    result[shit_i] = { ...bla };
                }
                Object.keys(result[shit_i])?.forEach((role_id) => {
                    let count = shift.slots?.filter((slot) => slot.role_id == role_id).length || 0;
                    if (result[shit_i][role_id] < count) {
                        result[shit_i][role_id] = count;
                    }
                });
            });
        });

        setMaxPerRole(result);
    };
    const toggleToolsMenu = () => {
        // setTools(initialTools)
        setToolsboxMenu(!toolsboxMenu)
    }

    const toggleForceUpdate = () => {
        setForceUpdate(!forceUpdate)
    }

    const days_list = () => {
        if (!currentWeek?.days || currentWeek?.days?.length < 1 || !currentWeek?.days[0].id) {
            return null
        } else {
            return currentWeek?.days?.map((day, i) => <Day
            mark={mark}
                toggleForceUpdate={toggleForceUpdate}
                maxPerRole={maxPerRole}
                currentWeek={currentWeek}
                setCurrentWeek={setCurrentWeek}
                employeeWatch={employeeWatch}
                key={`day_${i}`}
                tools={tools}
                edit={edit}
                selectedSlotId={selectedSlotId}
                setSelectedSlotId={setSelectedSlotId}
                day_num={i}
                toggleTimeModal={toggleTimeModal} />)
        }
    }
    const toggleTimeModal = (selected_slot_id) => {
        if (!showTimeModal) {
            setTimeModalSlotId(selected_slot_id)
        } else {
            setTimeModalSlotId(false)
        }
        setShowTimeModal(!showTimeModal)

    }

    const hiddenDaysCount = () => {
        let count = 0
        currentWeek.days?.forEach((day) => {
            if (day.hide) {
                count++
            }
        })
        return count
        
    }

    if(!globalState.user.branch?.active_subscriptions.find(subscription => subscription.subscription_type === 'menual_schedule')) {
        return <div>
            <UnavailableFeature/>
        </div>
    }

    return (
        <div className={`smart-schedule ${fullScreen ? 'full-screen' : ''}`}>
            <div className='smart-schedule-container'>
                <Navigation
                    mark={mark}
                    setMark={setMark}
                    saveWeek={saveWeek}
                    setShowTips={setShowTips}
                    createNewWeek={pageLoad}
                    fullScreen={fullScreen}
                    setFullScreen={setFullScreen}
                    currentWeek={currentWeek}
                    setCurrentWeek={setCurrentWeek}
                    location={'schedule'}
                    edit={edit}
                    toolsboxMenu={toolsboxMenu}
                    toggleToolsMenu={toggleToolsMenu} />
                {(toolsboxMenu && edit) ? <Toolsbox tools={tools} setTools={setTools} /> : <></>}
                {!currentWeek?.id || (!edit && currentWeek.status !== "published") ? <NoSchedule edit={edit} setCurrentWeek={setCurrentWeek} />
                    :
                    <>
                        {timeModalSlotId && <SlotTimeModal currentWeek={currentWeek} setCurrentWeek={setCurrentWeek} show={showTimeModal} toggleTimeModal={toggleTimeModal} timeModalSlotId={timeModalSlotId} />}
                        {showTips && <Tips week={currentWeek} setShowTips={setShowTips} />}
                        <div className={`smart-schedule-display-container ${isSmallScreen() && hiddenDaysCount() > 0 ? 'mt-5' : ''}`}>
                            <div className='smart-schedule-tools'>
                                {currentWeek?.id && <SideTools
                                    edit={edit}
                                    currentWeek={currentWeek}
                                    setCurrentWeek={setCurrentWeek}
                                    selectedSlotId={selectedSlotId}
                                    setSelectedSlotId={setSelectedSlotId}
                                    employeeWatch={employeeWatch}
                                    setEmployeeWatch={setEmployeeWatch} />}
                            </div>
                            <div className='smart-schedule-display'>
                                {tools?.add_slots && <AddSlotsModal currentWeek={currentWeek} setCurrentWeek={setCurrentWeek} />}

                                {/* <div className='days'> */}
                                {days_list()}
                                {/* <div>{currentWeek && JSON.stringify(currentWeek)}</div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default SmartSchedule