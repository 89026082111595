import React from 'react'
import ShiftStructure from './ShiftStructure'
import { fakeShiftId } from '../../../utils/structure_utils'
import { Trans } from '../../../lang/Trans'
const DayStructure = ({ weekStructure, setWeekStructure, day_i }) => {
    const day_num = day_i + 1
    const day = weekStructure.days[day_i]

    const addShift = () => {
        let days = [...weekStructure.days]
        let fake_shift_id = fakeShiftId(day_num, days[day_i].shifts.length)
        days[day_i].shifts.push({ id: fake_shift_id, slots: { shift_id: fake_shift_id, role_id: null } })
        setWeekStructure({ ...weekStructure, days })
    }

    return (
        <div className='day-structure col'>
            <div className='day-structure-header'>
                <Trans keys={`day_${day_num}`} />
            </div>
            <div className='day-structure-actions'>
                <div className='add-shift btn btn-sm btn-success' onClick={() => addShift()}>
                    <Trans keys={"add_shift"} />
                </div>
            </div>
            <div className='day-structure-body'>
                {day.shifts.length > 0 ? day.shifts.map((shift, shift_i) => {
                    return (
                        <ShiftStructure
                            key={`shift_structure_${shift_i}`}
                            weekStructure={weekStructure}
                            setWeekStructure={setWeekStructure}
                            day_i={day_i}
                            shift_i={shift_i}
                        />
                    )
                }) :
                    <div className='text-center mt-5 fw-bolder'><Trans keys={"day_off"} /></div>}
            </div>
        </div>
    )
}

export default DayStructure