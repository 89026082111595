import React, { useState } from 'react'
import { displayTime, makeApiRequest, userDisplayName } from '../../utils/utils'
import { useWorkey } from '../../WorkeyContext'
import { Trans } from '../../lang/Trans'
import moment from 'moment'
import Icon from '@mdi/react'
import { mdiCancel, mdiCashCheck, mdiCashFast, mdiCheck, mdiCheckBold, mdiCurrencyIls, mdiPencil, mdiTrashCan } from '@mdi/js'
import { toast } from 'react-hot-toast'

const Wage = ({ wage, wages, setWages }) => {
  const { globalState, setGlobalState } = useWorkey()
  const employee = globalState.user.branch.employees.find(employee => employee.id == wage.user_id)
  const position = globalState.user.branch.positions.find(position => position.role_od == wage.slot?.role_id && position.user_id == employee?.id)
  const role = globalState.user.branch.roles.find(role => role.id == wage.slot?.role_id)
  const [editMode, setEditMode] = useState(false)
  const [totalWage, setTotalWage] = useState(wage.total_wage)
  const saveWage = async () => {
    let new_wage = { ...wage, total_wage: Number(totalWage).toFixed(2) }
    const req = await makeApiRequest(globalState, setGlobalState, `/wages/${wage.id}/`, 'PUT', new_wage)
    const res = req[1]
    if (res.status === 200 || res.status === 201) {
      let new_wages = [...wages]
      let index = new_wages.findIndex(w => w.id == wage.id)
      if (index > -1) {
        new_wages[index] = new_wage
      }
      await setWages(new_wages)
      await toast.success(<Trans keys={'toast.record_saved'} />, { duration: 5000, position: 'top-left', className: 'app-green-txt' })
      setEditMode(false)
    } else {
      toast.error(res.statusText, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
    }
  }
  const deleteWage = async (t = false) => {
    if (!t) {
      return toast(t => <div>
        <div><span className='fw-bold ms-2'>אתה עומד למחוק רשומה זו. פעולה זו אינה ניתנת לביטול</span>
          (אם כי חישוב מחדש של הטיפים באותה משמרת יצור רשומה חדשה). האם למחוק?

        </div>
        <div className='mt-2'>
          <div className='btn btn-sm btn-app-red' onClick={() => deleteWage(t)}><Trans keys={'delete'} /></div>
          <div className='btn btn-sm btn-app-blue me-2' onClick={() => toast.dismiss(t.id)}><Trans keys={'no'} /></div>
        </div>
      </div>, { duration: 7 * 1000, position: 'top-center' })
    } else {
      await toast.dismiss(t.id)
      const req = await makeApiRequest(globalState, setGlobalState, `/wages/${wage.id}/`, 'DELETE')
      const res = req[1]
      if (res.status === 204) {
        setWages(wages.filter(w => w.id != wage.id))
        toast.success(<Trans keys={'toast.record_deleted'} />, { duration: 5000, position: 'top-left', className: 'app-green-txt' })
      } else {
        toast.error(res.statusText, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
      }
    }
  }

  const markAsPaid = async () => {
    const req = await makeApiRequest(globalState, setGlobalState, `/wages/${wage.id}/`, 'PUT', { paid: true })
    const res = req[1]
    if (res.status === 200 || res.status == 201) {
      toast.success(<Trans keys={'toast.record_updated'} />, { duration: 5000, position: 'top-left', className: 'app-green-txt' })
      setEditMode(false)
      let index = wages.findIndex(w => w.id == wage.id)
      if (index > -1) {
        let new_wages = [...wages]
        new_wages[index] = { ...wage, paid: true }
        setWages(new_wages)
      } else {
        toast.error(res.statusText, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
      }
    }
  }
  return editMode ?
    (
      <tr>
        <td>{moment(wage?.date).format('DD.MM.YYYY')}</td>
        <td>{employee ? userDisplayName(employee, true) : globalState.user.branch.name}</td>
        <td><Trans keys={`wages.${wage.wage_type}`} /></td>
        <td>{role ? role.name : ''}</td>
        <td>{wage.wage_percent}</td>
        <td>{wage.slot?.start}</td>
        <td>{wage.slot?.end}</td>

        <td>{displayTime(wage.total_hours)}</td>
        <td>{totalWage / wage.total_hours} <Icon path={mdiCurrencyIls} size={0.5} /></td>

        <td><input type={'number'} min={0} value={totalWage} className='form-control' onChange={(e) => setTotalWage(e.target.value)} /></td>
        <td>
          <Icon path={mdiCheckBold} size={1} onClick={() => saveWage()} className='pointer app-green-txt' />
          <Icon path={mdiCancel} size={1} onClick={() => setEditMode(false)} className='me-2 pointer app-red-txt' />
        </td>

      </tr>
    ) :
    (
      <tr>
        <td>{moment(wage?.date).format('DD.MM.YYYY')}</td>
        <td>{employee ? userDisplayName(employee, true) : globalState.user.branch.name}</td>
        <td><Trans keys={`wages.${wage.wage_type}`} /></td>
        <td>{role ? role.name : ''}</td>
        <td>{wage.wage_percent}</td>
        <td>{wage.slot?.start}</td>
        <td>{wage.slot?.end}</td>
        <td>{displayTime(wage.total_hours)}</td>
        <td>{(wage.total_wage / wage.total_hours).toFixed(2)} <Icon path={mdiCurrencyIls} size={0.5} /></td>
        <td>{Number(wage.total_wage).toFixed(2)} <Icon path={mdiCurrencyIls} size={0.5} /></td>
        <td>
          <Icon
            title={'מחק'}
            path={mdiTrashCan}
            size={1}
            onClick={() => deleteWage()}
            className='ms-2 pointer app-red-txt' />
          {wage.wage_type === 'salery' && !wage.paid && <Icon
            title={'ערוך'}
            path={mdiPencil}
            size={1}
            onClick={() => setEditMode(true)}
            className='ms-2 pointer app-blue-txt' />}
          {wage.wage_type === 'tips' && !wage.paid && <Icon
            onClick={() => markAsPaid()}
            title={'סמן כשולם'}
            path={mdiCashFast}
            size={1}
            className='pointer app-green-txt' />}
          {wage.wage_type === 'tips' && wage.paid && <Icon title="שולם" path={mdiCashCheck} size={1} className='app-blue-txt' />}
        </td>

      </tr>
    )
}

export default Wage