import { Icon } from '@mdi/react';
import { mdiPencil, mdiPlusCircle, mdiWhatsapp } from '@mdi/js';
import React from 'react'
import { Trans } from '../../../../lang/Trans'
import { useWorkey } from '../../../../WorkeyContext'
import { IconSize, userDisplayName } from '../../../../utils/utils'
import MainRoleManage from '../../../user-page/profile/main-role-manage/MainRoleManage';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import { internationalNumber } from '../../../../utils/data_utils';

const EmployeeItem = ({ employee, editMainRoleForUserId, setEditMainRoleForUserId }) => {

    return (
        <div className='item employee-item'>
            <Link to={`/user/${employee.id}`}>
                <Avatar src={employee.avatar} name={employee.first_name + ' ' + employee.last_name} size="30" round={true} />
            </Link>
            <div className='employee-info-container'>
                <Link to={`/user/${employee.id}`}>
                    <div className='name-display mx-2'>
                        <div className='fw-bolder'>{userDisplayName(employee)}</div>
                        <div className='mx-1'>-</div>
                        <div><MainRoleManage user={employee} showEdit={false} setEditMainRoleForUserId={setEditMainRoleForUserId} /></div>
                    </div>
                </Link>
                <div className='employee-contact-info'>
                    <div>{employee.email}</div>
                    <div className='app-green-txt pointer'>{employee.mobile && <Icon path={mdiWhatsapp} size={1} color="green"
                        onClick={() => window.open(`https://api.whatsapp.com/send?phone=${internationalNumber(employee.mobile, false)}`)} />}
                        <Link to={`tel:${internationalNumber(employee.mobile)}`}>
                            <span>{employee.mobile}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeItem