import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import { useWorkey } from '../../../WorkeyContext';
import { Trans } from '../../../lang/Trans';

const StructureRoleSettingsModal = ({ show, handleClose }) => {
  const { globalState, setGlobalState } = useWorkey();
  const [selectedRole, setSelectedRole] = useState({});
  const [numSlots, setNumSlots] = useState(1);

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  // loop thru all slots and make sure it has {role_id: role.id} as many times as numSlots
  const setSlotsWithRoleToAllShifts = () => {
    let week = globalState.weekStructure;
    let days = [...week.days];
    for (let day_i = 0; day_i < days.length; day_i++) {
      const day = days[day_i];
      for (let shift_i = 0; shift_i < day.shifts.length; shift_i++) {
        week.days[day_i].shifts[shift_i].slots = week?.days[day_i]?.shifts[shift_i]?.slots?.length > 0 ? week.days[day_i].shifts[shift_i].slots.filter((slot) => slot.role_id != selectedRole.id) : [];
        for (let index = 0; index < numSlots; index++) {
          week.days[day_i].shifts[shift_i].slots.push({ role_id: selectedRole.id });
        }
      }
    }
    let state = { ...globalState }
    state.weekStructure = week
    setGlobalState(state);
    handleClose()
  }

  return (
    <Modal className={globalState.local ==='he' ? 'rtl' : ''} show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title><Trans keys={'set_shift_numbers'}/></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label><Trans keys={'select_role'}/></Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="role-dropdown">
              {selectedRole.name || 'Select Role'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {globalState.user?.branch?.roles?.map((role) => (
                <Dropdown.Item key={role.id} onClick={() => handleRoleSelect(role)}>
                  {role.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Form.Group>
          <Form.Label><Trans keys={'number_of_slots'}/></Form.Label>
          <Form.Control type="number" min="0" value={numSlots} onChange={(e) => setNumSlots(e.target.value)} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="app-red" onClick={handleClose}>
          <Trans keys={'cancel'}/>
        </Button>
        <Button variant="app-green" onClick={setSlotsWithRoleToAllShifts}>
          <Trans keys={'save'}/>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StructureRoleSettingsModal;
