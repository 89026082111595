import React, { useState } from 'react'
import { useWorkey } from '../../../WorkeyContext'
import User from '../../../utils/user_utils'
import { useLocation, useParams } from 'react-router-dom'
import UserSignup from '../signup/UserSignup'
import UserActive from '../user-active/UserActive'
import { Trans } from '../../../lang/Trans'
import UserEmployee from '../user-employee/UserEmployee'
import UserFake from '../user-fake/UserFake'
import './UserLayout.css'
const UserLayout = () => {
    const { globalState, setGlobalState } = useWorkey()
    const userUtils = new User(globalState)
    const [globalUserStatus, setGlobalUserStatus] = useState(globalState.user.status)
    const { userId } =  useParams()
    const USER_ID = userId || globalState.user.id
    const location = useLocation()
    const allowDisplayOfNonEmployees = false

    const getDisplayType = () => {
        let type
        if(USER_ID == globalState.user.id) {
            if(location.pathname.includes('user/create')) {
                type = 'fake_user'
            } else{
                if(globalUserStatus === userUtils.status_list['normal']) {
                    type = 'employee'
                    // type = 'active_user'
                } else { 
                    type = 'setup_user'
                }
            }
        } else {
            let employee = globalState.user?.branch?.employees?.find((e) => e.id == USER_ID)
            if(employee) {
                type = 'employee'
            } else {
                if(allowDisplayOfNonEmployees) {
                    type = 'other_employee'
                } else {
                    type = 'no_user'
                }
            }
        }
        return type
    }

    const displayType = getDisplayType()

    return (
        <div className='user-layout'>
            {displayType === 'setup_user' && <UserSignup />}
            {displayType === 'active_user' && <UserActive />}
            {displayType === 'employee' && <UserEmployee USER_ID={USER_ID} />}
            {displayType === 'fake_user' && <UserFake />}
            {/* {displayType === 'fake_user' && <UserActive />} */}
            {displayType === 'no_user' && <div className='card m-5 p-5 fw-bold text-center'><Trans keys={'no_user'} /></div>}
        </div>
    )
}

export default UserLayout