import Icon from '@mdi/react'
import React from 'react'

const CustomBtn = ({btnText = '', btnTextClass = '', btnClass = '', iconPath = '', iconClass = '', btnTitle = '', iconSize = 1, callback = () => {}}) => {
  return (
    <div className={`custom-btn-text btn d-flex flex-row justify-content-center align-items-center ${btnClass}`} onClick={callback}>
        <Icon path={iconPath} title={btnTitle || btnText} className={`custom-btn-icon ${iconClass}`} size={iconSize}/>
        <div className={`custom-btn-text mx-2 ${btnTextClass}`}>{btnText}</div>
    </div>
  )
}

export default CustomBtn