import { Icon } from '@mdi/react';
import { mdiDeleteEmpty } from '@mdi/js';

import React, { useEffect, useState } from 'react'
import { Trans } from '../../../lang/Trans'
import { useWorkey } from '../../../WorkeyContext'
import { IconSize } from '../../../utils/utils';
const ShiftStructure = ({ weekStructure, day_i, shift_i }) => {
    const { globalState, setGlobalState } = useWorkey()
    const [shift, setShift] = useState(weekStructure.days[day_i].shifts[shift_i])
    const removeShift = () => {
        let days = [...weekStructure.days]
        days[day_i].shifts.splice(shift_i, 1)
        let state = { ...globalState }
        state.weekStructure.days = days
        setGlobalState(state)
    }

    const branch_roles = globalState.user.branch?.roles?.sort((a, b) => a.order - b.order)
    const slot_role = (role_i) => {
        return globalState.user.branch.roles[role_i]
    }

    const setSlotsWithRoleForSingelShift = (role_i, numSlots) => {
        let new_state = { ...globalState }
        new_state.weekStructure.days[day_i].shifts[shift_i].slots = new_state.weekStructure?.days[day_i]?.shifts[shift_i]?.slots.length > 0 ? new_state.weekStructure.days[day_i].shifts[shift_i].slots.filter((slot) => slot.role_id != slot_role(role_i).id) : [];
        for (let index = 0; index < numSlots; index++) {
            new_state.weekStructure.days[day_i].shifts[shift_i].slots.push({ shift_id: new_state.weekStructure.days[day_i].shifts[shift_i].id, role_id: slot_role(role_i).id });
        }
        setGlobalState(new_state)
    }

    useEffect(() => {
        setShift(weekStructure.days[day_i].shifts[shift_i])
    }, [weekStructure.days[day_i].shifts[shift_i]])
    return (
        <div className='shift-structure'>
            <div className='card p-2'>
                <div className='shift-structure-header'>
                    <div><Trans keys={"shift"} /> {shift_i + 1}</div>
                    <i className='fa-btn' onClick={removeShift}><Icon path={mdiDeleteEmpty} size={IconSize} /></i>
                </div>
                <div className='add-slots-inputs'>
                    {branch_roles?.map((role, role_i) => {
                        return (
                            <div className='d-flex flex-row justify-content-center text-center' key={` shift_role_${role_i}`}>
                                <label className='col text-center' htmlFor={`shift_role_${role_i}`}>
                                    {slot_role(role_i).name}
                                </label>
                                <div className='col'>
                                    <input type='number'
                                        className='form-control text-center'
                                        onChange={(e) => { setSlotsWithRoleForSingelShift(role_i, e.target.value) }}
                                        value={shift?.slots.length > 0 ? shift.slots.filter((slot) => slot.role_id === slot_role(role_i).id && slot.role_id !== null)?.length : 0}
                                        id={`shift_role_${role_i}`} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ShiftStructure