import React, { useState } from 'react'
import { makeApiRequest, userDisplayName } from '../../../utils/utils'
import { useWorkey } from '../../../WorkeyContext'
import { Trans } from '../../../lang/Trans'
import User from '../../../utils/user_utils'
import moment from 'moment'
import { toast } from 'react-hot-toast';

const ReplacementDisplay = ({replacements, setReplacements, replacementData, setReplaceData }) => {
  const { globalState, setGlobalState } = useWorkey()
  const userUtils = new User(globalState)
  const [showReplacement, setShowReplacement] = useState(false)
  const employee = () => {
    let currentUser = globalState.user.id == replacementData.user_id
    let user
    if (currentUser) {
      user = globalState.user
    } else {
      user = globalState.user.branch.employees.find(emp => emp.id === replacementData.user_id)
    }
    return user
  }

  const rejectReplacement = async (status = 'rejected') => {
    const req = await makeApiRequest(globalState, setGlobalState, '/replacements/' + replacementData.id, 'PUT', { status: status })
    const res = req[1]
    const data = req[0]
    if (res.status === 201) {
      if(globalState.user.replacements_requests) {
        let new_replacement = [...globalState.user.replacements_requests]
        let index = new_replacement.findIndex(rep => rep.id === data.id)
        new_replacement[index] = data
        setReplacements(new_replacement)
        setGlobalState({ ...globalState, user: { ...globalState.user, replacements_requests: new_replacement } })
      } else {
        if(setReplaceData) {
          setReplaceData(data)
        }
      }
    } else {
      toast.error(data.error)
    }
  }

  const replacementOptionsDisplay = () => {
    const options_ids = replacementData.options.split(',')
    if (replacementData.options.length < 1 || options_ids.length < 1) {
      return <div className='replacement-option'><Trans keys={'replacements_page.no_options'} /></div>
    }

    return options_ids.map(option => {
      let employee = globalState.user.branch.employees.find(emp => emp.id == option)
      return (
        <div className='btn btn-sm btn-app-light-blue replacement-option'
          onClick={() => selectReplacement(employee.id)}>{userDisplayName(employee)}</div>
      )
    })
  }

  const selectReplacement = (selected_id) => {

    toast(<div className='d-flex flex-column'>
      <div className='d-flex flex-row'><Trans keys={'replacements_page.approval_confirmation_msg'} /></div>
      <div className='d-flex flex-row justify-content-center'>
        <div className='btn btn-sm btn-app-dark-blue mt-2' onClick={() => confirmReplacement(selected_id)}><Trans keys={'replacements_page.approve'} /></div>
      </div>
    </div>, { duration: 1000 * 10 })
  }

  const confirmReplacement = async (selected_id) => {
    toast.dismiss()
    const req = await makeApiRequest(globalState, setGlobalState, '/replacements/' + replacementData.id, 'PUT', { status: 1, selected_user: selected_id })
    const res = req[1]
    const data = req[0]
    if(res.status === 201) {
      let new_replacement = [...replacements]
      let index = new_replacement.findIndex(rep => rep.id === data.id)
      new_replacement[index] = data
      setReplacements(new_replacement)
      setShowReplacement(false)
      if(setReplaceData) {setReplaceData(data)}
    } else {
     
      toast.error(data.error)
    }
  }

  const offerSelf = async() => {
    
  }

  return (
    <div className='replacement-display-container'>
      <div className='display card p-2'>
        <div className='replacement-data text-dark'>
          <div className='data-display d-flex flex-row'>
            <div className='mx-2'><Trans keys={'replacements_page.requester'} />:</div>
            <div className='fw-bold'>{userDisplayName(employee())}</div>
          </div>
          <div className='data-display d-flex flex-row'>
            <div className='mx-2'><Trans keys={'replacements_page.shift_date'} />:</div>
            <div className='fw-bold'>{moment(replacementData.date).format('DD.MM.YYYY')}</div>
          </div>
          <div className='data-display d-flex flex-row'>
            <div className='mx-2'><Trans keys={'replacements_page.shift_title'} />:</div>
            <div className='fw-bold'><Trans keys={"shift"} /> {replacementData.shift_index + 1}</div>
          </div>
          <div className='data-display d-flex flex-row'>
            <div className='mx-2'><Trans keys={'replacements_page.status'} />:</div>
            <div className={`fw-bold status_${replacementData.status}`}><Trans keys={`replacements_page.${replacementData.status}`} /></div>
          </div>
        </div>
        {showReplacement && <div className='replacement-options'>
          {replacementOptionsDisplay()}
        </div>}
        <div className='replacement-actions'>
          {replacementData.status === 'pending' && <>
            {userUtils.allowedTo('manage_schedule') && <>
              <div className='btn btn-sm btn-app-red' onClick={() => rejectReplacement()}><Trans keys={'replacements_page.reject'} /></div>
              <div className='btn btn-sm btn-app-green' onClick={() => setShowReplacement(true)}><Trans keys={'replacements_page.approve'} /></div>
            </>}
            {globalState.user.id === replacementData.user_id && <>
              <div className='btn btn-sm btn-app-light-blue' onClick={() => rejectReplacement('canceled')}><Trans keys={'replacements_page.cancel'} /></div>
            </>}
            {globalState.user.id !== replacementData.user_id && <>
              <div className='btn btn-sm btn-app-blue' onClick={() => offerSelf()}><Trans keys={'replacements_page.offer_replacement'} /></div>
            </>}
          </>}
        </div>
      </div>
    </div>
  )
}

export default ReplacementDisplay