import { Icon } from '@mdi/react';
import { findEmployee } from '../../../../utils/scheduling_utils'
import { makeApiRequest, userDisplayName } from '../../../../utils/utils'
import './Slot.css'

import React, { useEffect, useState } from 'react'
import { useWorkey } from '../../../../WorkeyContext'
import State from '../../../../utils/current_week_utils'
import { mdiAccountSwitchOutline, mdiCheck, mdiDelete, mdiPlusCircle, mdiSelectionRemove, mdiTrashCan } from '@mdi/js';
import { rebuildWeek } from '../../../../utils/week_utils';
import { toast } from 'react-hot-toast';
import { Trans } from '../../../../lang/Trans';
import ReplacementDisplay from '../../replacements/ReplacementDisplay';
import User from '../../../../utils/user_utils';
import _ from 'lodash';


import sbIcon from '../../../../assets/images/cursors/sbIcon.svg';
import infoIcon from '../../../../assets/images/cursors/infoIcon.svg';
import starIcon from '../../../../assets/images/cursors/starIcon.svg';
import traingingIcon from '../../../../assets/images/cursors/traingingIcon.svg';

const Slot = (props) => {
    const { mark, tools, addSlotToShift, currentWeek, setCurrentWeek, day_id, shift_id, slot_num, slot_id, selectedSlotId, setSelectedSlotId, edit, toggleTimeModal, employeeWatch } = props
    const { globalState, setGlobalState } = useWorkey()
    const [day, setday] = useState(currentWeek?.days?.find(d => d.id === day_id))
    const [shift, setshift] = useState(day?.shifts.find(s => s.id == shift_id))
    const [slot, setSlot] = useState(shift?.slots?.find(s => s.id == slot_id))
    const [fill] = useState(!slot?.id)
    const [editTime, setEditTime] = useState({ start: false, end: false })
    const [slotComment, setSlotComment] = useState("")

    const userUtils = new User(globalState)
    const modal = true
    const slotTimeDisplay = () => {
        return {
            start: {
                time: slot?.start || slot?.expected_start || "00:00",
                type: slot?.start ? "actual" : "expected"
            },
            end: {
                time: slot?.end || slot?.expected_end || "00:00",
                type: slot?.end ? "actual" : "expected"
            }
        }
    }

    const timesInputs = () => {
        if (modal) {
            return (
                <>
                    <span className={slotTimeDisplay().end.type}>{slotTimeDisplay().end.time}</span>
                    <div className='separator' />
                    <span className={slotTimeDisplay().start.type}>{slotTimeDisplay().start.time}</span>
                </>
            )
        } else {
            if (editTime.start || editTime.end) {
                return (
                    <>
                        <input className='form-control input-md' type={"time"} />
                        <div className='separator' />
                        <input className='form-control input-md' type={"time"} />
                        {(editTime.start || editTime.end) && <div className='save-btn fa-solid fa-floppy-disk'></div>}
                    </>
                )
            } else {
                return (
                    <>
                        <span onClick={() => setEditTime({ ...editTime, end: true })} className={slotTimeDisplay().end.type}>{slotTimeDisplay().end.time}</span>
                        <div className='separator' />
                        <span onClick={() => setEditTime({ ...editTime, start: true })} className={slotTimeDisplay().start.type}>{slotTimeDisplay().start.time}</span>
                    </>
                )
            }
        }

    }

    const removeSlot = async () => {
        if (!window.confirm('are you sure? this action can\'t be undone!')) {
            return;
        }
        const req = await makeApiRequest(globalState, setGlobalState, `/slots/${slot_id}`, 'DELETE');
        const [data, res] = await req;
        if (res.status === 200) {
            let new_current_week = { ...currentWeek };
            new_current_week.slots = new_current_week.slots?.filter(s => s.id !== slot_id);
            new_current_week = await rebuildWeek(new_current_week, globalState);
            setCurrentWeek(new_current_week);
            toast.success(<Trans keys={'successfully_removed'} />, {
                duration: 3000,
                icon: mdiCheck

            })
        }
    }

    const utilsSlotClass = () => {
        let class_text = `slot card mt-1`
        if (fill) {
            class_text += ' fill'
        } else {
            if (slot.user_id == globalState.user.id) {
                class_text += ' user-slot'
            }
            if (selectedSlotId == slot?.id) {
                class_text += ' fw-bolder'
            }
            if (edit) {
                const utilsState = new State(globalState)
                class_text += ' '
                class_text += tools?.slot_status_off ? '' : utilsState.slotStatus(currentWeek, slot?.id, selectedSlotId, employeeWatch?.id).className
            } else {
                if (employeeWatch?.id && slot.user_id === employeeWatch?.id) {
                    class_text += ' app-green-bg text-light'
                }
            }
        }
        return `${class_text}${mark ? ` ${mark}` : ''}`

    }

    const onNameClick = () => {
        if (employeeWatch) {
            menualAssginUserToSlot()
        } else {
            setSelectedSlotId(selectedSlotId == slot?.id ? false : slot?.id)
        }
    }

    const editComment = async (deleteComment = false) => {
        let new_comment = deleteComment ? null : slotComment
        let current_week = _.clone(globalState.currentWeek);
        let slotIndex = current_week.slots.findIndex(s => s?.id === slot.id);
        current_week.slots[slotIndex] = {
            ...current_week.slots[slotIndex],
            data: {
                ...current_week.slots[slotIndex].data,
                mark: new_comment && new_comment.length > 0 ? "info" : null,
                comment: new_comment
            }
        }
        console.log('data', current_week.slots[slotIndex].data)

        current_week = await rebuildWeek(current_week, globalState);
        let new_state = { ...globalState };
        new_state.currentWeek = current_week;
        setGlobalState(new_state);

    }

    const onSlotClick = async () => {
        console.log('mark', mark)
        if (mark) {
            if (mark === 'info') {
                if (slot.data?.['comment']) {
                    return toast.error((t) => <div>
                        <div>קיימת הערה לשיבוץ זה, האם למחוק?</div>
                        <div className='d-flex flex-row justify-content-center'>
                            <div className='btn btn-sm mx-3 btn-app-red' onClick={() => { editComment(true); toast.dismiss(t.id) }}>מחק</div>
                            <div className='btn btn-sm mx-3 btn-app-blue' onClick={() => toast.dismiss(t.id)}>ביטול</div>
                        </div>
                    </div>)
                } else {
                    return toast((t, slotComment) => {
                        return <div>
                            <div>
                                <div>הערה</div>
                                <div><input type='text' value={slotComment} onChange={(e) => setSlotComment(e.target.value)} className='form-control input-md' /></div>
                            </div>
                            <div>
                                <div className='btn btn-s, mx-3 btn-app-green' onClick={() => { editComment(); toast.dismiss(t.id) }}>שמור</div>
                                <div className='btn btn-s, mx-3 btn-app-red' onClick={() => toast.dismiss(t.id)}>ביטול</div>
                            </div>
                        </div>
                    })
                }
            }
            let current_week = _.clone(globalState.currentWeek);
            let slotIndex = current_week.slots.findIndex(s => s?.id === slot.id);
            current_week.slots[slotIndex] = {
                ...current_week.slots[slotIndex],
                data: {
                    ...current_week.slots[slotIndex].data || [],
                    mark: slot?.data?.['mark'] == mark ? null : mark
                }
            }

            current_week = await rebuildWeek(current_week, globalState);
            let new_state = { ...globalState };
            new_state.currentWeek = current_week;
            setGlobalState(new_state);

        } else {
            setSelectedSlotId(selectedSlotId == slot.id ? null : slot?.id)
        }
    }

    const menualAssginUserToSlot = async (deleteUser = false) => {
        if ((!employeeWatch && !deleteUser) || !edit) return
        // If the object type is 'employee', update the relevant slot with the object ID
        let current_week = _.clone(globalState.currentWeek);
        let slotIndex = current_week.slots.findIndex(s => s?.id === slot.id);

        current_week.slots[slotIndex] = {
            ...current_week.slots[slotIndex],
            user_id: deleteUser ? null : employeeWatch?.id
        }

        current_week = await rebuildWeek(current_week, globalState);
        let new_state = { ...globalState };
        new_state.currentWeek = current_week;
        setGlobalState(new_state);
        setSelectedSlotId(false);
    };


    useEffect(() => {
        let new_slot = { ...currentWeek?.days?.find(day => day.id == day_id)?.shifts?.find(s => s.id == shift_id)?.slots?.find(s => s.id == slot_id) }
        new_slot.id = slot_id
        setSlot(new_slot)
        setday(currentWeek?.days?.find(d => d.id === day_id))
        setshift(day?.shifts.find(s => s.id == shift_id))
    }, [currentWeek?.days?.find(day => day.id == day_id)?.shifts?.find(s => s.id == shift_id)?.slots?.find(s => s.id == slot_id), currentWeek?.slots?.length])

    const replacementIcon = () => {
        if (currentWeek.replacements) {
            let replacement = currentWeek.replacements.find(r => r?.slot_id == slot?.id)
            if (replacement) {
                if (showReplacementIcon(replacement)) {
                    let color
                    switch (replacement.status) {
                        case 'pending':
                            color = 'app-yellow-txt bg-light'
                            break;
                        case 'rejected':
                            color = 'app-red-txt bg-light'
                            break;
                        case 'approved':
                            color = 'app-green-txt bg-light'
                            break;

                        default:
                            break;
                    }
                    return (<Icon path={mdiAccountSwitchOutline} size={0.7} className={`${color} pointer`} />)
                }
            }
        }
        return null
    }

    const showComment = () => {
        toast(slot?.data?.comment)
    }

    const markIcon = () => {
        if (slot?.data?.['mark']) {
            // if (slot.data['mark'] === 'star') {
            //     return <img src={starIcon} size={'18px'} data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom" />
            // }
            if (slot.data['mark'] === 'sb') {
                return <img src={sbIcon} size={'18px'} />
            } else if (slot.data['mark'] === 'training') {
                return <img src={traingingIcon} size={'18px'} />
            } else if (slot.data['mark'] === 'info') {
                return <img src={infoIcon} size={'18px'} className='pointer' onClick={showComment} />
            }
        }
        return null
    }

    const showReplacementIcon = (replacement) => {

        if (replacement.user_id === globalState.user.id) {
            // always show the user his own replacement
            return true
        } else {
            // don't show old replacements for non requester users
            if (replacement.status != 'expired') {
                if (userUtils.allowedTo('manage_schedule')) {
                    return true
                } else {
                    // show non managers only pending
                    if (replacement.status === "pending") {
                        return true
                    }
                }

            }
        }

    }
    const setReplaceData = (replacement) => {
        let new_replacements = [...currentWeek.replacements]
        new_replacements.splice(new_replacements.findIndex(r => r?.slot_id == slot?.id), 1, replacement)
        setCurrentWeek({ ...currentWeek, replacements: new_replacements })
    }

    const popReplacement = () => {
        toast.custom((t) => <div className='slot-replacement-container card'>
            <ReplacementDisplay setReplaceData={setReplaceData} replacementData={currentWeek.replacements.find(r => r?.slot_id == slot?.id)} />
            <div className='d-flex flex-row justify-content-center'>
                <div className='btn btn-sm btn-app-dark-blue mt-2' onClick={() => toast.dismiss(t.id)}>
                    <Trans keys={'close'} />
                </div>
            </div>
        </div>, { duration: 1000 * 30, position: 'top-right' })
    }

    return (
        <>
            <div id={`slot_${slot?.id}`}
                className={utilsSlotClass(edit)}
                onClick={() => onSlotClick()}>
                <div className={`${edit && 'pointer'} slot-text ${!slot?.user_id && 'empty'}`} onClick={() => onNameClick()}>
                    <div className='text-center fw-bold'>{
                        (edit && fill) ? <Icon path={mdiPlusCircle} size={1} className='app-blue-txt pointer' onClick={addSlotToShift} />
                            : <div>
                                {userDisplayName(findEmployee(globalState, slot?.user_id))}
                            </div>}
                        <div className='replacement-btn' onClick={popReplacement}>{replacementIcon()}</div>
                        {edit && slot?.user_id && <div className='unassgin-btn deleter' onClick={() => menualAssginUserToSlot(true)}>
                            <Icon path={mdiTrashCan} size={0.9} className='app-red-txt' />
                        </div>}
                    </div>
                </div>
                <div className={`${edit && 'pointer'} slot-time`} onClick={() => { edit && modal && toggleTimeModal(slot?.id) }}>
                    {timesInputs()}
                </div>
                {!fill && tools?.remove_slots && <div className='remove-slot-btn' onClick={() => removeSlot()}>
                    <Icon path={mdiSelectionRemove} size={2} className='btn btn-sm btn-light app-red-text pointer' />
                </div>}
                {slot?.data?.['mark'] && <div className='mark'>
                    {markIcon()}
                </div>}
            </div >
        </>
    )
}

export default Slot