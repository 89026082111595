import { Trans } from '../lang/Trans'
import { mdiEye, mdiCalendarQuestion, mdiCog, mdiCalendarPlus, mdiCalendarMultiselect, mdiAccountDetails, mdiCogBox, mdiPencil, mdiAccountGroup, mdiAccountCircle, mdiFindReplace, mdiAccountConvert, mdiCurrencyIls, mdiAccountCog, mdiOfficeBuildingCog } from '@mdi/js'
import User from './user_utils'
const sidebarActionsList = () => {
    return (
        {
            "default": [],
            "schedule": [
                {
                    text: 'רשימת עובדים',
                    icon: mdiEye,
                },
            ],
        }
    )
}
const settingsNavs =
    [
        {
            text: <Trans keys={"sidebar.settings.branch"} />,
            icon: mdiOfficeBuildingCog,
            path: "/settings/branch",
            access_levels: ["branch_manager"]
        },
        {
            text: <Trans keys={"sidebar.settings.user"} />,
            icon: mdiAccountCog,
            path: "/settings/user",
        },
        {
            text: <Trans keys={"schedule_settings"} />,
            icon: mdiCogBox,
            path: "/settings/schedule",
            access_levels: ["manage_schedule"]
        },
    ]

export const sidebarMenuList = () => {
    return ({
        "default": [],
        "schedule": [
            {
                text: <Trans keys={"schedule"} />,
                icon: mdiEye,
                path: "/schedule",
                access_levels: ["view_schedule", "manage_schedule"]
            },
            
            {
                text: <Trans keys={"build_schedule"} />,
                icon: mdiPencil,
                path: "/schedule/edit",
                access_levels: ["manage_schedule"]
            },
            {
                text: <Trans keys={"schedule_structure"} />,
                icon: mdiCalendarPlus,
                path: "/schedule/structure/create",
                access_levels: ["manage_schedule"]
            },
            {
                text: <Trans keys={"sidebar.schedule.replacements"} />,
                icon: mdiAccountConvert,
                path: "/schedule/replacements",
                access_levels: ["manage_schedule"]
            },
            {
                text: <Trans keys={"requests"} />,
                icon: mdiCalendarMultiselect,
                path: "/schedule/requests",
                access_levels: ["view_schedule"]
            },
            // {
            //     text: <Trans keys={"schedule_settings"} />,
            //     icon: mdiCogBox,
            //     path: "/schedule/settings",
            //     access_levels: ["manage_schedule"]
            // }
        ],
        settings: settingsNavs,
        employees: [
            {
                text: <Trans keys={"employees_list"} />,
                icon: mdiAccountGroup,
                path: "/employees",
                access_levels: ["manage_schedule", "manage_employee"]
            },
            {
                text: <Trans keys={"roles_list"} />,
                icon: mdiAccountCircle,
                path: "/employees/roles",
                access_levels: ["manage_schedule"]
            },
            {
                text: <Trans keys={"wages.title"} />,
                icon: mdiCurrencyIls,
                path: "/employees/wages",
                access_levels: ["manage_schedule", "manage_employee"]
            }
        ]

    })
}
export const sidebarLocation = (globalState) => {
    let location = window.location.pathname.replace("/", "").split("/")[0];
    if (location === 'user') {
        location = 'settings';
    }
    let sidebar_menu_list = sidebarMenuList()[location] 

    sidebar_menu_list = sidebar_menu_list?.filter(item => !item?.access_levels || item?.access_levels?.some(level => new User(globalState).allowedTo(level)));
    sidebar_menu_list ||= sidebarMenuList()["default"];

    return sidebar_menu_list;
}

export const sidebarActionsLocation = (globalState) => () => {
    const location = window.location.pathname.replace("/", "").split("/")[0]

    const actionsList = sidebarActionsList()
    if (actionsList[location]) {
        const authorizedActions = globalState?.user?.data?.authorized_actions
        if (actionsList[location].access_levels) {
            if (actionsList[location].access_levels.some(action => authorizedActions[action])) {
                return actionsList[location]
            } else {
                return null
            }
        }
        return actionsList[location]
    }
    return actionsList["default"]
}

export const appDomain = process.env.NODE_ENV === "development" ? 'http://localhost:3001' : 'https://workey-fbd56.web.app'
export const apiDomain = process.env.NODE_ENV === "development" ? 'http://localhost:3000' : 'https://workeyapi.fly.dev'
