import React, { useState, useEffect } from 'react';
import './ProfileWagesCard.css';
import { useWorkey } from '../../../../WorkeyContext';
import { toast } from 'react-hot-toast';
import { makeApiRequest } from '../../../../utils/utils';
import { mdiCancel, mdiCheckBold, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Trans } from '../../../../lang/Trans';
import { MINIMUM_WAGE } from '../../../../utils/data_utils';
import Select from 'react-select';

const ProfileWagesCard = ({ user }) => {
  const { globalState, setGlobalState } = useWorkey();
  const [editMode, setEditMode] = useState(false);
  const [wageValues, setWageValues] = useState({});
  const [originalWageValues, setOriginalWageValues] = useState({});
  const wageTypeOptions = (role_id) => {return [
    { label: 'שכר שעתי', value: 'salery', selected: wageValues?.[role_id]?.['wage_type'] === 'salery' },
    { label: 'טיפים עם השלמה לשכר מינימום', value: 'tips', selected: wageValues?.[role_id]?.['wage_type'] === 'tips'  },
    { label: 'טיפים ללא השלמה', value: 'tips_only', selected: wageValues?.[role_id]?.['wage_type'] === 'tips_only'  },
    { label: 'שכר שעתי וטיפים ללא השלמה', value: 'both', selected: wageValues?.[role_id]?.['wage_type'] === 'both' },
  ]}
  useEffect(() => {
    const initialWageValues = {};
    [user.main_role, ...user.roles || []].forEach((role) => {
      const position = globalState.user.branch.positions.find(
        (p) => p.role_id === role?.id && p.user_id === user?.id
      );
      initialWageValues[role?.id] = {
        minimum_wage: position?.minimum_wage || role?.default_minimum_wage || globalState.user.branch.settings?.global_minimum_wage || MINIMUM_WAGE,
        wage_percent: position?.wage_percent || role?.default_wage_percent || 100,
        wage_type: position?.wage_type || role?.default_wage_type
      };
    });
    setWageValues(initialWageValues);
    setOriginalWageValues(initialWageValues);
  }, [user, globalState]);

  const onSave = async (roleId) => {
    const values = wageValues[roleId];
    const updatedPositions = globalState.user.branch.positions.filter(pos => pos.role_id === roleId && pos.user_id === user.id).map((pos) => {
      return {
        ...pos,
        minimum_wage: values['minimum_wage'],
        wage_percent: values['wage_percent'],
        wage_type: values['wage_type'],
      };
    });
    const req = await makeApiRequest(globalState, setGlobalState, `user/${user.id}/positions`, 'POST', { positions: updatedPositions || [] });
    const res = req[1];
    const data = res[0];
    if (res.status == 200) {
      toast.success(<Trans keys={'toast.record_saved'} />);
      // return
      setGlobalState({
        ...globalState,
        user: {
          ...globalState.user,
          branch: {
            ...globalState.user.branch,
            positions: updatedPositions,
          },
        },
      });
      setEditMode(false);
    } else {
      toast.error(<Trans keys={'toast.server_error'} />);
    }
  };

  const onCancel = (roleId) => {
    setWageValues((prevValues) => ({
      ...prevValues,
      [roleId]: originalWageValues[roleId],
    }));
    setEditMode(false);
  };

  const handleInputChange = (roleId, e, type) => {
    const { value } = type === 'wage_type' ? e : e.target;
    setWageValues((prevValues) => ({
      ...prevValues,
      [roleId]: { ...prevValues[roleId], [type]: value },
    }));
  };

  return (
    <div className='profile-wages-card'>
      <div className='text-center'>
        <Trans keys={'profile_page.wages_card.title'} />
      </div>
      <div className='card-body text-center'>
        {globalState.user.branch.positions.filter((position, index) => position.user_id == user?.id).length > 0 ? (
          <div className='role-wages-container mt-3'>
            {[user.main_role, ...user.roles || []].map((role) => {
              // const position = globalState.user.branch.positions.find(
              //   (p) => p.role_id === role?.id && p.user_id === user?.id
              // );
              // const defaultValue =
              //   wageValues[role?.id] ||
              //   position?.minimum_wage ||
              //   role?.default_minimum_wage ||
              //   globalState.user.branch.settings?.global_minimum_wage;

              return (
                <div className='d-flex flex-row role-wages card'>
                  <div className='role-name w-100 bg-secondary mb-2 p-1 fw-bold text-light'>{role?.name}</div>
                  <div>
                    <div className='role-wages-item' key={`minimum_${role?.id}`}>
                      <div className='d-flex flex-row'>
                        {editMode == `minimum_${role?.id}` ? (
                          <>
                            <div className='label'><Trans keys={'minimum_wage'} /></div>
                            <input
                              type='number'
                              className='form-control'
                              value={wageValues[role?.id]?.['minimum_wage'] || null}
                              onChange={(e) => handleInputChange(role?.id, e, 'minimum_wage')}
                            />
                            <Icon path={mdiCheckBold} size={1} className='app-green-txt mx-2 pointer' onClick={() => onSave(role?.id)} />
                            <Icon path={mdiCancel} size={1} className='app-red-txt mx-2 pointer' onClick={() => onCancel(role?.id)} />
                          </>
                        ) : (
                          <>
                            <div className='label'><Trans keys={'minimum_wage'} /></div>
                            <input
                              type='number'
                              className='form-control'
                              value={wageValues[role?.id]?.['minimum_wage'] || null}
                              disabled
                            />
                            <Icon path={mdiPencil} size={1} className='mx-2 app-blue-txt pointer' onClick={() => setEditMode(`minimum_${role?.id}`)} />
                          </>
                        )}
                      </div>
                      <div className=''>{!wageValues[role?.id] && <Trans keys={'not_set'} />}</div>
                    </div>
                    
                    <div className='role-wages-item' key={`type_${role?.id}`}>
                      <div className='d-flex flex-row'>
                        {editMode == `type_${role?.id}` ? (
                          <>
                            <div className='label'><Trans keys={'wage_type'} /></div>
                            <Select className='select'
                              // className='form-control'
                              value={wageTypeOptions(role.id).find((option) => option.value == wageValues[role?.id]?.['wage_type']) || null}
                              // value={wageTypeOptions.find((option) => option.value == defaultWageType)}
                              options={wageTypeOptions(role.id)}
                              onChange={(opt) => handleInputChange(role?.id, opt, 'wage_type')}
                            // onChange={(e) => setDefaultWageType(e.value)}
                            />
                            <Icon path={mdiCheckBold} size={1} className='app-green-txt mx-2 pointer' onClick={() => onSave(role?.id)} />
                            <Icon path={mdiCancel} size={1} className='app-red-txt mx-2 pointer' onClick={() => onCancel(role?.id)} />
                          </>
                        ) : (
                          <>
                            <div className='label'><Trans keys={'wage_type'} /></div>
                            <Select
                              className='select'
                              value={wageTypeOptions(role.id).find((option) => option.value == wageValues[role?.id]?.['wage_type']) || null}
                              // value={wageTypeOptions.find((option) => option.value == defaultWageType)}
                              options={wageTypeOptions(role.id)}
                              onChange={(opt) => handleInputChange(role?.id, opt, 'wage_type')}
                            // onChange={(e) => setDefaultWageType(e.value)}
                            isDisabled={true}
                            />
                            <Icon path={mdiPencil} size={1} className='mx-2 app-blue-txt pointer' onClick={() => setEditMode(`type_${role?.id}`)} />
                          </>
                        )}
                      </div>
                      <div className=''>{!wageValues[role?.id] && <Trans keys={'not_set'} />}</div>
                    </div>
                    {wageValues[role?.id]?.['wage_type'] !== 'salery' && <div className='role-wages-item' key={`percent_${role?.id}`}>
                      <div className='d-flex flex-row'>
                        {editMode == `percent_${role?.id}` ? (
                          <>
                            <div className='label'><Trans keys={'wage_percent'} /></div>
                            <input
                              type='number'
                              className='form-control'
                              value={wageValues[role?.id]?.['wage_percent'] || null}
                              onChange={(e) => handleInputChange(role?.id, e, 'wage_percent')}
                            />
                            <Icon path={mdiCheckBold} size={1} className='app-green-txt mx-2 pointer' onClick={() => onSave(role?.id)} />
                            <Icon path={mdiCancel} size={1} className='app-red-txt mx-2 pointer' onClick={() => onCancel(role?.id)} />
                          </>
                        ) : (
                          <>
                            <div className='label'><Trans keys={'wage_percent'} /></div>
                            <input
                              type='number'
                              className='form-control'
                              value={wageValues[role?.id]?.['wage_percent'] || null}
                              disabled
                            />
                            <Icon path={mdiPencil} size={1} className='mx-2 app-blue-txt pointer' onClick={() => setEditMode(`percent_${role?.id}`)} />
                          </>
                        )}
                      </div>
                      <div className=''>{!wageValues[role?.id] && <Trans keys={'not_set'} />}</div>
                    </div>}

                  </div>
                  {/* <div className='w-100 wage_type_input'>
                    blab{JSON.stringify(wageValues[role?.id])}
                  </div>
 */}
                </div>
              );
            })}
          </div>
        ) :
          (<div className='label'><Trans keys={"no_roles"} /></div>)
        }
      </div>
    </div>
  );

}

export default ProfileWagesCard;