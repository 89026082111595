import React, { useEffect, useState } from 'react'
import DynamicForm from '../../dynamic-form/DynamicForm'
import { Trans } from '../../../lang/Trans';
import { useWorkey } from '../../../WorkeyContext';
import User from '../../../utils/user_utils';
import { useLocation } from 'react-router-dom';

function UserInfoCard ({ newEmployeeMode, currentUser}) {
  const { globalState } = useWorkey();
  const [newEmployee, setNewEmployee] = useState(true);
  const [edit, setEdit] = useState(false);
  const userAllowedToEdit = new User(globalState).isManagerOf(currentUser) || currentUser?.id == globalState?.user?.id
  const handleEdit = (mode) => {
    // if its the users page / the user manager
    if (userAllowedToEdit) {
      setEdit(mode);
    }
  };

  const handleSave = (values) => {
    setEdit(false);
  };

  const infoFields = {
    username: { id: 'username', type: 'text', value: currentUser?.username || '', label: 'username' },
    // can be empty, can contain chars and numbers, not symoles, up to 20 chars
    first_name: { id: 'first_name', type: 'text', value: currentUser?.first_name || '', label: 'first_name' },
    // can not be empty, must contain at least 3 chars, no numbers, no symoles, up to 20 chars
    last_name: { id: 'last_name', type: 'text', value: currentUser?.last_name || '', label: 'last_name' },
    // can empty, no numbers, no symoles, up to 20 chars
    email: { id: 'email', type: 'email', value: currentUser?.email || '' },
    // can not be empty, must be a valid email
    gender: {
      id: 'gender',
      type: 'select',
      value: <Trans keys={'gender.unknown'} />,
      label: 'gender.title',
      options: [<Trans keys={'gender.unknown'} />, <Trans keys={'gender.female'} />, <Trans keys={'gender.male'} />],
    },
    // can be unknown
    mobile: { id: 'mobile', type: 'email', value: currentUser?.mobile || '', label: 'mobile' },
    // can be empty, must be an israeli mobile number
    birth_date: { id: 'birth_date', type: 'date', value: currentUser?.birth_date || '', label: 'birth_date' },
    // can be empty, must be date, can't be older then 99 and can't be younger then 16
  };


  const handleValidation = (values) => {
    const errors = {};

    if (values.username && !/^[a-zA-Z0-9א-ת]{1,20}$/.test(values.username)) {
      errors.username = 'Username can contain letters and numbers only, up to 20 characters';
    }

    if (!values.first_name) {
      errors.first_name = 'First name is required';
    } else if (!/^[a-zA-Zא-ת]+$/.test(values.first_name)) {
      errors.first_name = "First name can't contain numbers";
      if (values.first_name.length > 20) {
        errors.first_name += ", and can't be longer than 20 characters";
      }
    } else if (values.first_name.length > 20) {
      errors.first_name = "First name can't be longer than 20 characters";
    }

    if (values.last_name && !/^[a-zA-Zא-ת]{0,20}$/.test(values.last_name)) {
      errors.last_name = 'Last name can contain letters only, up to 20 characters';
    }

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email must be a valid email address';
    }

    if (values.mobile && !/^(?:\+972|0)[1-9]\d{6,8}$/.test(values.mobile)) {
      errors.mobile = 'Mobile must be a valid Israeli mobile number';
    }

    return Object.keys(errors).length > 0 ? errors : null;
  }


  return (
    <div className={`${newEmployee ? 'new' : ''} user-profile`}>
      <div className="card my-3 p-3 info">
        <DynamicForm
          fields={infoFields}
          validation={handleValidation}
          onSave={handleSave}
          onEdit={handleEdit} // pass the callback function to the onEdit prop
          userAllowedToEdit={userAllowedToEdit}
          edit={edit}
        />
      </div>
    </div>
  );
}

export default UserInfoCard;