import HE from './he';
import EN from './en';
import { useWorkey } from '../WorkeyContext';

const langs = {
  "he": HE,
  "en": EN,
}

export const Trans = ({keys = '', mode = 'capitalize'}) => {
  const { globalState } = useWorkey();
  const lang = langs[globalState.local];
  if (!lang || !keys || typeof keys != "string") {
    const missingLang = `missing_lang: ${globalState.local}`;
    if(!lang) {
      console.error(`!lang`, globalState.local, langs);

    }
    if(!keys) {
      console.error(`keys`, keys, `typeof keys`, typeof keys);
    }
    return keys;
  }
  keys = keys.trim().toLowerCase();
  const keyArray = keys?.split(".") || [];
  let translation = lang;
  for (let i = 0, len = keyArray.length; i < len; i++) {
    translation = translation[keyArray[i]];
    if (!translation) break;
  }

  if (!translation) {
    translation = `${(lang.missing_translation || EN.missing_translation)}: ${keys}`;
    // console.error(translation)
  }

  if (typeof translation === "object") {
    translation = JSON.stringify(translation);
  }

  if (mode) {
    switch (mode) {
      case "uppercase":
        translation = translation.toUpperCase();
        break;
      case "lowercase":
        translation = translation.toLowerCase();
        break;
      case "capitalize":
        translation = translation.charAt(0).toUpperCase() + translation.slice(1);
        break;
    }
  }

  return translation;
};
