export const steps = {
  start: [{
    selector: '.first-step',
    content: 'This is my first Step',
  },
  {
    selector: '#settings-nav-item',
    content: 'settings-nav-item',
  },
  {
    selector: '.first-step',
    content: 'This is my first Step',
  },
  {
    selector: '.first-step',
    content: 'This is my first Step',
  },
  {
    selector: '.first-step',
    content: 'This is my first Step',
  },
    // ...
  ],
}