import { render } from "react-dom";
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { useWorkey } from "../../../WorkeyContext";
import { useEffect, useState } from "react";

const getRoles = (globalState) => {
  let roles_rows_data = [];
  globalState.user.branch.roles.forEach(role => {
    roles_rows_data.push({
      name: role.name, surname: role.order || '',
      default_minimum_wage: role.default_minimum_wage || '',
      default_wage_percent: role.default_wage_percent || '',
      default_wage_type: role.default_wage_type || '',
      order: role.order || ''
    });
  })
  roles_rows_data.push({ name: "", surname: "" })
  return roles_rows_data;
};

const getColumns = () => {
  return [
    { columnId: "default_wage_type", width: 150 },
    { columnId: "default_wage_percent", width: 150 },
    { columnId: "default_minimum_wage", width: 150 },
    { columnId: "order", width: 150 },
    { columnId: "name", width: 150 },
  ]
};

const headerRow = {
  rowId: "header",
  cells: [
    { type: "header", text: "סוג שכר" },
    { type: "header", text: "אחוז שכר מהטיפים" },
    { type: "header", text: "שכר מינימום" },
    { type: "header", text: "סדר תצוגה" },
    { type: "header", text: "שם תפקיד" },
  ]
};

const getRows = (roles) => [
  headerRow,
  ...roles.map((role, idx) => ({
    rowId: idx,
    cells: [
      { type: "text", text: role.default_wage_type || '' },
      { type: "number", value: role.default_wage_percent || '' },
      { type: "number", value: role.default_minimum_wage || '' },
      { type: "number", value: role.order || 0 },
      { type: "text", text: role.name },
    ]
  }))
];

const handleChanges = (changes) => {
  console.log(changes);
}

function RolesForm() {
  const { globalState, setGlobalState } = useWorkey();
  const [roles, setRoles] = useState(getRoles(globalState));

  useEffect(() => {
    setRoles(getRoles(globalState));
  }, [globalState.user.branch.roles?.length])

  const rows = getRows(roles);
  const columns = getColumns();

  return <div className="employees-grid-list-container">
    <div className='text-center fw-bold mb-1'>תפקידים</div>
    <div className="employees-grid-list">
      <ReactGrid
        enableRangeSelection
        onCellsChanged={handleChanges}

        rows={rows} columns={columns} />
    </div>
  </div>
}
export default RolesForm;

