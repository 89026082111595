import React, { useState } from 'react'
import { Icon } from '@mdi/react';
import { mdiPlusCircle, mdiToolboxOutline } from '@mdi/js';
import EmployeeItem from './EmployeeItem'
import { useWorkey } from '../../../../WorkeyContext'
import { Link, useNavigate } from 'react-router-dom'
import { mdiMinusCircle } from '@mdi/js';
import { IconSize, userDisplayName } from '../../../../utils/utils';
import '../EmployeesSettingsLists.css'
import { Trans } from '../../../../lang/Trans';
import MainRoleManage from '../../../user-page/profile/main-role-manage/MainRoleManage';
const EmployeesList = () => {
    const { globalState } = useWorkey()
    const [employeesTools, setEmployeesTools] = useState(false)
    const [selectedTool, setSelectedTool] = useState(false)
    const [editMainRoleForUserId, setEditMainRoleForUserId] = useState(null)
    const navigate = useNavigate()

    return (
        <div className='employees-settings-lists-container'>
            {editMainRoleForUserId && <div className='change-role-modal-container'>
                <div className='card m-5 p-5 w-50'>
                    <div className='card-body'>
                        <MainRoleManage
                            user={globalState.user.branch?.employees.find(employee => employee.id == editMainRoleForUserId)}
                            setEditMainRoleForUserId={setEditMainRoleForUserId}
                            showEdit={true}
                            startWithEdit={true} />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <div className={'btn btn-app-blue mt-5'} onClick={() => setEditMainRoleForUserId(null)}><Trans keys={"cancel"} /></div>
                    </div>
                </div>
            </div>}
            <div className='employees-settings-list card'>
                <div className='card-header text-center'>
                    עובדים
                    <Icon path={mdiToolboxOutline} size={IconSize} className={`pointer toolsbox-btn ${employeesTools ? "lg-btn-selected" : ""}`} onClick={() => setEmployeesTools(!employeesTools)} />
                </div>
                {employeesTools ? <div className='actions'>
                    <Icon path={mdiMinusCircle} size={IconSize}
                        className={`mx-1 pointer text-danger ${selectedTool == 'remove' ? "lg-btn-selected" : ""}`}
                        onClick={() => setSelectedTool(selectedTool == 'remove' ? false : 'remove')} />
                    <Icon path={mdiPlusCircle} size={IconSize}
                        className={`mx-1 pointer text-success ${selectedTool == 'add' ? "lg-btn-selected" : ""}`}
                        onClick={() => setSelectedTool(selectedTool == 'add' ? false : 'add')} />
                </div> : <></>}
                <div className='card-body'>
                    {globalState.user?.branch?.employees?.length > 0 ? globalState.user.branch?.employees.sort((a, b) => userDisplayName(a).localeCompare(userDisplayName(b), "he"))
                    .map((employee) => {
                        return (
                            <div className='card p-2 m-1'>
                                <EmployeeItem
                                    editMainRoleForUserId={editMainRoleForUserId}
                                    setEditMainRoleForUserId={setEditMainRoleForUserId}
                                    key={`employees_${employee.id}`}
                                    selectedTool={selectedTool}
                                    employee={employee} />
                            </div>
                        )
                    }) : <></>}
                </div>
            </div>
            <div className='employees-settings-lists-actions-container'>
                <div className='btn btn-app-light-blue' onClick={() => navigate('/user/create')}>
                    <Trans keys={"employees_settings_lists.employees.create"} />
                </div>
                <div className='btn btn-app-blue' onClick={() => navigate('/user/invite')}>
                    <Trans keys={"employees_settings_lists.employees.invite"} />
                </div>

            </div>
        </div>
    )
}

export default EmployeesList