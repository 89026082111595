import React, { useState } from 'react'
import './ProfileSchedulingSettings.css'
import { Trans } from '../../../../lang/Trans'
import './ProfileSchedulingSettings.css'
import { useWorkey } from '../../../../WorkeyContext'
import { makeApiRequest } from '../../../../utils/utils'
import { toast } from 'react-hot-toast'
const ProfileSchedulingSettings = ({ user, onSaveCallback }) => {
    const { globalState, setGlobalState } = useWorkey()
    const [scheduling, setScheduling] = useState(user.data.settings.branch.scheduling)
    const [editMode, setEditMode] = useState(false)
    const onSave = async () => {
        let userDup = { ...user }
        userDup.data.settings.branch.scheduling = Object.entries(scheduling).reduce((acc, [key, value]) => {
            acc[key] = Number(value);
            return acc;
        }, {})
        const req = await makeApiRequest(globalState, setGlobalState, `users/${userDup.id}`, 'PUT', userDup)
        const res = req[1]
        const data = req[0]
        if (res.status === 201) {
            let new_user = { ...user, ...data.user }
            new_user.token = data.token
            onSaveCallback(new_user)
            setEditMode(false)
        } else {
          toast.error(<Trans keys={'toast.feild_to_update.user_scheduling'} />);
            
        }
    }

    const onCancel = () => {
        setScheduling(user.data.settings.branch.scheduling)
        setEditMode(false)
    }
    const handleSettingChange = (property) => (e) => {
        setScheduling({
            ...scheduling,
            [property]: property === 'allow_double_shifts' ? e.target.checked : e.target.value
        })
    }

    const renderSettingsFields = () => {
        const booleans = ['allow_double_shifts'];
        return Object.keys(scheduling).map((key) => {
          return (
            <div className="form-feild">
              <label htmlFor={key} className="form-label">
                <Trans keys={`scheduling_settings.${key}`} />
              </label>
              {key === 'allow_double_shifts' ? (
                <div className="">
                  <input
                    type="checkbox"
                    checked={scheduling[key]}
                    disabled={!editMode}
                    onChange={(e) => handleSettingChange(key)(e)}
                    className="form-check-input"
                    id={key}
                  />
                </div>
              ) : (
                <input
                  type="number"
                  value={scheduling[key]}
                  min={0}
                  disabled={!editMode}
                  onChange={(e) => handleSettingChange(key)(e)}
                  className="form-control"
                  id={key}
                />
              )}
            </div>
          );
        });
      };
      

    return (
        <div className='profile-scheduling-settings-container'>
            <div className={`form-title`}><Trans keys={'scheduling_settings.title'} /></div>
            <div className='form-btns'>
                {editMode && (<>
                    <div className='btn btn-sm btn-app-green mx-2' onClick={() => onSave()}>
                        <Trans keys={'save'} />
                    </div>
                    <div className='btn btn-sm btn-app-red mx-2' onClick={() => onCancel()}>
                        <Trans keys={'cancel'} />
                    </div>
                </>)}
                {!editMode && <div className='btn btn-sm btn-app-blue mx-2' onClick={() => setEditMode(true)}>
                    <Trans keys={'edit'} />
                </div>}
                <div className='form-feilds'>
                    {renderSettingsFields()}
                </div>
            </div>
        </div>
    )
}

export default ProfileSchedulingSettings