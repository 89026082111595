import { getBranchData } from "./data_utils";
import { apiDomain } from "./routing"
import User from "./user_utils";
import { rebuildWeek } from "./week_utils";
import secureLocalStorage from "react-secure-storage";
import { toast } from 'react-hot-toast';
import { Trans } from "../lang/Trans";


export const moment = require('moment'); // require    
export const _ = require('lodash');

export const defaultScores = { position: 90 }

export const makeApiRequest = async (globalState, setGlobalState, path, method, body = {}, headers = {}) => {
    let newFetchingData = { ...globalState.fetchingData, [path]: true }
    newFetchingData[path] = true
    await setGlobalState({ ...globalState, fetchingData: newFetchingData })
    const response = await apiReq(globalState, path, method, headers, body)
    let res
    await response.json().then(data => {
        res = data
        if (!response?.status) {
            toast.error(<Trans keys={'toast.response.no_status_code'} />);
        }
        delete globalState.fetchingData[path]
        setGlobalState(globalState)
    }).catch((err) => {
        console.error(err)
        res = err
        delete globalState.fetchingData[path]
        setGlobalState(globalState)
    });

    return [res, response]
}

export const apiReq = async (globalState, path, method = 'GET', headers = {}, body = {}) => {
    if (!path.startsWith("/")) {
        path = "/" + path
    }
    path = `${apiDomain}${path}`
    headers["Content-Type"] = "application/json"
    let token = globalState.user?.token || headers.token
    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    const reqData = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: "same-origin", // include, *same-origin, omit
        headers: headers,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
    if (method !== 'GET') {
        reqData['body'] = JSON.stringify(body) // body data type must match "Content-Type" header
    }
    const response = await fetch(path, reqData);
    // if (response.status > 299 || response.status < 200 || response.status != "ok"|| response.status != "created") {
    // return response
    // }
    if(response.error) {
        toast.error(response.error)
    }
    return response
}

export const storedUser = (token_only) => {
    const stored_user = secureLocalStorage.getItem(`${process.env.NODE_ENV}WorkeyUser`)
    const storedUserTokenStr = stored_user ? JSON.parse(stored_user).token : null
    if (token_only) {
        if (!storedUserTokenStr) {
            return false
        } else {
            return storedUserTokenStr
        }
    } else {
        if (!stored_user) {
            return false
        } else {
            return JSON.parse(stored_user)
        }
    }
}

export const getStoredUser = async (globalState, setGlobalState, fullData = true) => {
    let data = false
    if (fullData) {
        let stored_user = await storedUser()
        if (stored_user) {
            data = stored_user
        }
    } else {
        let stored_user_token = await storedUser(true)
        const req = await makeApiRequest(globalState, setGlobalState, "/auto_login", "GET", false, { token: stored_user_token })
        const res = req[1]
        const data = req[0]
        if (res.status === 200) {
            data['token'] = stored_user_token
        } else {
            toast.error(<Trans keys={'toast.server_error'} />)
        }
    }
    return data
}


export const setupRunner = async (globalState, setGlobalState, force = false) => {
    console.log("setupRunner")
    let new_app_state = { ...globalState }
    let stored_user = await getStoredUser(globalState, setGlobalState, true)
    if (!stored_user) {
        return
    }
    new_app_state['user'] = stored_user

    // new_app_state['user'] = stored_user_token
    if (new_app_state?.user.branch_id && (force?.branch || !new_app_state?.user?.branch)) {
        new_app_state['user']['branch'] = await getBranchData(new_app_state, setGlobalState, true)
        new_app_state.user['main_role'] = new_app_state['user']['branch'].employees.find((e) => e.id == new_app_state.user.id)?.main_role
        new_app_state.user['roles'] = new_app_state['user']['branch'].employees.find((e) => e.id == new_app_state.user.id)?.roles
        if (force?.week) {
            new_app_state['currentWeek'] = await getCurrentWeek(new_app_state, setGlobalState)
        }
    }
    setGlobalState(new_app_state)


}


export const isNewUser = (globalState) => {
    return globalState.user?.status !== new User(globalState).status_list['normal']
}

export const getCurrentWeek = async (globalState, setGlobalState, method = "GET", options = { type: "full", create_type: 'last_week' }) => {
    let week
    let weekRef = options?.weekRef || (globalState.weekRef || 0)
    let path = `/current_week/${options.week_ref || weekRef || 0}${method === "GET" ? options?.type ? `/${options.type}` : '/full' : ""}`
    let body = {create_type: options.create_type}
    const req = await makeApiRequest(globalState, setGlobalState, path, method, body)
    const data = req[0]
    const res = req[1]
    if (data && (res.status == 200 || res.status == 201)) {
        week = data
        if (week.week) {
            week = week.week
        }
        week = await rebuildWeek(week, globalState)
        return week
    } else {
        return toast.error(data.error || <Trans keys={'server_error'} />)
    }
}

export const logout = async () => {
    await secureLocalStorage.removeItem(`${process.env.NODE_ENV}WorkeyUser`)
    window.location.href = "/"
}

export const userDisplayName = (user, full_name = false) => {
    if (!user) { return '' }
    let display_name = user.username || 'משתמש'
    if (user.first_name && user.last_name) {
        if (full_name) {
            display_name = `${user.first_name} ${user.last_name}`
        } else {
            display_name = `${user.first_name} ${user?.last_name?.charAt(0)}${user?.last_name ? '' : ''}`
        }
    }
    return display_name.charAt(0).toUpperCase() + display_name.slice(1)
}

export const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}


const preventClose = () => {
    window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    });

}


export const IconSize = '20px';

// Check if screen size is less than 750px width
export const isSmallScreen = () => {
    return window.innerWidth < 750;
}


export const displayTime = (time) => {
    return `${Math.floor(time).toFixed(0).padStart(2, '0')}:${((time % 1) * 60).toFixed(0).padStart(2, '0')}`
}

