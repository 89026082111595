import React, { useState } from 'react'
import Avatar from 'react-avatar'
import { makeApiRequest, userDisplayName } from '../../../../utils/utils'
import User from '../../../../utils/user_utils'
import { useWorkey } from '../../../../WorkeyContext'
import { Trans } from '../../../../lang/Trans'
import './ProfileAvatarCard.css'
import ProfileNameDisplay from '../profile-name-display/ProfileNameDisplay'
import { toast } from 'react-hot-toast'
const ProfileAvatarCard = ({ showEdit = true, user, avatarType, location }) => {
    const { globalState, setGlobalState } = useWorkey()
    const [image, setImage] = useState(null);
    const originalImage = avatarType === 'avatar' ? user.avatar : user.logo
    const uploadImage = async () => {
        if (avatarType === 'avatar') {
            let user = { ...globalState.user }
            delete user.token
            delete user.branch
            const userReq = await makeApiRequest(globalState, setGlobalState, `/users/${user.id}`, 'PUT', user)
            const userRes = userReq[1]
            let userData = userReq[0].user
            userData['token'] = userReq[0].token
            if (userRes.status === 201 && userData.id) {
                setGlobalState({
                    ...globalState,
                    user: { ...globalState.user, ...userData }
                })
                toast.success(<Trans keys={'toast.updated.user_avatar'} />);
                setImage(null);
            } else {
                toast.error(<Trans keys={'toast.feild_to_update.user_avatar'} />);
            }
        } else {
            const branchReq = await makeApiRequest(globalState, setGlobalState, `/branches/${user.branch_id}`, 'PUT', user.branch)
            const branchRes = branchReq[1]
            const branchData = branchReq[0]
            if (branchRes.status === 201 && branchData.id) {
                setGlobalState({
                    ...globalState,
                    user: { ...globalState.user, branch: { ...globalState.user.branch, ...branchData } }
                })
                toast.success(<Trans keys={'toast.updated.branch_avatar'} />);
                setImage(null);
            } else {
                toast.error(<Trans keys={'toast.feild_to_update.branch_avatar'} />);
            }
        }
    }
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result;
            setImage(base64String);
            let new_global_state = { ...globalState }
            if (avatarType === 'avatar') {
                new_global_state.user.avatar = base64String
            } else {
                new_global_state.user.branch.logo = base64String
            }
            // setGlobalState(new_global_state)
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleCancelClick = async () => {
        await setImage(originalImage);
        let new_global_state = { ...globalState }
        if (avatarType === 'avatar') {
            new_global_state.user.avatar = null
        } else {
            new_global_state.user.branch.logo = null
        }
        setGlobalState(new_global_state)
    };

    const handleSubmitClick = () => {
        if (image) {
            uploadImage(image); // Call the uploadImage function with the base64 string
        }
    };

    // const imgSrc = () => {
    //     if(image) {
    //         return image
    //     }
    //     if(avatarType ==='avatar') {
    //         return globalState.user.avatar
    //     } else {
    //         return globalState.user.branch?.logo
    //     }
    // }
    return (
        <div className='profile-avatar-card'>
            <div className='profile-avatar-content'>
                <div className='text-center'>
                    {avatarType !== 'hide' && <Avatar src={avatarType === 'avatar' ? user.avatar : user.branch?.logo} name={avatarType === 'avatar' ? userDisplayName(user) : user.branch?.name} size={100} round={true} />}
                </div>
            </div>
            {(avatarType !== 'hide' && showEdit) && (
                <div className='profile-avatar-edit'>
                    <label htmlFor='image-upload-input' className='btn btn-sm btn-app-blue'>
                        <Trans keys={"upload_image"} />
                    </label>
                    <input
                        type='file'
                        id='image-upload-input'
                        accept='image/*'
                        onChange={handleImageChange}
                        hidden
                    />
                    {image && (
                        <>
                            <div className='btn btn-sm btn-app-red' onClick={handleCancelClick}>
                                <Trans keys={"cancel"} />
                            </div>
                            <div className='btn btn-sm btn-app-green' onClick={handleSubmitClick}>
                                <Trans keys={"save"} />
                            </div>
                        </>
                    )}
                </div>

            )}

            <ProfileNameDisplay user={user} location={location} />

        </div>
    );
};

export default ProfileAvatarCard;
