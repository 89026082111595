import React from 'react'
import { Trans } from '../../../lang/Trans'

const ProfileActionsCard = ({ cardsDisplay, toggleCardsDisplay }) => {
  return (
    <div className='profile-actions-card'>
      <div className='btn-container'>
        {<div className={`btn btn-sm btn-app-${cardsDisplay.includes('wages') ? 'light-blue' : 'blue'}`} onClick={() => toggleCardsDisplay('wages')}><Trans keys={`wages.title`} /></div>}
      </div>
      <div className='btn-container'>
        <div className={`btn btn-sm btn-app-${cardsDisplay.includes('scheduling') ? 'light-blue' : 'blue'}`} onClick={() => toggleCardsDisplay('scheduling')}><Trans keys={'scheduling_settings.title'} /></div>
      </div>
      <div className='btn-container'>
        <div className={`btn btn-sm btn-app-${cardsDisplay.includes('authorized_actions') ? 'light-blue' : 'blue'}`} onClick={() => toggleCardsDisplay('authorized_actions')}><Trans keys={'authorized_actions.title'} /></div>
      </div>
      <div className='btn-container'>
        <div className={`btn btn-sm btn-app-${cardsDisplay.includes('general') ? 'light-blue' : 'blue'}`} onClick={() => toggleCardsDisplay('general')}><Trans keys={'general_actions'} /></div>
      </div>
    </div>
  )
}

export default ProfileActionsCard