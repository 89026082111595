import React, { useEffect, useState } from 'react'
import { mdiTrashCan } from '@mdi/js'
import { useWorkey } from '../../../../../../WorkeyContext'
import Icon from '@mdi/react'
import { rebuildWeek } from '../../../../../../utils/week_utils'
import { Trans } from '../../../../../../lang/Trans'
import { toast } from 'react-hot-toast'
import { makeApiRequest } from '../../../../../../utils/utils'
import moment from 'moment'
const ScheduleSideBtns = ({ currentWeek, folded, selectedSlotId, setSelectedSlotId, employeeWatch, setEmployeeWatch, sideToolAssginUserToSlot, edit }) => {
    const { globalState, setGlobalState } = useWorkey()
    const [selectedSlot, setSelectedSlot] = useState(globalState.currentWeek?.slots?.find(slot => slot.id == selectedSlotId))
    useEffect(() => {
        setSelectedSlot(globalState.currentWeek?.slots?.find(slot => slot.id == selectedSlotId))
    }, [selectedSlotId])

    const remmoeAssignedUser = async () => {
        await sideToolAssginUserToSlot('')
    }

    const askReplacment = async (asked) => {
        if (!asked) {
            let ask = toast((t) => (
                <div className='d-flex flex-column'>
                    <Trans keys={'side_tools.ask_replacment.are_you_sure'} />
                    <div className='btn btn-sm btn-app-blue mt-2'
                        onClick={() => askReplacment(t)} >
                        <Trans keys={'side_tools.ask_replacment.confirm_btn'} />
                    </div>
                </div>
            ), 10 * 1000);
        } else {
            toast.dismiss(asked.id)
            const req = await makeApiRequest(globalState, setGlobalState, `/replacements`, "POST",
                {
                    user_id: globalState.user.id,
                    slot_id: selectedSlotId,
                    options: '',
                    status: 0
                })
            const data = req[0]
            const res = req[1]
            if (res.status === 201) {
                setGlobalState({ ...globalState, user: { ...globalState.user, replacements_requests: [...globalState.user.replacements_requests || [], data] } })
                toast.success((t) => (
                    <Trans keys={'side_tools.ask_replacment.success'} />
                ));
            }
        }
    }

    const slotDate = () => {
        let shift = currentWeek?.shifts.find(shift => shift.id == selectedSlot?.shift_id)
        if (shift) {
            let day = currentWeek.days.find(day => day.id == shift.day_id)
            if (day) {
                return moment(day.date)
            }
        }
    }
    return (
        <div className='schedule-side-btns d-flex flex-row justify-content-center'>
            {!folded && edit && selectedSlot?.user_id && <div className='btn btn-sm mt-3 pointer btn-app-red' onClick={() => remmoeAssignedUser()} >
                <Icon path={mdiTrashCan} size={1} className="text-light" />
            </div>}
            {(slotDate() > new Date()) &&
                !folded && selectedSlot?.user_id && selectedSlot?.user_id == globalState.user.id && <div className='btn btn-sm mt-3 mx-3 pointer btn-app-light-blue'
                    onClick={() => askReplacment()} >
                    <Trans keys={'side_tools.ask_replacment.btn_title'} />
                </div>}
        </div>
    )
}

export default ScheduleSideBtns