import React, { useEffect, useState } from 'react'
import { useWorkey } from '../../../WorkeyContext';
import ReplacementDisplay from './ReplacementDisplay';
import { makeApiRequest } from '../../../utils/utils';
import { Trans } from '../../../lang/Trans';
import './Replacements.css'

import { toast } from 'react-hot-toast';
import UnavailableFeature from '../../blocks-ui/UnavailableFeature';

const ReplacementsPage = () => {
  const { globalState, setGlobalState } = useWorkey()
  const [replacements, setReplacements] = useState([])
  useEffect(() => {
    getReplacements()
  }, [])

  const getReplacements = async () => {
    const req = await makeApiRequest(globalState, setGlobalState, '/replacements', 'GET')
    const data = req[0]
    const res = req[1]

    if (res.status === 200) {
      setReplacements(data)
    } else {
      toast.error(data.error)
    }
  }

  if (!globalState.user.branch.active_subscriptions.find(subscription => subscription.subscription_type === 'replacements')) {
    return <div>
      <UnavailableFeature/>
    </div>
  }

  return (
    <div className='replacements-page-container'>
      <div className='main-display card'>
        <div className='fw-bold text-center fs-5 mb-3'><Trans keys={'replacements_page.title'} /></div>
        <div className='replacements-list '>
          {replacements.length < 1 && <div className='text-center'><Trans keys={'replacements_page.no_replacements'} /></div>}
          {replacements?.map((replace) => {
            return <div key={replace.id} className='mb-1'><ReplacementDisplay
              replacements={replacements}
              setReplacements={setReplacements}
              key={replace.id}
              replacementData={replace} /></div>
          })}
        </div>
      </div>
    </div>
  )
}

export default ReplacementsPage