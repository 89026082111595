import React, { useEffect } from 'react'
import './Sidebar.css'
import Avatar from 'react-avatar';
import { Icon } from '@mdi/react';
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import SubLocation from './SubLocation'
import { sidebarActionsLocation, sidebarLocation } from '../../utils/routing'
import { useWorkey } from '../../WorkeyContext'
import { mdiDotsHorizontal, mdiDotsVertical, mdiMoveResize, mdiResize } from '@mdi/js';
import SidebarTools from './SidebarTools';
import { IconSize } from '../../utils/utils';

const Sidebar = () => {
    const { globalState, setGlobalState } = useWorkey()
    const sizeClass = globalState.largeSidebar ? 'xl' : 'sm'
    const [menuList, setMenuList] = useState(sidebarLocation(globalState))
    const [actionsList, setActionsList] = useState(sidebarActionsLocation())
    const [showTools, setShowTools] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const displayList = () => {
        if (location.pathname === '/' && sidebarLocation(globalState).length === 0) {
            navigate('/settings/user')
        } else {
            return menuList?.map((link, i) =>
                <SubLocation key={`link_${i}`} sizeClass={sizeClass} link={link} toggleSideBar={toggleSideBar} />
            );
        }
    }

    useEffect(() => {

        setMenuList(sidebarLocation(globalState))

    }, [useLocation().pathname, globalState?.user?.status, globalState?.user?.branch_id])

    const toggleSideBar = () => {
        let new_state = { ...globalState }
        new_state.largeSidebar = !new_state.largeSidebar
        setGlobalState(new_state)
    }

    useEffect(() => {
        if (sizeClass === 'sm') {
            setShowTools(false)
        }
    }, [sizeClass])

    useEffect(() => {
        if (sizeClass === 'sm' && showTools) {
            toggleSideBar()
        }
    }, [showTools])

    const avatar = globalState.user?.branch?.logo || globalState.user?.avatar
    return (<>
        <div className={`sidebar ${!globalState.user?.id ? "hidden" : ""} ${sizeClass}`}>
            <div className={`header ${sizeClass} px-1 d-flex ${sizeClass == 'sm' ? 'flex-column justify-content-center align-items-center' : ''}`}>
                {avatar == globalState.user?.avatar ? null : <Avatar src={avatar} round={false} className={sizeClass == 'sm' ? "mb-2" : ""} size={sizeClass == 'sm' ? 20 : 50} />}
                <span id="toggle-sidebar"><Icon path={sizeClass == 'sm' ? mdiDotsVertical : mdiDotsHorizontal} size={IconSize} className={'toggle-sidebar m-2 pointer'} onClick={() => toggleSideBar()} ></Icon></span>
            </div>
            <div className='body'>
                {showTools ? <SidebarTools actionsList={actionsList} /> : displayList()}
            </div>
        </div>
    </>
    )
}

export default Sidebar