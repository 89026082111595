import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { useWorkey } from '../../../WorkeyContext'
import { Trans } from '../../../lang/Trans'
import { makeApiRequest } from '../../../utils/utils'
import ProfilePageAvatar from '../../user/profile-page-avatar/ProfilePageAvatar'
import ProfileAvatarCard from '../../user-page/profile/profile-avatar-card/ProfileAvatarCard'

const BranchActions = () => {
    const { globalState, setGlobalState } = useWorkey()
    const initBranch = {
        company_name: '',
        logo: '',
        company_number: '',
        owner_id: null,
    }
    const [branchData, setBranchData] = useState(globalState.user.branch || initBranch)

    useEffect(() => {
        setBranchData(globalState.user.branch || initBranch)
    }, [globalState.user.branch])

    const onAvatarChange = (avatar) => {
        const file = avatar
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result;
            if (base64String) {
                let branch = { ...branchData, logo: base64String }
                let res = makeApiRequest(globalState, setGlobalState, `/branches/${globalState.user.branch_id}`, 'PUT', branch)
                if (res.id) {
                    setGlobalState({
                        ...globalState,
                        user: {
                            ...globalState.user,
                            branch: res
                        }
                    })
                } else {
                    console.error(res)
                }
            }
            setBranchData({ ...branchData, logo: base64String });
        };
    };


    return (
        <div className={'branch-actions-card card'}>
            <div className='p-3 branch-info-card'>
                <ProfileAvatarCard
                    location={'setup'}
                    showEdit={true}
                    user={globalState.user}
                    avatarType={'logo'} />
    
                {/* <Avatar src={branchData?.logo || globalState.user.avatar} name={branchData?.company_name || globalState.local === 'he' ? "לא ידוע" : "unknown"} size="100" round={true} /> */}
            </div>
        </div>
    )
}

export default BranchActions