import React from 'react'
import './PageNotFound.css'
import { Icon } from '@mdi/react';
import { Trans } from '../../lang/Trans'

const PageNotFound = () => {
    return (
        <div className='page_not_found'>
            <div className='msg'>
                <div className='nums'>
                    <div>4</div>
                    <div className="gears" id="two-gears">
                        <div className="gears-container">
                            <div className="gear-rotate"></div>
                            <div className="gear-rotate-left"></div>
                        </div>
                    </div>
                    <div>4</div>
                </div>
                <div className='text'>
                    <div><Trans keys={'text_404'}/></div>
                </div>
            </div>
        </div >
    )
}

export default PageNotFound