import { Icon } from '@mdi/react';
import React from 'react'
import { Trans } from '../../../../lang/Trans'
import { useWorkey } from '../../../../WorkeyContext'
import { mdiCancel, mdiCheck, mdiPlusCircle } from '@mdi/js';
import { IconSize } from '../../../../utils/utils';

const RoleItem = ({ role, editRole, setEditRole, saveEditRole }) => {
    const { globalState } = useWorkey()
    const positionsCount = (main = false) => {
        const positions = globalState.user.branch.positions
        if (main) {
            return positions.filter(position => position.main && position.role_id === role.id).length
        } else {
            return positions.filter(position => position.role_id === role.id).length
        }
    }
    return (
        <div className='item employee-item pointer'>
            <div>
                {/* {editRole.id === role.id ? 
                <div className='d-flex'>
                    <input type='text' className='form-control' defaultValue={role.name} onChange={e => setEditRole({id: role.id, value: e.target.value})}/>
                    <div className='btn btn-sm btn-app-red mx-2'  onClick={() => setEditRole({id: null, value: null})}><Icon path={mdiCancel} size={1}/></div>
                    <div className='btn btn-sm btn-app-green' onClick={() => saveEditRole(role.id)}><Icon path={mdiCheck} size={1}/></div>
                </div>: */}
                <div>
                    <div className='fw-bolder'>{role.name}</div>
                    {/* <div><Trans keys={'main'} /> {positionsCount(true)} / <Trans keys={'total'} /> {positionsCount()} </div> */}
                    <div><Trans keys={'display_order'} />: {role.order || 0} </div>
                </div>
                {/* } */}
            </div>
        </div>
    )
}

export default RoleItem