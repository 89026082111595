import React, { useEffect, useState } from 'react'
import './WelcomeNewUser.css'
import '../Welcome.css'
import { Trans } from '../../../lang/Trans'
import EmployerFlow from './EmployerFlow'
import UserProfile from '../../user/UserProfile'
import User from '../../../utils/user_utils'
import { useWorkey } from '../../../WorkeyContext'
import Brain from '../../loading/Brain'
import { makeApiRequest } from '../../../utils/utils'
import NewUserProfile from '../../user/new-user-profile/NewUserProfile'
import UserLayout from '../../user-page/user-layout/UserLayout'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const WelcomeNewUser = () => {
  const { globalState, setGlobalState } = useWorkey()
  const userUtils = new User(globalState)
  const navigate = useNavigate()
  if(!globalState.user) {
    navigate('/login')
    return null
  }

  const activateUser = async () => {
    const { status_list } = userUtils;
    const user_updates = updateUserStatus(status_list.normal);
    const url = `/users/${globalState.user.id}`;
    const method = "PUT";
    const req = await makeApiRequest(globalState, setGlobalState, url, method, user_updates);
    const res = req[1];
    const data = req[0].user;
    let new_user = { ...globalState.user, ...data };
    if (res.status === 201 && data.id) {
      setGlobalState({
        ...globalState,
        user: new_user
      })
    } else {
      toast.error(<Trans keys={'toast.failed.activate_user'} />);
    }
  }

  const updateUserStatus = (status) => {
    const { username, email, first_name, last_name, mobile, gender, birth_date, telegram_id, avatar, data } = globalState.user;
    return { username, email, first_name, last_name, mobile, gender: gender.toLowerCase(), birth_date, telegram_id, status, avatar, data };
  }

  const startEmployerSetup = () => {
    const user_updates = updateUserStatus(userUtils.status_list['started_employer_setup'])
    if (user_updates) {
      let user = { ...globalState.user, ...user_updates }
      setGlobalState({
        ...globalState,
        user: user
      })
    } else {
      toast.error(<Trans keys={'toast.error.start_employer_setup'} />);    }
  }


  return (
    <div className='welcome new-user'>
      <div className='card p-4'>
        {globalState.user?.status === userUtils.status_list['start'] && (
          <>
            <div className='welcome-title d-flex flex-column text-center'>
              <div className='fw-bold fs-3  app-dark-blue-txt mb-4'><Trans keys={'welcome.title'} /></div>
              <div><Trans keys={'welcome.new_user.greeting'} /></div>
            </div>
            <div className='d-flex flex-row text-center justify-content-center mt-4'>
              <Trans keys={'welcome.new_user.this_is_you'} />
            </div>
            <div className='mt'>
            </div>
            <div className='mt-1'><UserLayout /></div>
          </>
        )}

        {globalState.user.status === userUtils.status_list['completed_user_setup'] && !globalState.user.branch_id &&
          <>
            <div>
              <div className='d-flex flex-row justify-content-center m-3'><Trans keys={`you.${globalState.user.gender}`} /></div>
              <div className='d-flex flex-row justify-content-center'>
                <div className='card clickable setup-btn mx-5 p-5' onClick={() => activateUser()}>
                  <div className='p-2'><Trans keys={`employee_gender.${globalState.user.gender}`} /></div>
                </div>
                <div className='card clickable setup-btn mx-5 p-5' onClick={() => startEmployerSetup()}>
                  <div className='p-2'><Trans keys={`employer_gender.${globalState.user.gender}`} /></div>
                </div>
              </div>
            </div>
          </>
        }
        {(globalState.user.status === userUtils.status_list["started_employer_setup"] || globalState.user.status === userUtils.status_list["completed_branch_setup"]) &&
          <>
            <div>
              <EmployerFlow activateUser={activateUser} />
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default WelcomeNewUser