import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Trans } from '../../lang/Trans';
import { makeApiRequest } from '../../utils/utils';
import { useWorkey } from '../../WorkeyContext';
import { toast } from 'react-hot-toast';
import './ContactUs.css';
import { isValidEmail, isValidMobile } from '../../utils/data_utils';
const ContactUs = ({ onSave }) => {
    const { globalState, setGlobalState } = useWorkey();
    const [subject, setSubject] = useState('');
    const [otherSubject, setOtherSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [email, setEmail] = useState(globalState.user?.email || '');
    const [mobile, setMobile] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [messageError, setMessageError] = useState('');

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
        setSubjectError('');
    };

    const handleOtherSubjectChange = (event) => {
        setOtherSubject(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
        setMessageError('');
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setEmailError('');
    };

    const handleMobileChange = (event) => {
        setMobile(event.target.value);
        setMobileError('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isFormValid = validateForm();

        if (isFormValid) {
            const req = await makeApiRequest(globalState, setGlobalState, '/messages', 'POST', {
                sender_id: globalState.user?.id,
                subject: subject === 'other' ? otherSubject : subject,
                message,
                email: email === '' ? globalState.user?.email : email,
                mobile,
            });
            const data = req[0];
            const res = req[1];
            if (res.status === 201) {
                toast.success(<Trans keys='contact_us.message_sent' />);
                setSubject('');
                setOtherSubject('');
                setMessage('');
                setEmail('');
                setMobile('');
                setIsValid(true);
            } else {
                setIsValid(true);
                toast.error(data.error);
            }
        }
    };

    const validateForm = () => {
        let isFormValid = true;

        if (subject === '') {
            setSubjectError(true);
            isFormValid = false;
        }

        if (subject === 'other' && otherSubject === '') {
            setSubjectError(true);
            isFormValid = false;
        }

        // TODO add a valid email validation
        if ((!email || email === '') && !globalState.user?.email && !isValidEmail(email)) {
            setEmailError(true);
            isFormValid = false;
        }

        // TODO. mobile can be empty, but if not empty, then validate as a mobile phone number
        if (mobile.length > 0 && !isValidMobile(mobile)) {
            setMobileError(true);
            isFormValid = false;
        }

        if (message === '') {
            setMessageError(true);
            isFormValid = false;
        }

        setIsValid(isFormValid);
        return isFormValid;
    };


    return (
        <div className='contact-form-container'>
            <Form className="contact-form" onSubmit={handleSubmit}>
            <Form.Group controlId="subject">
                <Form.Label>
                    <div className="mb-2 fs-5 fw-bold text-center">
                        <Trans keys={"contact_us.title"} />
                    </div>
                </Form.Label>
                <Form.Control
                    as="select"
                    value={subject}
                    onChange={handleSubjectChange}
                    isInvalid={subjectError !== ''}
                >
                    <option value="">
                        <Trans keys={"contact_us.subject"} />
                    </option>
                    <option value="bug">
                        <Trans keys={"contact_us.subjects.bug"} />
                    </option>
                    <option value="new_feature">
                        <Trans keys={"contact_us.subjects.new_feature"} />
                    </option>
                    <option value="subscription">
                        <Trans keys={"contact_us.subjects.subscription"} />
                    </option>
                    <option value="other">
                        <Trans keys={"contact_us.other"} />
                    </option>
                </Form.Control>
                {subject === 'other' && (
                    <>
                        <Form.Label><Trans keys={"contact_us.other"} /></Form.Label>
                        <Form.Control
                            type="text"
                            value={otherSubject}
                            onChange={handleOtherSubjectChange}
                            isInvalid={subjectError !== ''}
                        />
                    </>
                )}
                <Form.Control.Feedback type="invalid">
                    {subjectError ? <Trans keys='contact_us.subject_error' /> : ''}
                </Form.Control.Feedback>
            </Form.Group>            {!globalState.user?.email && (
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        isInvalid={emailError !== ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {emailError ? <Trans keys='contact_us.email_error' /> : ''}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
            {!globalState.user?.mobile && (
                <Form.Group controlId="mobile">
                    <Form.Label><Trans keys={"mobile"} /></Form.Label>
                    <Form.Control
                        type={"tel"}
                        value={mobile}
                        onChange={handleMobileChange}
                        isInvalid={mobileError !== ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {mobileError ? <Trans keys='contact_us.mobile_error' /> : ''}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
            <Form.Group controlId="message">
                <Form.Label><Trans keys={"contact_us.message"} /></Form.Label>
                <Form.Control
                    as="textarea"
                    rows={6}
                    value={message}
                    onChange={handleMessageChange}
                    isInvalid={messageError !== ''}
                />
                <Form.Control.Feedback type="invalid">
                    {messageError ? <Trans keys='contact_us.message_error' /> : ''}
                </Form.Control.Feedback>
            </Form.Group>

            <div className="text-center mt-3">
                <Button type="submit"><Trans keys={"submit"} /></Button>
            </div>
        </Form>
        </div>
    );
}
export default ContactUs  