import React, { useState } from 'react'
import { userDisplayName } from '../../../../../utils/utils'
import { employeeAssingmentsCount, employeeReqestsCount } from '../../../../../utils/scheduling_utils'
import { useWorkey } from '../../../../../WorkeyContext';
import Icon from '@mdi/react';
import { mdiEye, mdiEyeRemove } from '@mdi/js';

const EmployeeSideTool = ({ currentWeek, setCurrentWeek, employee, slot, sideToolAssginUserToSlot, employeeWatch, setEmployeeWatch, selectedSlotId, edit }) => {
    const { globalState } = useWorkey();
    const shift = currentWeek.shifts.find(shift => shift.id == slot?.shift_id);
    const employeeBgColor = () => {
        let bg = ''
        if (edit) {
            if (!slot) { return bg }
            if (shift.slots.filter(slot => slot.user_id == employee.id).length > 0) {
                if(currentWeek.requests.find(req => req.user_id == employee.id && req.shift_id == shift.id)) {
                    bg = 'bg-secondary fw-n'
                } else {
                    bg = 'assgined-not-requested'
                }

            } else {
                let request = currentWeek.requests.find(req => req.user_id == employee.id && req.shift_id == shift.id)
                if (request) {
                    if (request.priority == 1) {
                        bg = 'high-priority-employee-tool'
                    }
                    if (request.priority == 2) {
                        bg = 'low-priority-employee-tool'
                    }
                }
            }
        }
        return bg
    }

    const generateColors = () => {
        // Generate a random hex color
        const bgColor = '#' + Math.floor(Math.random() * 16777215).toString(16);

        // Calculate the brightness of the background color
        const brightness = Math.round((parseInt(bgColor, 16) * 299 +
            parseInt(bgColor, 16) * 587 +
            parseInt(bgColor, 16) * 114) / 1000);

        // Choose a text color based on the background brightness
        const textColor = (brightness > 125) ? '#000' : '#fff';

        // Return the colors as an object
        return { bgColor, textColor };
    }

    const toggleWatch = async () => {
        if (employeeWatch?.id === employee.id) {
            setEmployeeWatch(false)
        } else {
            setEmployeeWatch({ ...employee, ...generateColors() })
        }
    }

    const employeeAssignmentDisplay = () => {
        if (!edit) return null
        return `(${employeeAssingmentsCount(employee, currentWeek.slots)}/${employeeReqestsCount(employee, currentWeek.requests)})`
    }

    return (
        <div className={`schedule-users-item ${employeeWatch?.id == employee?.id ? 'text-light fw-bold' : employeeBgColor()}`} onClick={() => selectedSlotId ? sideToolAssginUserToSlot(employee) : toggleWatch()}>
            <div className={`schedule-users-item-name`}>{userDisplayName(employee)}</div>
            <div className='d-flex flex-row'>
                <div className='schedule-users-item-counts'>{employeeAssignmentDisplay()}</div>
                <div className='me-2'><Icon path={employeeWatch?.id == employee?.id ? mdiEyeRemove : mdiEye} size={1} onClick={() => toggleWatch()} /></div>
            </div>
        </div>)
}

export default EmployeeSideTool