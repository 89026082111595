import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Trans } from '../../../../../lang/Trans';
import { useWorkey } from '../../../../../WorkeyContext';
import { newShift } from '../../../../../utils/week_utils';
import { newSlot } from '../../../../../utils/week_utils';
import { makeApiRequest } from '../../../../../utils/utils';
import { rebuildWeek } from '../../../../../utils/week_utils';
import { validate } from 'uuid';
import { toast } from 'react-hot-toast';
const notify = () => toast('Here is your toast.');

const AddSlotsModal = ({ currentWeek, setCurrentWeek }) => {
    const { globalState, setGlobalState } = useWorkey();
    const [selectedShift, setSelectedShift] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [numberOfSlots, setNumberOfSlots] = useState(1);
    const [show, setShow] = useState(true);
    const [errors, setErrors] = useState({});
    const handleShiftChange = (event) => {
        setSelectedShift(event.target.value);
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };


    const handleNumberOfSlotsChange = (event) => {
        let value = Number(event.target.value)
        if (value < 1 || typeof value !== 'number') {
            setNumberOfSlots(1);
        } else {
            setNumberOfSlots(value);
        }
    };

    const valid = () => {
        let new_errors = {}
        if (!selectedShift) {
            new_errors.selectedShift = true;
        }
        if (!selectedRole) {
            new_errors.selectedRole = true;
        }
        if (!numberOfSlots) {
            new_errors.numberOfSlots = true;
        }
        setErrors(new_errors)
        return Object.keys(new_errors).length === 0
    }
    const handleSave = async () => {
        if (!valid()) {
            return
        }
        const shift_i = await currentWeek.shifts.findIndex((s) => s.id == selectedShift)
        const shift = await currentWeek.shifts[shift_i]
        const day_i = await currentWeek.days.findIndex((d) => d.id == shift?.day_id)
        // const day = currentWeek.days[day_i]
        const role = globalState.user.branch.roles.find((r) => r.id == selectedRole)
        let new_slot = newSlot(shift, role)
        new_slot['quantity'] = numberOfSlots
        const req = await makeApiRequest(globalState, setGlobalState, '/slots', "POST", { slot: new_slot })
        const data = req[0]
        const res = req[1]
        if (res.status === 201) {
            let new_current_week = { ...currentWeek }
            // new_state.currentWeek.days[day_i].shifts[shift_i].slots.push(data)
            new_current_week.days[day_i].shifts[new_current_week.days[day_i].shifts.findIndex((s) => s.id == shift.id)].slots = new_current_week.days[day_i].shifts[new_current_week.days[day_i].shifts.findIndex((s) => s.id == shift.id)].slots.concat(data)
            new_current_week.shifts[shift_i].slots = new_current_week.shifts[shift_i].slots.concat(data)
            new_current_week.slots = new_current_week.slots.concat(data)
            new_current_week = await rebuildWeek(new_current_week, globalState)
            setCurrentWeek(new_current_week)
            setShow(false)
        } else {
            toast.error(data.error ||<Trans keys={ 'server error'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
        }

    }

    const handleCancel = () => {
        setShow(false)
    }

    return (
        <Modal className='rtl' show={show}>
            <Modal.Header>
                <Modal.Title><Trans keys={'add_slots'} /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="shiftSelect">
                        <Form.Label><Trans keys={'select_Shift'} /></Form.Label>
                        <Form.Control as="select" value={selectedShift} onChange={handleShiftChange}>
                            <option key={'select-shift'} value={null}><Trans keys={`select`} /> </option>
                            {currentWeek?.days.map(day => day.shifts?.map((shift) => {
                                const day_i = currentWeek.days.findIndex((day) => day.id === shift.day_id)
                                const day = currentWeek.days[day_i]
                                const shift_i = day.shifts.findIndex((s) => s.id === shift.id)
                                return (
                                    <option key={shift.id} value={shift.id}><Trans keys={`day_${day_i + 1}`} /> <Trans keys={'shift'} /> {shift_i + 1}</option>
                                )
                            }))}
                            {errors.selectedShift && <Form.Label className={'app-red-text'}><Trans keys={'required'} /></Form.Label>}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="shiftRole">
                        <Form.Label><Trans keys={'select_role'} /></Form.Label>
                        <Form.Control as="select" value={selectedRole} onChange={handleRoleChange}>
                            <option key={'select-role'} value={null}><Trans keys={`select`} /> </option>
                            {globalState.user.branch.roles.map((role) => {
                                return (
                                    <option key={role.id} value={role.id}>{role.name}</option>
                                )
                            })}
                            {errors.selectedRole && <Form.Label className={'app-red-text'}><Trans keys={'required'} /></Form.Label>}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="slotNumber">
                        <Form.Label><Trans keys={'number_of_required_slots'} /></Form.Label>
                        <Form.Control type="number" min={1} value={numberOfSlots || 1} onChange={handleNumberOfSlotsChange} />
                        {errors.numberOfSlots && <Form.Label className={'app-red-text'}><Trans keys={'required'} /></Form.Label>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row justify-content-center'>
                <Button variant="app-red" onClick={handleCancel}><Trans keys={'cancel'} /></Button>
                <Button variant="app-green" onClick={handleSave}><Trans keys={'save'} /></Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddSlotsModal;
