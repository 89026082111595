import React, { useEffect, useState } from 'react'
// import { apiRequest, API_PATH } from '../../../assets/utils/paths';
import './Telegram.css'
import Icon from '@mdi/react'
import { mdiSend } from '@mdi/js'
import { useWorkey } from '../../WorkeyContext'
import { makeApiRequest } from '../../utils/utils'
import { toast } from 'react-hot-toast'
import { Trans } from '../../lang/Trans'

function Telegram({ state, setIsLoading, setState }) {
    const {globalState, setGlobalState} = useWorkey()
  useEffect(() => {
    if (!telegramId) {
      getUserTelegramId(false)
    }
  }, [])

  const [telegramId, setTelegramId] = useState(null)
  const [connected, setConnected] = useState(false)
  const getUserTelegramId = async(open = true) => {
    const req = await makeApiRequest(globalState, setGlobalState, '/get_telegram_id', "GET")
    const data = req[0]
    const res = req[1]
    if(res.status === 200) {
        setGlobalState({ ...globalState, user: { ...globalState.user, telegram_id: data } })
        if (data?.user?.telegram_id) {
            let telegram_id = data.user.telegram_id
            if (telegram_id.startsWith('workey')) {
              setConnected(false)
              if (open) {
                window.open(`https://t.me/WorkeySystemBot?start=${telegram_id}`, '_blank');
              }
            } else {
              setConnected(true)
              if (open) {
                toast.error(data.error ||<Trans keys={'toast.already_connected_to_telegram'} />);

              }
            }
          }
    }
  };

  const disconnectFromTelegram = async() => {
    const req = await makeApiRequest(globalState, setGlobalState, '/telegram_disconnect', "DELETE")
  };

  return (
    <>
      {connected ?
        <div>
          <div>איזה כיף! כבר יש חיבור בנינו...</div>
          <div>אם ברצונך לנתק את החיבור יש ללחוץ כאן</div>
          <div className="telegram-btn btn red-bg" onClick={() => { disconnectFromTelegram() }}>
            <i className="fa-brands fa-telegram" />
          </div>
        </div>
        :
        <div>
          <div>כדי לחבר את חשבונך לטלגרם יש ללחוץ כאן</div>
          <div className="telegram-btn btn btn-app-blue" onClick={() => { getUserTelegramId(true) }}>
            <Icon path={mdiSend} size={1.2}/>
          </div>
        </div>}
    </>
  )
}

export default Telegram