import { isEqual } from "lodash"
import { FirstDayNum } from "./system_settings"
import moment from "moment"
import State from "./current_week_utils"

export const isWeekDay = (date) => {
  const day = date.getDay()
  return day === FirstDayNum
}

export const employeePositions = (globalState, employee_id) => {
  let positions = [...globalState.user.branch?.positions]
  return positions.filter(position => position.user_id === employee_id)
}

export const branchRoles = (globalState) => {
  return globalState.user.branch?.roles
}

export const getEmployee = (globalState, employee_id) => {
  let branch = globalState.user.branch
  return branch.employees.find(employee => employee.id === employee_id)
}

export const employeeRoles = (globalState, employee_id) => {
  let employee_positions = employeePositions(globalState, employee_id)
  let positions = employee_positions.map(position => branchRoles(globalState).find(role => role.id === position.role_id))

  return positions
}

export const findEmployee = (globalState, employee_id) => {
  return globalState?.user?.branch?.employees?.find(employee => employee.id === employee_id)
}

export const getMaxObjectsByRoleAndShift = (currentWeek, shiftId = null, roleId = null) => {
  if (!currentWeek) { return [] }
  const maxObjectsByRole = {};

  // Loop through each day
  currentWeek?.days?.forEach((day) => {
    // Loop through each shift of the day
    day.shifts?.filter(shift => !shift.hide)?.forEach((shift) => {
      // If a shiftId is provided and it doesn't match the current shift, skip it
      if (shiftId && shift.id !== shiftId) {
        return;
      }
      // Loop through each slot of the shift
      shift.slots?.forEach(({ role_id, shift_id }) => {
        // If a roleId is provided and it doesn't match the current role, skip it
        if (roleId && role_id !== roleId) {
          return;
        }
        // Increment the count for the current role_id and shift_id
        const key = `${role_id}_${shift_id}`;
        maxObjectsByRole[key] = {
          role_id,
          shift_id,
          count: (maxObjectsByRole[key]?.count || 0) + 1,
        };
      });
    });
  });

  // Loop through the counts for each role_id and shift_id and get the max count
  const maxObjects = [];
  Object.keys(maxObjectsByRole)?.forEach((key) => {
    const { role_id, shift_id, count } = maxObjectsByRole[key];
    if (!maxObjects[role_id] || count > maxObjects[role_id].count) {
      maxObjects[role_id] = { role_id, shift_id, count };
    }
  });

  // Filter out any undefined or null elements from the array
  let filteredMaxObjects = maxObjects.filter(Boolean);

  // If a shiftId is provided, filter the max count data for that shift
  if (shiftId) {
    filteredMaxObjects = filteredMaxObjects.filter((data) => data.shift_id === shiftId);
  }

  // If a roleId is provided, filter the max count data for that role
  if (roleId) {
    filteredMaxObjects = filteredMaxObjects.filter((data) => data.role_id === roleId);
  }

  return filteredMaxObjects;
};

export const findSlotById = (days, slotId) => {
  // Loop through each day
  for (const day of days) {
    // Loop through each shift of the day
    for (const shift of day.shifts) {
      // Loop through each slot of the shift
      for (const slot of shift.slots) {
        // If the slot ID matches the ID we are looking for, return the day, shift, and slot objects
        if (slot.id === slotId) {
          return { day, shift, slot };
        }
      }
    }
  }

  // If no matching slot is found, return null
  return null;
};

export const employeeAssignmentStatus = (globalState, employee, tested_slot) => {
  let status = 1;

  let week = globalState.currentWeek
  let assignments = week.slots.filter(slot => slot.user_id === employee.id)
  assignments?.forEach(assigned_slot => {
    if (assigned_slot.id === tested_slot.id) {
      status = 0
    } else {
      if (assigned_slot.shift_id === tested_slot.shift_id) {
        // working in that shift
        status = 2
        if (slotsTimeOverlap(assigned_slot, tested_slot)) {
          // overlap
          status = 0
        }
      }
    }
  })

  return status
}

const slotsTimeOverlap = (assigned_slot, tested_slot) => {
  const assigned_start = assigned_slot.expected_start
  const assigned_end = assigned_slot.expected_end
  const tested_start = tested_slot.expected_start
  const tested_end = tested_slot.expected_end
  if (assigned_start < tested_end && tested_start < assigned_end) {
    return true // overlap between assigned_slot and tested_slot
  }
  return false // no overlap
}

export const schedulingUtils = {
  "isWeekDay": isWeekDay
}

export const lastFirstDay = new Date().getDay() === 0 ? moment(new Date()).format() : moment().local().day(0).format();

export const employeeReqestsCount = (employee, requests) => {
  return requests.filter(request => request?.user_id === employee.id).length
}

export const employeeAssingmentsCount = (employee, slots) => {
  return slots.filter(slot => slot?.user_id === employee.id).length
}

export const shiftClass = (currentWeek, shift, employeeWatch) => {
  let class_names = ''
  if (!currentWeek?.requests || !shift || !employeeWatch) {
    return class_names
  }

  let request_shift = currentWeek.requests.find(r => r.user_id == employeeWatch.id && r.shift_id == shift.id)
  let watch_assgined_in_shift = currentWeek.slots.find(s => s.user_id && s.user_id == employeeWatch.id && s.shift_id == shift.id)

  if (request_shift && !watch_assgined_in_shift) {
    class_names = `shift-requested-by-watch-not-assgined border priority-${request_shift.priority}`
  }


  return class_names

}

// export const slotClass = (globalState, slot = null, employeeWatch = false) => {
  
//   if(!slot) {
//     return "bg-dark"
//   }
//   let class_names = ''
//   if (!globalState.currentWeek.requests || !slot) {
//     return class_names
//   }

//   let shift_requests = globalState.currentWeek.requests.filter(r => slot.main_available_employees.some(emp => emp.id === r.user_id));
//   let shift_requested_by_watch = shift_requests.some(sr => slot.main_available_employees.some(emp => emp.id === sr.user_id));
//   let shift_requested_by_main = globalState.currentWeek.requests.find(r => slot.main_available_employees?.find(emp => emp.id == r.user_id) && r.shift_id == slot.shift_id)
//   let shift_requested_by_other = globalState.currentWeek.requests.find(r => slot.available_employees?.find(emp => emp.id == r.user_id) && r.shift_id == slot.shift_id)

//   if (employeeWatch) {
//     if (slot.user_id && employeeWatch.id == slot.user_id) {
//       if (shift_requested_by_watch) {
//         class_names = hilightClass["slot-assgined-requested-by-watch"]
//       } else {
//         class_names = hilightClass['slot-assgined-not-requested-by-watch']
//       }
//     }

//   } else {
//     if (!slot.user_id) {
//       if (shift_requested_by_main && !new State(globalState).checkEmployeeInShift(shift_requested_by_main.user_id, shift_requested_by_main.shift_id)) {
//         class_names = hilightClass["not-assgined-requested-by-main"]
//       } else {
//         if(shift_requested_by_other && !new State(globalState).checkEmployeeInShift(shift_requested_by_other.user_id, shift_requested_by_other.shift_id)){
//           class_names = hilightClass["not-assgined-requested-by-other"]
//         } else {
//           class_names = hilightClass["not-assgined-not-requested"]
//         }
//       }
//     }

//   }
  
//   // return hilightClass["not-assgined-not-requested"]
//   return class_names
// }



