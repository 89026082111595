import React from 'react'
import { localDate } from '../../../../utils/dates'
import { Icon } from '@mdi/react';
import { mdiMenuDown, mdiMenuLeft, mdiPanLeft, mdiPlusCircle, mdiSetLeft } from '@mdi/js';
import { toolsSettings } from '../tools/tools_utils'
import Shift from '../shift/Shift'
import { useState } from 'react'
import { useEffect } from 'react'
import { addShift } from '../../../../utils/week_utils'
import { Trans } from '../../../../lang/Trans'
import { useWorkey } from '../../../../WorkeyContext'
import { mdiAccount } from '@mdi/js';
import { IconSize, isSmallScreen } from '../../../../utils/utils';
import moment from 'moment';

const Day = (props) => {
    const { mark, toggleForceUpdate, maxPerRole, currentWeek, setCurrentWeek, day_num, edit, tools, selectedSlotId, setSelectedSlotId, toggleTimeModal, employeeWatch, toggleUpdate } = props
    const { globalState, setGlobalState } = useWorkey()

    const [day, setDay] = useState({ ...currentWeek?.days?.[day_num] })
    const dayCalendar = moment(day.date).day()
    const todayCalendar = moment().day()
    const [hideDay, setHideDay] = useState(false)
    const shifts = () => {
        return day?.shifts?.map((shift, shift_num) => {
            // if (!shift || (part && part != `shift_${shift_num + 1}`)) { return <></> }
            if (shift.day_id == day.id) {
                return <>
                    <Shift
                        mark={mark}
                        toggleForceUpdate={toggleForceUpdate}
                        maxPerRole={maxPerRole}
                        currentWeek={currentWeek}
                        setCurrentWeek={setCurrentWeek}
                        toggleUpdate={toggleUpdate}
                        employeeWatch={employeeWatch}
                        key={`shift_${shift_num}`}
                        edit={edit}
                        day_id={day.id}
                        shift={shift}
                        shift_id={shift.id}
                        shift_day_num={shift_num}
                        tools={tools}
                        selectedSlotId={selectedSlotId}
                        setSelectedSlotId={setSelectedSlotId}
                        toggleTimeModal={toggleTimeModal} />
                </>
            }
        })
    }

    useEffect(() => {
        setDay(currentWeek?.days?.[day_num])
    }, [currentWeek, currentWeek?.slots?.length])
    useEffect(() => {
        let day = { ...currentWeek?.days?.[day_num] }
        if (isSmallScreen() && todayCalendar != dayCalendar) {
            toggleHideDay()
        }
        setDay(day)
    }, [])

    const toggleHideDay = async () => {
        let current_week = { ...currentWeek }
        const day_num = current_week.days.findIndex(d => d.id == day.id)
        // const shift_num = current_week.days[day_num]?.shifts.findIndex(shift => shift.id == shift_id)
        if (!current_week.days[day_num]) {
            return
        }
        current_week.days[day_num]['hide'] = !current_week.days[day_num]['hide'] ? true : false
        await setGlobalState({ ...globalState, currentWeek: current_week })
        await setCurrentWeek(current_week)
        await setHideDay(!hideDay)
        toggleForceUpdate()
    }

    return (
        <>
            {hideDay && <div className={`day day-folded day-folded-${day_num} pointer`} onClick={() => toggleHideDay()} >
                <div className='day-title'>
                    <div><Trans keys={`day_${day_num + 1}`} /></div>
                    <div><Icon path={hideDay ? mdiMenuLeft : mdiMenuDown} size={1} /></div>
                </div>
            </div>}
            <div className={!hideDay ? `day col` : "hidden"}>


                <div className=''>
                    <div className='day-title-container pointer' onClick={() => toggleHideDay(!hideDay)} >
                        {/* <div className='d-flex /'> */}
                        <div className='day-title'>
                            <div className='toogle-day-btn'>
                                <Icon path={hideDay ? mdiMenuLeft : mdiMenuDown} size={1} />
                            </div>
                            <div className='date-and-day'><Trans keys={`day_${day_num + 1}`} /> {moment(day?.date).format('DD.MM')}</div>
                        </div>
                        {/* </div> */}
                    </div>
                    <div className='day-btns text-center'>
                        {tools?.add_shifts ? <Icon path={mdiPlusCircle} size={IconSize} onClick={() => addShift(currentWeek, setCurrentWeek, globalState, day, setGlobalState)} className={`pointer text-${toolsSettings.add_shifts.color}`} /> : <></>}
                    </div>
                    <div className={`day-body`}>
                        {shifts()}
                    </div>
                </div>
                {/* : <><Trans keys={`day_${day_num + 1}`} /></>} */}
            </div>
        </>
    )
}

export default Day