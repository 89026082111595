import React from 'react';
import Select from 'react-select';
import { useWorkey } from '../../../../../WorkeyContext';
import { userDisplayName } from '../../../../../utils/utils';
import './ActionAs.css';
import { Trans } from '../../../../../lang/Trans';
import User from '../../../../../utils/user_utils';

const ActionAs = ({setActAsUser}) => {
    const { globalState, setGlobalState } = useWorkey();
    const userUtils = new User(globalState);
    const handleSelectChange = async (selectedOption) => {
        let user = { ...globalState.user };
        user = { ...user, id: selectedOption.value } ;
        setActAsUser(user)
        await setGlobalState({...globalState, user});
        // callback();
    };

    const options = globalState.user.branch.employees.map((employee) => ({
        value: employee.id,
        label: userDisplayName(employee, true),
    }));

    if (!userUtils.allowedTo('manage_employee') && !userUtils.allowedTo('manage_schedule')) {
        return <></>
    }
    return (
        <div className='action-as-container'>
            <Trans keys={'as'} />
            <Select
                className='action-as'
                value={options.find((option) => option.value == globalState.user.id)}
                options={options}
                onChange={handleSelectChange}
            />
        </div>
    );
};

export default ActionAs;
