import React from 'react'
import './ProfileNameDisplay.css'
import { userDisplayName } from '../../../../utils/utils'
import { useWorkey } from '../../../../WorkeyContext'
import { Trans } from '../../../../lang/Trans'
import User from '../../../../utils/user_utils'
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js'
import MainRoleManage from '../main-role-manage/MainRoleManage'
import NotMainRoleManage from '../not-main-role-manage/NotMainRoleManage'

const ProfileNameDisplay = ({ user, location }) => {
    const { globalState } = useWorkey()
    const userUtils = new User(globalState)
    return (
        <div className='profile-name-display-container'>
            {location !== 'setup' && <>
                <div className='name-display'>{userDisplayName(user)}</div>
                <div className='owner-title fw-bolder app-blue-txt'>{user.id == globalState.user.branch?.owner_id && <Trans keys={'branch_manager'} />}</div>
                <MainRoleManage user={user} />
                {user.main_role?.id && <NotMainRoleManage currentUser={user} />}
            </>}
        </div>
    )
}

export default ProfileNameDisplay