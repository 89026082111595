import React from 'react'
import { Link } from 'react-router-dom'
import { apiReq } from '../../utils/utils.js'
import { useWorkey } from '../../WorkeyContext.js'
const AdminDashboard = () => {
  const { globalState } = useWorkey()

  function downloadFile() {
    fetch('/admins/download_file')
      .then(response => response.blob())
      .then(blob => {
        // Convert the blob to CSV format
        const reader = new FileReader();
        reader.onload = function() {
          const text = reader.result;
          const csvData = convertToCSV(text);
  
          // Save the CSV file using the download attribute
          const link = document.createElement('a');
          link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
          link.download = 'db_dump.csv';
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        reader.readAsText(blob);
      })
      .catch(error => {
        console.error('Failed to download file:', error);
      });
  }
  

  function convertToCSV(data) {
    // Split the data into lines
    const lines = data.split('\n');
  
    // Extract the column headers from the first line
    const headers = lines[0].split(',');
  
    // Convert each line to a CSV row
    const csvRows = [];
    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(',');
      const csvRow = headers.map((header, index) => {
        const value = values[index];
        // Enclose the value in double quotes if it contains a comma
        if (value.includes(',')) {
          return `"${value}"`;
        }
        return value;
      });
      csvRows.push(csvRow.join(','));
    }
  
    // Add the header row to the CSV data
    csvRows.unshift(headers.join(','));
  
    // Combine all CSV rows into a single CSV string
    const csvData = csvRows.join('\n');
  
    return csvData;
  }

  
  return (
    <div className='d-flex flex-row justify-content-center flex-wrap mt-3'>
      <Link to={'/login/admin'}><div className='btn btn-sm btn-app-dark-blue mx-3'>התחברות כמשתמש</div></Link>
      <div className='btn btn-sm btn-app-dark-blue mx-3' onClick={() => downloadFile()}>משיכת מידע</div>
    </div>
  )
}

export default AdminDashboard