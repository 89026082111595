import React, { useEffect, useState } from 'react'
import './PriorityRequests.css'
import { Trans } from '../../../lang/Trans'
import { useWorkey } from '../../../WorkeyContext';
import { getCurrentWeek, isSmallScreen, makeApiRequest } from '../../../utils/utils';
import Navigation from '../display/tools/Navigation';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import Icon from '@mdi/react';
import { mdiAlertOctagon, mdiArrowDownBoldCircle, mdiArrowUpBoldCircle, mdiCancel, mdiCheck, mdiCheckDecagram } from '@mdi/js';
import UnavailableFeature from '../../blocks-ui/UnavailableFeature';

const PriorityRequests = () => {
    const SYSTEM_PRIORITY_REQUEST_DEFAULT_VALUE = 2

    const { globalState, setGlobalState } = useWorkey();
    const [currentWeek, setCurrentWeek] = useState(globalState.currentWeek || {})
    const [actAsUser, setActAsUser] = useState(globalState.user)
    useEffect(() => {
        getPageData()
    }, [globalState.weekRef])

    useEffect(() => {
        getUserRequestsData()
    }, [currentWeek?.days?.[0]?.date, globalState.user.id])


    // useEffect(() => {
    // getPageData(1)
    // }, [])

    const getUserValidations = (currentWeek, display = true) => {
        let user_settings = {}
        user_settings['min_shifts_requests'] = (actAsUser?.data?.settings.branch?.scheduling || {}).min_shifts_requests || (globalState.user?.branch?.data?.settings?.scheduling || {}).min_shifts_requests || 0
        user_settings['min_days_requests'] = (actAsUser?.data?.settings.branch?.scheduling || {}).min_days_requests || (globalState.user?.branch?.data?.settings?.scheduling || {}).min_days_requests || 0
        let tests = {}
        if (!currentWeek.shifts) {
            return null
        }
        let shift_requested_count = currentWeek.shifts?.filter(shift => {
            return shift.priority == 1 || shift.priority == 2
        }).length || 0
        tests['min_shifts_requests'] = shift_requested_count >= user_settings['min_shifts_requests']

        let day_requested_count = 0
        currentWeek.days?.forEach(day => {
            if (currentWeek.shifts.filter(shift => shift.day_id === day.id && shift.priority != 0).length > 0) {
                day_requested_count++
            }
        })
        tests['min_days_requests'] = day_requested_count >= user_settings['min_days_requests']

        if (display) {

            return Object.keys(user_settings).map(key => {
                let count = key == 'min_days_requests' ? day_requested_count : shift_requested_count
                return (
                    <div className='mt-1 d-flex flex-row align-items-center' key={key}>
                        <Icon path={tests[key] ? mdiCheckDecagram : mdiAlertOctagon} size={1} className={`mx-1 app-${tests[key] ? 'green' : 'red'}-txt`} />
                        <Trans keys={`scheduling_settings.${key}`} /> - ({count}/{user_settings[key]})</div>
                )
            })
        } else {
            return tests['min_days_requests'] && tests['min_shifts_requests']
        }
    }


    const getPageData = async (week_ref = globalState.weekRef) => {
        const week = await getCurrentWeek(globalState, setGlobalState, "GET", { type: "minimal", week_ref })
        if (week.days) {
            await setCurrentWeek(week)
        }
    }

    const getUserRequestsData = async () => {
        let user_requests = []
        // if (currentWeek?.slots) {
        //     currentWeek.slots = currentWeek?.slots.map(slot => {
        //         slot['priority'] = 0
        //         return slot
        //     })
        //     return user_requests
        // }
        if (!currentWeek?.id) {
            return user_requests
        }
        const req = await makeApiRequest(globalState, setGlobalState, `/user_requests/${globalState.user.id}/${currentWeek.id}`, "GET")
        const res = req[1]
        const data = req[0]
        if (res.status === 200) {
            if (data?.requests) {
                user_requests = data?.requests
                let new_current_week = { ...currentWeek }
                new_current_week.shifts = new_current_week.shifts.map(shift => {
                    let user_req = user_requests?.find(user_request => user_request.shift_id === shift.id)
                    shift['priority'] = user_requests.length > 0 ? user_req?.priority || 0 : SYSTEM_PRIORITY_REQUEST_DEFAULT_VALUE
                    return shift
                })
                await setCurrentWeek(new_current_week)
            }
        } else {
            console.error(res.error)
            toast.error(data.error ||<Trans keys={'server error'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
        }
    }

    const saveRequests = async () => {
        if (!getUserValidations(currentWeek, false)) {
            return toast.error(<Trans keys={'requests_page.errors.invalid_sumbition'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
        }
        let requests = currentWeek.shifts.filter(shift => shift.priority != 0).map(shift => {
            return { shift_id: shift.id, user_id: actAsUser.id, priority: shift.priority }
        })
        for (let index = 0; index < requests.length; index++) {
            requests[index]['day_index'] = currentWeek.days.findIndex(day => day.shifts.find(s => s.id === requests[index].shift_id))
            requests[index]['shift_index'] = currentWeek.days[requests[index]['day_index']].shifts.findIndex(s => s.id === requests[index].shift_id)
        }
        const req = await makeApiRequest(globalState, setGlobalState, `/user_requests/${currentWeek.id}`, "POST", { requests })
        if (req[1].status === 200 || req[1].status === 201) {
            toast.success(<Trans keys={'toast.updated.user_requests'} />);
        } else {
            toast.error(<Trans keys={'toast.failed_to_update.user_requests'} />);
        }
    }

    const toggelePriority = (shift) => {
        const updatedShifts = [...currentWeek?.shifts];
        let shiftIndex = currentWeek?.shifts.map(s => s.id).indexOf(shift.id);
        if (!updatedShifts[shiftIndex]) {
            return
        }
        if (updatedShifts[shiftIndex].priority === 2) {
            updatedShifts[shiftIndex].priority = 0
        } else {
            updatedShifts[shiftIndex].priority = updatedShifts[shiftIndex]?.priority + 1
        }
        setCurrentWeek({ ...currentWeek, shifts: updatedShifts });
    }

    if(!globalState.user.branch.active_subscriptions.find(subscription => subscription.subscription_type === 'menual_schedule')) {
        return <div>
            <UnavailableFeature/>
        </div>
    }

    const priorityIcons = [mdiCancel, mdiArrowUpBoldCircle, mdiArrowDownBoldCircle]
    return (
        <div className='priority-requests-contiainer'>
            <div className='card'>
                <div className='page-title'><Trans keys={'requests_page.title'} /></div>
                <Navigation location={'requests'} currentWeek={currentWeek} setActAsUser={setActAsUser} setCurrentWeek={setCurrentWeek} />
                <div className='requests-container'>
                    {!currentWeek.id && <div className='requests-no-schedule '>

                        <div className='fw-bolder card'>
                            <Trans keys={'requests_page.no_schedule'} />
                        </div>
                    </div>}
                    {currentWeek.id && currentWeek.days?.map((day, day_i) => {
                        return (
                            <div className={`text-center`} key={day.id}>
                                <div className='fw-bold'><Trans keys={`day_${day_i + 1}`} /> - {moment(day?.date).format('DD.MM')}</div>
                                <div className='request-day'>{currentWeek.shifts.filter(shift => shift.day_id === day.id).map((shift, shift_i) => {
                                    return (
                                        <span className="request-shift" key={shift.id}>
                                            <div className='d-flex flex-row'><Trans keys={'shift'} /> {shift_i + 1}</div>
                                            <div className={`priority btn priority-${shift.priority} text-center`} onClick={() => toggelePriority(shift)}>
                                                <Icon path={priorityIcons[shift.priority || 0]} size={1} className='text-light' />
                                            </div>
                                        </span>
                                    )
                                })}</div>
                            </div>
                        )
                    })}
                </div>
                <div className='text-center mb-2'>מקרא</div>
                <div className='request-user-guid'>
                    <div className={`priority btn priority-0 text-center fw-bold`}>
                        <Icon path={priorityIcons[0]} size={1} className='text-light' /> לא יכול לעבוד במשמרת זו
                    </div>
                    <div className={`priority btn priority-1 text-center fw-bold`}>
                        <Icon path={priorityIcons[1]} size={1} className='text-light' /> מעדיף לעבוד במשמרת זו
                    </div>
                    <div className={`priority btn priority-2 text-center fw-bold`}>
                        <Icon path={priorityIcons[2]} size={1} className='text-light' /> פנוי לעבוד במשמרת זו
                    </div>
                </div>
                <div className='requests-validations-checks'>
                    {getUserValidations(currentWeek)}
                </div>
                <div className='mt-3 d-flex flex-row justify-content-center'>
                    <div className='btn btn-app-green' onClick={() => saveRequests()}><Trans keys={"submit"} /></div>
                </div>
            </div>
        </div>
    )
}

export default PriorityRequests