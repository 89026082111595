import React from 'react'
import { Trans } from '../../lang/Trans'
import { useNavigate } from 'react-router-dom'

const UnavailableFeature = () => {
    const navigate = useNavigate()
    return (
        <div className='text-center m-5'>
            <div className='card p-5 fw-bold d-flex flex-column justify-content-center align-items-center'>
                <Trans keys={'plan.this_feature_is_not_available'} />
                <div className='btn btn-app-yellow mt-4' onClick={() => navigate('/settings/branch') }>לניהול החשבון</div>
            </div>
        </div>
    )
}

export default UnavailableFeature