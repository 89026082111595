import { includes } from "lodash";

class User {
  constructor(globalState) {
    this.state = globalState;
    this.user = globalState?.user;
    this.branch = globalState?.user?.brnach;
  }

  isBranchOwner(branch) {
    return branch?.owner_id == this.user.id
  }

  isCorporationOwner(corporation) {
    return corporation.id == this.user.corporation?.owner_id
  }

allowedTo(action) {
  const user = this.state.user;
  if(!user) return false;
  const authorizedActions = user?.data?.authorized_actions;
  const isSysAdmin = authorizedActions?.sys_admin;
  const isBranchOwner = this.isBranchOwner(user.branch);

  if (isSysAdmin) {
    return true;
  } else if (isBranchOwner) {
    if (action.includes('sys') || action.includes('own')) {
      return false;
    }
  }

  return authorizedActions?.[action] || authorizedActions?.['branch_manager'];
}

  isManagerOf(employee) {
    return this.allowedTo("manage_employee") && employee?.branch_id == this.user.branch_id && employee?.id != this.user.id
  }

  initialize_actions_list = {
    manage_schedule: false,
    view_schedule: false,
    manage_employee: false,
    own_corporation: false,
    own_branch: false,
    branch_manager: false,
    sys_admin: false,
  }

  initialize_data = {
    settings: {
      branch: {
        scheduling: this.initialize_scheduling_settings
      },
      user: {
        scheduling: this.initialize_scheduling_settings
      }
    },
    authorized_actions: this.initialize_actions_list
  }

  initialize_scheduling_settings = {
    min_assingments: {
      shifts: 0,
      days: 0
    },
    max_assignments: {
      shifts: 12,
      days: 6
    },
    min_requests: {
      shifts: 0,
      days: 0
    },
    max_requests: {
      shifts: 0,
      days: 0
    },
    week_end_requests: {
      shifts: 0,
      days: 0
    },
    allow_double_shifts: true,
    max_working_time: 0
  }

  status_list = {
    start: "setup",
    completed_user_setup: "user",
    joined_branch: "employee",
    started_employer_setup: "employer",
    completed_branch_setup: "branch_owner",
    completed_corporation_setup: "corporation_owner",
    normal: "active",
    fake: "fake"
  }

  userSetup = (user, create) => {
    if (create) {
      user = {}
    }

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    user['id'] = create ? null : user?.id || this.user.id
    user['username'] = create ? "" : user?.username ? capitalize(user?.username) : capitalize(this.state.user?.username);
    user['email'] ||= create ? user?.email : "";
    user['telegram_id'] ||= "";
    user['access_level'] ||= "basic";
    user['first_name'] = create ? "" : user?.first_name ? capitalize(user?.first_name) : capitalize(this.state.user?.first_name);
    user['last_name'] = create ? "" : user?.last_name ? capitalize(user?.last_name) : capitalize(this.state.user?.last_name);
    user['gender'] ||= create ? "" : user.gender || "unknown";
    user['mobile'] ||= "";
    user['branch_id'] ||= user?.branch_id || this.state.user?.branch?.id;
    user['birth_date'] ||= create ? "" : user?.birth_date || "";
    user['avatar'] ||= create ? "" : user?.avatar || "";
    user['goole_id'] ||= create ? "" : user?.google_id || "";
    user['status'] ||= create ? new User(user).status_list["fake"] : user?.status || new User(user).status_list["fake"];
    user['data'] ||= create ? new User().initialize_data : user?.data || this.state.user.data
    // await setCurrentUser(user)
    return user

  }

}

export default User;