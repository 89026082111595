import { toast } from "react-hot-toast"
import { makeApiRequest } from "./utils"
import { Trans } from "../lang/Trans"

export const getBranchData = async (globalState, setGlobalState, with_employees = false) => {
  let branch_id = globalState.user.branch_id
  const req = await makeApiRequest(globalState, setGlobalState, `/branch/${branch_id}`, "GET")
  const res = req[1]
  const branch = req[0]
  if (res.status !== 200 || !branch) {

    return toast.error(<Trans keys={'toast.feild_to_retrive.branch_data'} />);
    
  }
  if (with_employees) {
    const branchEmployeesReq = await makeApiRequest(globalState, setGlobalState, `/branch/${branch_id}/employees`, "GET")
    const res = branchEmployeesReq[1]
    const branch_employees = branchEmployeesReq[0]
    if (res.status !== 200 || !branch_employees) {
      return toast.error(<Trans keys={'toast.feild_to_retrive.employees_data'} />);
    }
    branch['employees'] = branch_employees || []

    branch['employees'] = branch['employees']?.map((employee) => {
      let emp_pos = branch['positions']?.filter(position => position?.user_id == employee?.id)
      let main_pos = emp_pos?.find(pos => pos?.user_id == employee?.id && pos.main)
      employee['main_role'] = branch['roles']?.find(role => role?.id == main_pos?.role_id)
      employee['roles'] = branch['roles']?.filter(role => emp_pos.filter(pos => !pos.main).map(pos => pos?.role_id).includes(role?.id))
      return employee
    })
  }

  return branch
}

// TODO sum all tips total_hours * wage_percent and save to a var, then check (tip.total_hours / thisVar) 
export const getHoursPortions = async (tips, total_tips, total_didaction) => {
  let total_hours = await getShiftTotalHours(tips);
  tips.forEach(tip => {
    tip.total_wage = ((((Number(tip.total_hours) * tip.wage_percent / 100)) / Number(total_hours)) * (Number(total_tips.total_wage) - Number(total_didaction.total_wage))).toFixed(2);
  });
  return tips;
}

const getShiftTotalHours = (tips) => {
  let total_hours = 0;
  tips.forEach(tip => {
    total_hours += (Number(tip.total_hours) * (Number(tip.wage_percent) / 100));
  });
  return total_hours;
}

export const MINIMUM_WAGE = 30.61

export const internationalNumber = (mobile, plus = true) => {
  if(!mobile)return false
  if (!mobile.startsWith("+972")) {
    mobile = mobile.replace(/^0/, "+972");
  }
  const isValidMobile = /^\+\d{1,3}\d{9}$/.test(mobile);
  if(isValidMobile) {
    if(!plus) {
      return mobile.replace("+", "")
    } else {
      return mobile
    }
  } else {
    return false
  }
}

export const isValidEmail = (email) => {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidMobile = (mobile) => {
  const isValid = /^05[0-9]{8}$/.test(mobile);
  return isValid;
};
