import { useState } from 'react';
import './BranchSettingsPage.css'
import { Link, useNavigate } from "react-router-dom";
import BranchPage from '../../settings/branch-page/BranchPage';
import EmployeesForm from './EmployeesForm';
import PlanPicker from '../../plan-picker/PlanPicker';
import RolesForm from './RolesForm';
import { isSmallScreen } from '../../../utils/utils';
import { trans } from '../../../lang/translation';
import { useWorkey } from '../../../WorkeyContext';
import BranchEmployeesSettingsForms from '../../settings/branch-page/BranchEmployeesSettingsForms';
const BranchSettingsPage = () => {
  const { globalState, setGlobalState } = useWorkey();
  const [showCard, setShowCard] = useState(false);
  const navigate = useNavigate()

  const renderContent = () => {
    switch (showCard) {
      case 'branch':
        return <div className='branch-page-render mt-3'><BranchPage /></div>
      case 'employees':
        return <div className='branch-employees-settings-render'>
          {/* <div className='page-not-for-mobile'>{trans(globalState, 'page_not_for_mobile')}</div> */}
          <BranchEmployeesSettingsForms />
        </div>
      default:
        return <div className='mt-5 fw-bold d-flex justify-content-center'>
          {trans(globalState, 'not_selected')}
        </div>
    }
  }

  return <div className="branch-settings-page">
    <div className="page-body card">
      <div className="text-center my-1 fs-5 fw-bold">הגדרות עסק</div>
      <div className="branch-settings-actions mx-1 mt-3">
        {/* <div onClick={() => setShowCard(showCard === '' ? false : '')} className="btn btn-app-blue">הגדרות שיבוץ</div> */}
        {/* <div onClick={() => setShowCard(showCard === 'roles' ? false : 'roles')} className="btn btn-app-dark-blue">ניהול תפקידים</div> */}
        {/* <div onClick={() => setShowCard(showCard === 'wages' ? false : 'wages')} className="btn btn-app-green">הגדרות שכר</div> */}
        <div onClick={() => setShowCard(showCard === 'employees' ? false : 'employees')} className="btn btn-app-light-blue">ניהול עובדים</div>
        <div onClick={() => navigate('/settings/branch/account')} className="btn btn-app-yellow">ניהול חשבון</div>
        <div onClick={() => setShowCard(showCard === 'branch' ? false : 'branch')} className="btn btn-app-blue">פרטי עסק</div>
      </div>
      <div className='branch-settings-content'>
        {renderContent()}
      </div>

    </div>
  </div>;
}
export default BranchSettingsPage;

