import React, { useState } from 'react'
import { Trans } from '../../lang/Trans'
import { useWorkey } from '../../WorkeyContext'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NoSchedule.css';
import { getCurrentWeek } from '../../utils/utils';
import User from '../../utils/user_utils';
import ScheduleSetupFlow from './schedule-setup-flow/ScheduleSetupFlow';

const NoSchedule = ({ edit, setCurrentWeek }) => {
    const { globalState, setGlobalState } = useWorkey()
    const [scheduleSetup, setScheduleSetup] = useState(false)
    const userUtils = new User(globalState)
    const navigate = useNavigate()
    const createNewSchedule = async () => {
        if (!globalState.currentWeek?.id) {
            const new_week = await getCurrentWeek(globalState, setGlobalState, "POST")
            await setGlobalState({ ...globalState, currentWeek: { ...new_week } })
            await setCurrentWeek({ ...new_week })
        }
        // callback(new_week.days)
        navigate('/schedule/edit')
    }

    const createBtn = () => {
        if (!userUtils.allowedTo("manage_schedule")) {
            return null
        } else {
            if (globalState.user.branch?.roles.length < 1 || !globalState.user.branch?.data?.week_structure?.days) {
                return <div className='mt-5 btn btn-success' onClick={() => setScheduleSetup(true)}>
                    <Trans keys={"create_schedule"} />
                </div>
            } else {
                return <div className='mt-5 btn btn-success' onClick={createNewSchedule}>
                    <Trans keys={globalState.currentWeek?.id ? "schedule_page.edit_schedule" : "create_schedule"} />
                </div>
            }
        }
    }

    const endSetup = () => {
        setScheduleSetup(false)
        createNewSchedule()
    }

    return (
        <>{scheduleSetup ? <ScheduleSetupFlow endSetup={endSetup} />
            :
            <div className='d-flex flex-row justify-content-center'>
                <div className='mt-2 fw-bolder card p-5 no-schedule'>

                    <div>
                        <Trans keys={"no_schedule"} />
                    </div>
                    {createBtn()}
                </div>
            </div>
        }</>)
}

export default NoSchedule