import React, { useState } from 'react'
import { useWorkey } from '../../../WorkeyContext'
import ProfileInfoCard from '../profile/profile-info-card/ProfileInfoCard'
import ProfileSchedulingSettings from '../profile/profile-scheduling-settings/ProfileSchedulingSettings'
import ProfileAvatarCard from '../profile/profile-avatar-card/ProfileAvatarCard'
import './UserEmployee.css'
import ProfileAuthorizedActionsCard from '../profile/profile-authorized-actions-card/ProfileAuthorizedActionsCard'
import User from '../../../utils/user_utils'
import ProfileActionsCard from '../profile/ProfileActionsCard'
import ProfileWagesCard from '../profile/profile-wages-card/ProfileWagesCard'
import PageNotFound from '../../page_not_found/PageNotFound'
import { Trans } from '../../../lang/Trans'
const UserEmployee = ({ USER_ID }) => {
    const { globalState, setGlobalState } = useWorkey()
    const userUtils = new User(globalState);
    const [cardsDisplay, setCardsDisplay] = useState(['general'])
    const toggleCardsDisplay = (card_name) => {
        let new_cards_display
        if (cardsDisplay.includes(card_name)) {
            new_cards_display = cardsDisplay.filter((c) => c !== card_name)
        } else {
            new_cards_display = [...cardsDisplay, card_name]
        }
        setCardsDisplay(new_cards_display)
    }
    const employee = globalState.user.branch?.employees.find((e) => e.id == USER_ID)
    if (!employee) {
        return <PageNotFound />
    }
    return (
        <div className='user-employee-page-layout'>
            <div className='user-sections'>
                <div className='top-sections'>
                    <div className='profile-info-container'>
                        <ProfileInfoCard
                            showEdit={globalState.user.id === employee?.id || employee?.status === "fake"}
                            user={employee}
                        />
                    </div>
                    <div className={`profile-avatar-container card`}>
                        <div>
                            <ProfileAvatarCard
                                avatarType={'avatar'}
                                user={employee}
                                showEdit={false}
                            />
                        </div>
                    </div>
                </div>
                {userUtils.allowedTo('manage_employee') && <div className='actions-section'>
                    <div className='card'>
                        <ProfileActionsCard toggleCardsDisplay={toggleCardsDisplay} cardsDisplay={cardsDisplay} />
                    </div>
                </div>}
                {(userUtils.allowedTo('manage_employee') || userUtils.allowedTo('manage_schedule')) && <div className='manegers-sections'>
                    {cardsDisplay.includes('wages') && userUtils.allowedTo('manage_employee') && <div className='card manage-wages'>
                        <ProfileWagesCard user={employee} />
                    </div>}
                    {cardsDisplay.includes('scheduling') && userUtils.allowedTo('manage_schedule') && <div className='card manage-scheduling'>
                        <ProfileSchedulingSettings
                            showEdit={true}
                            user={employee}
                        />
                    </div>}
                    {cardsDisplay.includes('authorized_actions') && userUtils.allowedTo('manage_employee') && <div className='card manage-authorizations'>
                        <ProfileAuthorizedActionsCard
                            showEdit={true}
                            user={employee}
                        />
                    </div>}
                    {cardsDisplay.includes('general') && userUtils.allowedTo('manage_employee') && <div className='card general-actions'>
                        <div>
                            <div className='text-center mb-2'><Trans keys={'general_actions'} /></div>
                            <div className='d-flex flex-row justify-content-center'>
                                <div className='btn btn-sm btn-app-red'>
                                    {employee.status === 'fake' ? 'מחק עובד' : 'נתק משתמש מהסניף'}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>}
            </div>
        </div >
    )
}

export default UserEmployee