import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useWorkey } from '../../../../WorkeyContext';
import { Trans } from '../../../../lang/Trans';
import { mdiCancel, mdiCheckBold, mdiTrashCan } from '@mdi/js';
import Icon from '@mdi/react';
import { makeApiRequest } from '../../../../utils/utils';
import { toast } from 'react-hot-toast';

const NotMainRoleManage = ({ currentUser }) => {
    const { globalState, setGlobalState } = useWorkey();
    const [editMode, setEditMode] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleRoleSelection = (e) => {
        setSelectedRole(globalState.user.branch.roles.find(role => role.id == e.target.value))
    }
    const handleSave = async () => {
        let position = {
            branch_id: globalState.user.branch.id,
            main: false,
            role_id: selectedRole.id,
            user_id: currentUser.id,
        }
        const req = await makeApiRequest(globalState, setGlobalState, `/positions`, 'POST', position);
        const data = req[0];
        const res = req[1];
        if (res.status == 201) {
            let state = { ...globalState };
            state.user.branch.positions = [...state.user.branch.positions || [], data];
            let employee = state.user.branch.employees.find((emp) => emp.id == currentUser.id)
            let employeeIndex = state.user.branch.employees.findIndex((emp) => emp.id == employee.id);

            let emp_pos = state.user.branch.positions.filter(pos => pos.user_id == employee.id && !pos.main)
            employee['roles'] = emp_pos.map(pos => state.user.branch['roles'].find(role => role?.id == pos.role_id))
            state.user.branch.employees[employeeIndex] = employee
            if (employee.id == globalState.user.id) {
                state.user['roles'] = employee.roles
            }
            setGlobalState(state);
            setEditMode(false);
            toast.success(<Trans keys={'toast.record_saved'} />);
        } else {
            toast.error(<Trans keys={'toast.server_error'} />);
        }

    }


    const handleRoleDeletion = async (roleId) => {
        let pos = globalState.user.branch.positions?.find((position) => position.role_id == roleId && position.user_id == currentUser.id && !position.main);
        if (!pos) {
            toast.error(<Trans keys={'toast.no_position'} />);
        } else {
            const req = await makeApiRequest(globalState, setGlobalState, `/positions/${pos.id}`, 'DELETE');
            const res = req[1];
            if (res.status == 204) {
                let state = { ...globalState };
                state.user.branch.positions = state.user.branch.positions.filter(position => position.id != pos.id);
                let employeeIndex = state.user.branch.employees.findIndex((employee) => employee.id == currentUser.id);
                let employee = state.user.branch.employees[employeeIndex];
                employee['roles'] = state.user.branch.positions.filter((position) => position.id != pos.id && position.user_id == currentUser.id && !position.main).map((position) => globalState.user.branch.roles.find((role) => role.id == position.role_id));
                state.user.branch.employees[employeeIndex] = employee;
                state.user.branch.employees[employeeIndex] = employee;
                if (employee.id == globalState.user.id) {
                    state.user['roles'] = employee.roles
                }
                setGlobalState(state);
                toast.success(<Trans keys={'toast.record_deleted'} />);
            } else {
                toast.error(<Trans keys={'toast.server_error'} />);
            }
        }
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    return (
        <div>
            {editMode ? (
                <Form.Group>
                    <Form.Label>בחירת תפקיד</Form.Label>
                    <Form.Control as="select" onChange={handleRoleSelection}>
                        <option value={''}>{<Trans keys={'select'} />}</option>
                        {globalState.user.branch.roles?.filter(
                            (role) =>
                                role.id !== currentUser.main_role?.id &&
                                !currentUser.roles?.map((r) => r?.id).includes(role.id)
                        )
                            .map((role) => (
                                <option key={role.id} value={role.id} selected={selectedRole?.id === role.id}>
                                    {role.name}
                                </option>
                            ))}
                    </Form.Control>
                    <div className="d-flex flex-row justify-content-center mt-2">
                        <Icon
                            path={mdiCheckBold}
                            size={1}
                            className="app-green-txt pointer mx-2"
                            onClick={handleSave}
                        />
                        <Icon
                            path={mdiCancel}
                            size={1}
                            className="app-red-txt pointer mx-2"
                            onClick={handleCancel}
                        />
                    </div>
                </Form.Group>
            ) : (
                <>
                    <div className="d-flex flex-column justify-content-center my-2">
                        <div className='text-center'>{currentUser.roles?.length < 1 && <Trans keys={'user_page.no_roles_selected'} />}</div>
                        {currentUser.roles?.map((role) => (
                            <div className="text-center" key={role.id}>
                                {role?.name}
                                <Icon
                                    path={mdiTrashCan}
                                    className="me-2 app-red-txt pointer"
                                    size={1}
                                    onClick={() => handleRoleDeletion(role.id)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                        <div className="btn btn-sm btn-app-blue" onClick={toggleEditMode}>
                            <Trans keys={'user_page.edit_roles'} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default NotMainRoleManage;

