import EN from "./en";
import HE from "./he";

export const trans = (globalState, keys) => {
    let local = globalState?.local || 'he';
    let lang;
    
    switch (local) {
        case 'en':
            lang = EN;
            break;
        default:
            lang = HE;
            break;
    }

    keys = keys.split(".");
    let translation = keys.reduce((obj, key) => obj[key], lang);
    return translation || "missing: " + keys;
}
