import React, { useEffect, useState } from 'react'
import './TopNavbar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { isNewUser, logout, userDisplayName } from '../../utils/utils';
import { Link } from "react-router-dom";
import { Trans } from '../../lang/Trans';
import { useWorkey } from '../../WorkeyContext';
import Icon from '@mdi/react';
import { mdiEarth, mdiHelpBox } from '@mdi/js';
import User from '../../utils/user_utils';

const TopNavbar = () => {
  const { globalState, setGlobalState } = useWorkey()
  const [globalUser, setGlobalUser] = useState(globalState.user)
  const userUtils = new User(globalState)
  useEffect(() => {
    setGlobalUser(globalState.user)
  }, [globalState.user?.avatar, globalState.user?.id, globalState?.user?.status])

  const closeMenu = () => {
    // Close the menu by toggling the navbar collapse state
    const navbarCollapse = document.getElementById('responsive-navbar-toggle');
    navbarCollapse.click();
  };

  const displaySchedule = () => {
    return globalUser?.id && (globalUser.data?.authorized_actions?.view_schedule || globalUser.data?.authorized_actions?.manage_schedule) && (
      globalUser.data?.authorized_actions?.branch_manager || globalUser.branch.plan_data.menual_schedule
    )
  }

  return (
    <div className='top-navbar-container'>
      <Navbar className="top-navbar" expand="lg" variant="dark">
        <Nav.Item>
          <Link to={'/'}>
            <Navbar.Brand className='brand-name mx-4 fw-bold fs-4'>
              Workey
              {/* <img src='logo.svg' style={{width: '10%'}} alt='logo' /> */}
            </Navbar.Brand>
          </Link>
        </Nav.Item>
        <Navbar.Toggle id="responsive-navbar-toggle" aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`${globalState.local === 'he' ? 'ms' : 'me'}-auto`}>
            {displaySchedule() && <Nav.Item id={`schedule-nav-item`}>
              <Link to={`/schedule${userUtils.allowedTo("manage_schedule") ? '/edit' : ''}`} className="nav-link" onClick={closeMenu}>
                <Trans keys={"schedule"} />
              </Link>
            </Nav.Item>}
            {globalUser?.id && (globalUser.data?.authorized_actions?.manage_schedule || globalUser.data?.authorized_actions?.manage_employee) && <Nav.Item>
              <Link to={`/employees`} className="nav-link" onClick={closeMenu}>
                <Trans keys={"employees"} />
              </Link>
            </Nav.Item>
            }
            {globalUser?.id &&
              <Nav.Item id={`settings-nav-item`}>
                <Link to={`/settings${userUtils.allowedTo("branch_manager") ? '/branch' : '/user'}`} className="nav-link" onClick={closeMenu}>
                  <Trans keys={"settings"} />
                </Link>
              </Nav.Item>
            }
            <Nav.Item id={`contact-us-nav-item`}>
              <Link to={`/contact-us`} className="nav-link" onClick={closeMenu}>
                <Trans keys={"contact_us.title"} />
              </Link>
            </Nav.Item>
            {userUtils.allowedTo('sys_admin') ?
              <Nav.Item>
                <Link to={`/admin`} className="nav-link">
                  אדמין
                </Link>
              </Nav.Item>
              : <></>}
          </Nav>
          <Nav className='mx-5'>
            {!globalUser?.id ?
              <Nav.Item>
                <Link to={`/login`} className="nav-link" onClick={closeMenu}>
                  <Trans keys={"login.title"} />
                </Link>
              </Nav.Item>
              : <></>}
            {/* <NavDropdown title={<Icon title={'change language'} path={mdiEarth} size={1} />} id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={() => { setGlobalState({ ...globalState, local: 'he' }); closeMenu(); }}>עברית</NavDropdown.Item>
              <NavDropdown.Item onClick={() => { setGlobalState({ ...globalState, local: 'en' }); closeMenu(); }}>English</NavDropdown.Item>
            </NavDropdown> */}

            {globalState.user?.branch_id && <Nav.Item id="begin-tutorial-nav-item" onClick={() => setGlobalState({ ...globalState, isTourOpen: 'start' })}>
              <Icon path={mdiHelpBox} size={1} className='text-light mt-2 pointer'/>
            </Nav.Item>}
            {globalUser?.id ?
              <Nav.Item>
                <Link to={`/user/${globalUser.id}`} className="nav-link" onClick={closeMenu}>
                  <span id="tutorial-start" />
                  <Trans keys={"hello"} />, {userDisplayName(globalUser)}
                </Link>
              </Nav.Item>
              : <></>}
            {globalUser?.id ? <Nav.Item><Nav.Link onClick={() => { logout(); }}>
              <Trans keys={"logout"} />
            </Nav.Link></Nav.Item> : <></>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}
export default TopNavbar  
