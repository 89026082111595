import React, { useState } from 'react'
import './MainRoleManage.css'
import { useWorkey } from '../../../../WorkeyContext'
import User from '../../../../utils/user_utils'
import { Trans } from '../../../../lang/Trans'
import Icon from '@mdi/react'
import { mdiCancel, mdiPencil } from '@mdi/js'
import Select from 'react-select';
import State from '../../../../utils/current_week_utils'
import { makeApiRequest } from '../../../../utils/utils'
import './MainRoleManage.css'
import { toast } from 'react-hot-toast'
const MainRoleManage = ({ user, showEdit = true, startWithEdit, setEditMainRoleForUserId }) => {
    const { globalState, setGlobalState } = useWorkey()
    const userUtils = new User(globalState)

    const [editMode, setEditMode] = useState(startWithEdit)
    const [dataToSave, setDataToSave] = useState({ positions: [] });

    const user_selected_default = () => {
        return { value: user.main_role?.id, label: user.main_role?.name, selected: true }

    }
    const roles_options = () => {
        let roles = globalState.user.branch?.roles
        return roles.map(role => {
            return { value: role.id, label: role.name }
        })
    }

    const selectMainRole = async (e) => {
        let employee = { ...user }
        let old_main_position_index = employee.positions?.findIndex(p => p.main)
        let old_non_main_position_index = employee.positions?.findIndex(p => p.role_id == e.value && !p.main)
        let selected_role = globalState.user.branch?.roles.find(role => role.id == e.value)
        if (old_main_position_index > -1) {
            delete employee.positions[old_main_position_index]
        } else if (old_non_main_position_index > -1) {
            old_non_main_position_index.main = true
            employee.main_position = old_non_main_position_index
        }
        employee['main_position'] = {
            user_id: employee.id,
            role_id: selected_role.id,
            branch_id: employee.branch_id,
            main: true
        }
        employee['positions'] ||= []
        employee['positions'].push(employee['main_position'])

        employee.main_role = selected_role
        savePositions(employee)
    }


const savePositions = async (employee) => {
    if (employee.positions?.length > 0) {
        const req = await makeApiRequest(globalState, setGlobalState, `/user/${employee.id}/positions/`, "POST", { positions: employee?.positions || [] });
        const data = req[0]
        const res = req[1]
        if (res.status === 200) {
            toast.success(<Trans keys={'toast.record_saved'} />);
            let state = { ...globalState }
            state.user.branch.positions = data
            if (state.user.id === employee.id) {
                state.user.main_position = employee.main_position
                state.user.main_role = employee.main_role
            } else {
                let employeeIndex = state.user.branch?.employees.findIndex(emp => emp.id === employee.id)
                state.user.branch.employees[employeeIndex] = employee
            }
            // delete state.user.branch
            setGlobalState(state)
            // } else {

            // }
            // window.location.reload()
            setEditMode(false);
            if (setEditMainRoleForUserId) {
                setEditMainRoleForUserId(null)
            }
        } else {
            toast.error(data.error ||<Trans keys={'toast.feild_to_update.role'} />);        
        }
    }
};

const canChangeMainRole = () => {
    if (userUtils.isBranchOwner(globalState.user.branch)) {
        return true
    } else if (globalState.user.id !== user.id && userUtils.allowedTo('manage_employee')) {
        return true
    }
    return false
}


return (
    <div className='main-role-display w-100'>
        <div className='d-flex flex-row justify-content-center'>
            {!editMode && <div className='user-main-role-title app-light-blue-txt'>{user.main_role?.name || <Trans keys={'general_employee'} />}</div>}
            {(showEdit && !editMode && canChangeMainRole()) && <Icon path={mdiPencil} size={1} className='pointer app-blue-txt me-3' onClick={() => setEditMode(true)} />}
        </div>
        {canChangeMainRole() && <div className='main-role-select-container'>
            {editMode && <div className='main-role-select d-flex flex-row justify-content-around align-items-center'>
                <Select
                    defaultValue={user_selected_default()}
                    name="main_role"
                    options={roles_options()}
                    className="w-100 mx-3"
                    // classNamePrefix="select"
                    onChange={(e) => selectMainRole(e)}
                />
                {!startWithEdit && <Icon path={mdiCancel} size={1} className='pointer app-red-txt' onClick={() => setEditMode(false)} />}
                {/* <div className='me-3'>
                    </div> */}
            </div>}
        </div>}
    </div>
)
}

export default MainRoleManage