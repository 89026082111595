// WorkeyContext.js
import { createContext, useCallback, useContext, useReducer, useEffect } from "react";
import workeyReducer, { initialGlobalState } from "./workeyReducer";
import  secureLocalStorage  from  "react-secure-storage";

export const WorkeyContext = createContext(null); // pass null instead of initialGlobalState

export const WorkeyProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(workeyReducer, initialGlobalState);

  useEffect(() => {
    let initial_global_state = {...initialGlobalState};
    setGlobalState(initial_global_state);
  }, [initialGlobalState]);

  const setGlobalState = (newState) => {
    // newState.slots = newState?.slots?.map(slot => {
    //   delete slot['shift'];
    //   delete slot['day'];
    // })

    if (newState.user) {
      secureLocalStorage.removeItem(`${process.env.NODE_ENV}WorkeyUser`)
      secureLocalStorage.setItem(`${process.env.NODE_ENV}WorkeyUser`, JSON.stringify(newState.user))
    }
    dispatch({ type: "UPDATE_GLOBAL_STATE", payload: newState });
  };
  const setGlobalUser = (newUser) => {
    setGlobalState({ ...globalState, user: newUser });
  };

  const setTutorial = (tutorialData) => {
    setGlobalState({ ...globalState, tutorial: tutorialData });
  };


  const setLoading = (status) => {
    setGlobalState({ ...globalState, loading: status });
  };

  const value = {
    globalState: globalState,
    dispatch: dispatch,
    setTutorial: setTutorial,
    setGlobalState: setGlobalState,
    setGlobalUser: setGlobalUser,
    setLoading: setLoading,
  };
  return (
    <WorkeyContext.Provider value={value}>{children}</WorkeyContext.Provider>
  );
};

export const useWorkey = () => {
  const context = useContext(WorkeyContext);

  if (context === undefined) {
    throw new Error("useWorkey must be used within a WorkeyProvider");
  }

  return context;
};
