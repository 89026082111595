import { useState } from 'react'
import './Toolsbox.css'
import { initialTools, toolsSettings } from './tools_utils'
import { Trans } from '../../../../lang/Trans'

const Toolsbox = ({ tools, setTools }) => {
    const [position, setPosition] = useState({ clientY: null  })


const toggleTool = (name) => {
    let tools_init = name === 'slot_status_off' ? {...tools} : { ...initialTools }
    const newTools = { ...tools_init, [name]: !tools[name] };
    setTools(newTools);
}
    const toolsBtns = () => {
        if (!toolsSettings) { return <></> }
        return Object.keys(toolsSettings).map((tool_name, tool) => {
            let trans_name = tool_name 
            let class_name = tools[tool_name] ? "selected" : ""
            let tool_color = toolsSettings[tool_name].color
            if(trans_name === 'slot_status_off') {
                class_name = ''
                if(!!tools[tool_name]) {
                    tool_color = 'app-blue'
                    trans_name = 'slot_status_on'
                }
            }

            return (
                <div key={tool_name} className={`btn btn-sm ${class_name} btn-${tool_color}`} onClick={() => toggleTool(tool_name)}>
                    <Trans keys={trans_name} />
                </div>
            )
        })
    }
    return (
        // setPosition({ clientY: e.clientY > 750 ? 750 : e.clientY })
        <div draggable={false} style={{ top: position.clientY }} onDragEnd={(e) => {}} className="toolsbox-menu">
            {toolsBtns()}
        </div>
    )
}

export default Toolsbox