import React from 'react'

const SubmittedRequests = ({callback, selected}) => {
    return (
        <>
        <div>
            X עובדים מתוך Y הגישו משמרות לשבוע שמתחיל בתאריך DD.MM.YY
        </div>
          
        </>
    )
}

export default SubmittedRequests