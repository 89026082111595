import React, { useEffect, useState } from 'react'
import { displayTime, makeApiRequest } from '../../../utils/utils'
import { useWorkey } from '../../../WorkeyContext'
import { Form, Button } from 'react-bootstrap';

import './Tips.css'
import SlotTip from './SlotTip';
import { toast } from 'react-hot-toast';
import { Trans } from '../../../lang/Trans';
import { getHoursPortions } from '../../../utils/data_utils';
import { employeePositions } from '../../../utils/scheduling_utils';

const Tips = ({ week, setShowTips }) => {
    const { globalState, setGlobalState } = useWorkey()
    const [currentWeek, setCurrentWeek] = useState(week)
    const [tips, setTips] = useState([])
    const [shiftTotal, setShiftTotal] = useState([])
    const [selectedShift, setSelectedShift] = useState(null)
    const [selectedDay, setSelectedDay] = useState(null)
    const [totalDidaction, setTotalDidaction] = useState(null)
    const [showPublishBtn, setShowPublishBtn] = useState(false)
    const handleShiftChange = async (event) => {
        const shiftId = event.target.value
        let shift = currentWeek.shifts?.find(shift => shift.id == shiftId)
        if (!shift) {
            return
        }
        await setSelectedShift(shift)
        let selected_day = currentWeek?.days.find(day => day.id == shift.day_id)
        if (!selected_day) {
            return toast.error('error')
        }
        await setSelectedDay(selected_day)

        // Make API request to fetch tips for the selected shift
        const req = await makeApiRequest(globalState, setGlobalState, `shifts/${shiftId}/tips`, 'GET')
        const data = req[0]
        const res = req[1]
        let new_tips = []
        if (res.status === 200 || res.status === 201) {
            let shift_total = data.find(tip => tip.user_id == null && tip.slot_id == null && tip.shift_id == shiftId && tip.wage_type === 'tips')
            if (!shift_total) {
                shift_total = {
                    total_wage: 0,
                    user_id: null,
                    shift_id: shift.id,
                    slot_id: null,
                    total_hours: 0,
                    wage_type: 'tips',
                    paid: false,
                    date: selected_day.date,
                    wage_percent: 100
                }
            }
            let total_didaction = data.find(tip => tip.shift_id == shiftId && tip.wage_type === 'didaction')
            if (!total_didaction) {
                total_didaction = {
                    total_wage: 0,
                    user_id: null,
                    shift_id: shift.id,
                    slot_id: null,
                    total_hours: null,
                    wage_type: 'didaction',
                    paid: true,
                    date: selected_day.date,
                    wage_percent: 100
                }
            }
            await setTotalDidaction(total_didaction)
            shift.slots.filter(slot => !!slot.user_id).forEach(slot => {
                let position = employeePositions(globalState, slot.user_id).find(position => position.role_id == slot.role_id && position.main)
                let tip = data.find(tip => tip.user_id == slot.user_id && tip.slot_id == slot.id && tip.wage_type === 'tips')
                if (!tip) {
                    tip = {
                        total_wage: 0,
                        user_id: slot.user_id,
                        shift_id: shift.id,
                        slot_id: slot.id,
                        total_hours: slot.total_hours,
                        wage_type: 'tips',
                        paid: false,
                        date: selected_day.date,
                        wage_percent: position?.wage_percent == 0 ? position?.wage_percent : position?.wage_percent || 100
                    }
                }
                new_tips.push(tip)
            });
            shift_total.total_hours = shift.slots.filter(slot => slot.user_id != null).reduce((total, slot) => total + slot.total_hours, 0)
            // if (shift_total.total_wage > 0) {
            //     new_tips = await getHoursPortions(new_tips, shift_total, total_didaction)
            // }
            await setShiftTotal(shift_total)
            setTips(new_tips)
        } else {
            toast.error(<Trans keys={'server_error'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
        }
    }


    const recalculateTips = async () => {
        let new_tips = await getHoursPortions(tips, shiftTotal, totalDidaction)
        await setShowPublishBtn(true)
        return setTips(new_tips)
    }

    const handleTotalTipsChange = async (event) => {
        await setShiftTotal({ ...shiftTotal, total_wage: Number(event.target.value) })
        setShowPublishBtn(false)

    }

    const handleSaveTotals = async (show_toast = true) => {
        let total_method = shiftTotal.id ? 'PUT' : 'POST'
        let total_path = shiftTotal.id ? `/wages/${shiftTotal.id}` : `/wages`
        const total_req = await makeApiRequest(globalState, setGlobalState, total_path, total_method, shiftTotal)
        const total_res = total_req[1]
        if (total_res.status === 200 || total_res.status === 201) {
            let didact_method = totalDidaction.id ? 'PUT' : 'POST'
            let didact_path = totalDidaction.id ? `/wages/${totalDidaction.id}` : `/wages`
            const didact_req = await makeApiRequest(globalState, setGlobalState, didact_path, didact_method, totalDidaction)
            const didact_res = didact_req[1]
            if (didact_res.status === 200 || didact_res.status === 201) {
                show_toast && toast.success(<Trans keys={'tips.success.total_and_didactions_saved'} />)
                return true
            } else {
                toast.error(<Trans keys={'server_error'} /> - <Trans keys={'for_total_didactions'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
            }
        } else {
            toast.error(<Trans keys={'server_error'} /> - <Trans keys={'for_total_tips'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
        }
        return false
    }

    const renderTips = () => {
        return tips.map((tip, tip_num) => {
            let slot = currentWeek.slots.find(slot => slot.id == tip.slot_id)
            return <SlotTip tip_num={tip_num}
                tips={tips}
                slot={slot}
                shift={selectedShift}
                shiftTotal={shiftTotal}
                day={selectedDay}
                tip={tip}
                key={`slot_${tip_num}`} />
        })

    }

    const publishTips = async () => {
        let saved = await handleSaveTotals(false)
        let wages = [...tips]
        if (saved) {
            const req = await makeApiRequest(globalState, setGlobalState, `/wages/publish`, 'POST', wages)
            const res = req[1]
            if(res.status === 200 || res.status === 201) {
                setShowTips(false)
            } else {
                toast.error(<Trans keys={'server_error'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
            }
        }

    }

   
    return (
        <div className='tips-container'>
            <div className='card'>
                <div className='title'><Trans keys={'tips.title'} /></div>
                <Form.Group className="shift-select" controlId='shiftSelect'>
                    <Form.Control as='select' onChange={handleShiftChange}>
                        <option value=''>-- <Trans keys={'select'} /> --</option>
                        {currentWeek?.days.map((day, day_num) => (
                            day.shifts.map((shift, shift_day_num) => (
                                <option key={shift.id} value={shift.id}>
                                    <Trans keys={`day`} /> <Trans keys={`day_${day_num + 1}`} /> <Trans keys={'shift'} /> {shift_day_num + 1}
                                </option>
                            ))
                        ))}
                    </Form.Control>
                </Form.Group>

                {selectedShift && (
                    <>
                        <Form.Group className='total-tips' controlId='totalTipsInput'>
                            <Form.Label><Trans keys={'tips.total'} /></Form.Label>
                            <Form.Control
                                type='number'
                                value={shiftTotal.total_wage}
                                onChange={handleTotalTipsChange}
                            />
                            <Form.Label><Trans keys={'tips.didactions'} /></Form.Label>
                            <Form.Control
                                type='number'
                                value={totalDidaction?.total_wage}
                                onChange={(e) => setTotalDidaction({ ...totalDidaction, total_wage: Number(e.target.value) })}
                            />
                            <div className="d-flex justify-content-center">
                                <Button variant='app-green' className='mt-2' onClick={handleSaveTotals}>
                                    <Trans keys={'save'} />
                                </Button>
                            </div>
                            <Form.Label className='mt-1'><Trans keys={'tips.total_time'} />: {displayTime(shiftTotal.total_hours)}</Form.Label>
                            <div className='d-flex justify-content-center'>
                                <Button variant='app-light-blue' className='mt-1' onClick={recalculateTips}>
                                    <Trans keys={'tips.recalculate'} />
                                </Button>
                            </div>
                        </Form.Group>
                        <div className='tips-list-container'>
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"><Trans keys={'name'} /></th>
                                        <th scope="col"><Trans keys={'role'} /></th>
                                        <th scope="col"><Trans keys={'wage_percent'} /></th>
                                        <th scope="col"><Trans keys={'total_work_time'} /></th>
                                        <th scope="col"><Trans keys={'total_per_hour'} /></th>
                                        <th scope="col"><Trans keys={'total'} /></th>
                                        {/* <th scope="col"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTips()}
                                </tbody>
                            </table>
                        </div>


                    </>
                )}
                <div className='mt-2'>
                    <div className='mx-2 btn btn-app-red' onClick={() => setShowTips(false)}><Trans keys={'cancel'} /></div>
                    {showPublishBtn && <div className='mx-2 btn btn-app-green' onClick={publishTips}><Trans keys={'tips.publish'} /></div>}
                </div>
            </div>
        </div >
    );
}
export default Tips


