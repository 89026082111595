import React from 'react'
import { Trans } from '../../lang/Trans'

const Unauthorized = () => {
    return (
        <>
         <div className='unauthorized p-5'>
             <div className='card fw-bold m-5 p-5 d-flex flex-row justify-content-center'><Trans keys={"not_authorized_for_this_page"} /></div>
         </div>
        </>
    )
}

export default Unauthorized