import React from 'react'
import { isSmallScreen } from '../../../utils/utils'
import { trans } from '../../../lang/translation'
import { useWorkey } from '../../../WorkeyContext';
import RolesForm from '../../settings-pages/branch/RolesForm';
import EmployeesForm from '../../settings-pages/branch/EmployeesForm';

const BranchEmployeesSettingsForms = () => {
    const { globalState, setGlobalState } = useWorkey();
    return <>
        <div className=''>
            {/* <div>
                <div className='btn btn-sm btn-app-blue mx-2'>תפקידי עובדים</div>
                <div className='btn btn-sm btn-app-blue mx-2'>פרטים אישים</div>
                <div className='btn btn-sm btn-app-blue mx-2'>פרטי התקשרות</div>
                <div className='btn btn-sm btn-app-blue mx-2'>הרשאות</div>
            </div> */}
            <div className='mt-3'>
                <div className='text-center fw-bold mb-1'>עובדים</div>
                <EmployeesForm />
            </div>
        </div></>
}

export default BranchEmployeesSettingsForms