import React, { useState } from 'react';
import { Trans } from '../../../../lang/Trans';
import './AddNewRoleModal.css';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { useWorkey } from '../../../../WorkeyContext';
import { makeApiRequest } from '../../../../utils/utils';
import Select from 'react-select';
import { MINIMUM_WAGE } from '../../../../utils/data_utils';
import { toast } from 'react-hot-toast';

const AddNewRoleModal = ({ selectedRole, setAddRoleModal }) => {
  const { globalState, setGlobalState } = useWorkey();
  const [name, setName] = useState(selectedRole?.name || '');
  const [defaultWageType, setDefaultWageType] = useState(selectedRole?.default_wage_type);
  const [wagePercent, setWagePercent] = useState(selectedRole?.default_wage_percent || 100);
  const [minimumWage, setMinimumWage] = useState(selectedRole?.default_minimum_wage || MINIMUM_WAGE);
  const wageTypeOptions = [
    { label: 'שכר שעתי', value: 'salery', selected: defaultWageType === 'salery' },
    { label: 'טיפים עם השלמה לשכר מינימום', value: 'tips', selected: defaultWageType === 'tips' },
    { label: 'טיפים ללא השלמה', value: 'tips_only', selected: defaultWageType === 'tips_only' },
    { label: 'שכר שעתי וטיפים ללא השלמה', value: 'both', selected: defaultWageType === 'both' },
  ]
  const [error, setError] = useState(null);
  const tagOptions = [
    { label: 'employees_settings_lists.roles.resturant.manager', value: 'מנהל' },
    { label: 'employees_settings_lists.roles.resturant.waiter', value: 'מלצר' },
    { label: 'employees_settings_lists.roles.resturant.bartender', value: 'ברמן' },
    { label: 'employees_settings_lists.roles.resturant.host', value: 'מארחת' },
    { label: 'employees_settings_lists.roles.resturant.cold_kitchen', value: 'פס קר' },
    { label: 'employees_settings_lists.roles.resturant.hot_kitchen', value: 'פס חם' },
    { label: 'employees_settings_lists.roles.resturant.checker', value: "צ'קר" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null)
    // Check if name is not empty and is not an existing role
    if (name.trim() === '') {
      toast.error('Please enter a role name.');
      return;
    }
    if (!selectedRole?.id && globalState.user.branch.roles.find(role => role.name === name.trim())) {
      toast.error('Role name already exists.');
      return;
    }

    // Add new role to global state
    const newRole = name.trim();
    const req = await makeApiRequest(globalState, setGlobalState, `/roles${selectedRole?.id ? `/${selectedRole.id}` : ''}`, selectedRole?.id ? 'PUT' : 'POST',
    { name: newRole || selectedRole?.name,
      default_wage_type: defaultWageType || selectedRole?.default_wage_type,
      default_wage_percent: wagePercent || selectedRole?.default_wage_percent,
      default_minimum_wage: minimumWage || selectedRole?.default_minimum_wage});
    const res = req[1]
    const data = req[0]

    if (res.status === 201) {
      if(selectedRole?.id) {
        let roleIndex = globalState.user.branch.roles.findIndex(role => role.id === selectedRole.id);
        let roles = [...globalState.user.branch.roles || []]
        roles[roleIndex] = data
        setGlobalState({ ...globalState, user: { ...globalState.user, branch: { ...globalState.user.branch, roles } } })
      } else {
        let new_state = { ...globalState };
        new_state.user.branch.roles.push(data.role)
        await setGlobalState(new_state)
      }
      setAddRoleModal(false)
    } else {
      setError(res.error)
      toast.error(data.error || res.statusText || <Trans keys={'toast.server_error'} />);
    }
  };

  const selectTag = (tag) => {
    setName(tag)
    // setTag(tag)
  }

  return (
    <div className="add-new-role-modal">
      <div className="card">
        <div className="card-header">
          <div className="new-role-modal-close-btn pointer" onClick={() => setAddRoleModal(false)}>
            <Icon path={mdiClose} title={<Trans keys={'close'} />} size={1} />
          </div>
          <Trans keys={'employees_settings_lists.roles.add'} />
        </div>
        <div className="card-body">
          <div className='new-role-modal- d-flex flex-row justify-content-center app-red-txt fw-bolder'>{error}</div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className='d-flex flex row role-name'>
              <div className='d-flex flex-column input-and-lable'>
                <div className="form-group">
                  <label htmlFor="roleName">
                    <Trans keys={'employees_settings_lists.roles.name'} />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="roleName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group suggestions">
                <label htmlFor="roleTag">
                  <Trans keys={'employees_settings_lists.roles.tag'} />
                </label>
                <div className="new-role-suggestions-container">
                  {tagOptions.map((option) => (
                    <button
                      key={option.value}
                      type="button"
                      className={`btn btn-sm btn-outline-secondary ${option.value === name ? 'active' : ''}`}
                      onClick={() => selectTag(option.value)}
                    >
                      <Trans keys={option.label} />
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className='d-flex flex row role-minimum-wage'>
              <div className='d-flex flex-column input-and-lable'>
                <div className="form-group">
                  <label htmlFor="roleMinimumWage">
                    <Trans keys={'employees_settings_lists.minimum_wage'} />
                  </label>
                  <input
                    type={"number"}
                    className='form-control'
                    min={0}
                    value={minimumWage}
                    onChange={(e) => setMinimumWage(e.target.value)}
                  />
                </div>
              </div>

            </div>
            <div className='d-flex flex row role-wage-type'>
              <div className='d-flex flex-column input-and-lable'>
                <div className="form-group">
                  <label htmlFor="roleType">
                    <Trans keys={'employees_settings_lists.wage_type'} />
                  </label>
                  <Select
                    // className='form-control'
                    value={wageTypeOptions.find((option) => option.value == defaultWageType)}
                    options={wageTypeOptions}
                    onChange={(e) => setDefaultWageType(e.value)}
                  />
                </div>
                {defaultWageType && defaultWageType != 'salery' && <div className='d-flex flex row role-wage-type'>
                  <div className='d-flex flex-column input-and-lable'>
                    <div className="form-group">
                      <label htmlFor="roleWageType">
                        <Trans keys={'employees_settings_lists.wage_percent'} />
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="roleWageType"
                        value={wagePercent}
                        onChange={(e) => setWagePercent(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>}
              </div>

            </div>
            <div className='new-role-modal-btns'>
              <button type="submit" className="btn btn-sm btn-app-green" onClick={(e) => handleSubmit(e)}>
                <Trans keys={'save'} />
              </button>
              <button type="button" className="btn btn-sm btn-app-red ml-2" onClick={() => setAddRoleModal(false)}>
                <Trans keys={'cancel'} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewRoleModal;
