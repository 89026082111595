import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '../../lang/Trans';
import { uniqueId } from 'lodash';

import './DynamicForm.css';

const propTypes = {
  fields: PropTypes.object.isRequired,
  validation: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onEdit: PropTypes.func, // new prop
  onCancel: PropTypes.func, // new prop
  edit: PropTypes.bool,
};

const defaultProps = {
  edit: false,
};

function DynamicForm({
  fields,
  validation,
  onSave,
  onEdit,
  onCancel,
  edit,
  userAllowedToEdit,
  fieldStyle = 'col-12 col-md-4',
}) {
  const [values, setValues] = useState(
    Object.fromEntries(
      Object.keys(fields).map((key) => [key, fields[key].value || ''])
    )
  );
  const [originalValues, setOriginalValues] = useState(values);
  const [errors, setErrors] = useState(null);

  // useEffect(() => {
  //   setOriginalValues(values);
  // }, [values]);

const handleChange = (event) => {
  const { name, value, type, checked } = event.target;
  const newValue = type === 'checkbox' ? checked : value;
  setValues((prevValues) => ({ ...prevValues, [name]: newValue }));
};
  
  const handleSave = () => {
    const validationErrors = validation(values);
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      const formValues = Object.fromEntries(
        Object.keys(fields).map((key) => [key, values[key]])
      );
      setErrors(null);
      onSave(formValues);
    }
  };

  const handleCancel = async() => {
    await setValues(originalValues);
    onEdit(false)
    if (onCancel) {
      onCancel();
    }
  };

//   const cancelChanges = () => {
//     setSelectedValues(originalValues)
//     setEditMode(false)
// }

  // TODO handle number input aswell
  const renderField = (key) => {
    const { label, id, type, options } = fields[key];
    const value = values[key];
    const error = errors && errors[key];
    return (
      <div
        className={`justify-content-between mb-3 form-group ${fieldStyle}`}
        key={`${key || id || label}`}
      >
        <label className="ms-3" htmlFor={id || key}>
          <Trans keys={label || key || id} />
        </label>
        {type === 'select' ? (
          <select
            key={`${key || id || label}-${uniqueId()}`}
            className="form-control"
            id={id || key || label}
            name={key}
            // defaultValue={value}
            value={value}
            onChange={handleChange}
            disabled={!edit}
          >
            <option value="">Select...</option>
            {options.map((option) => (
              <option
                key={`${key || id || label}-${uniqueId()}`}
                value={option}
              >
                {option}
              </option>
            ))}
          </select>
        ) : type === 'checkbox' ? (
          <div className="form-check w-100 align-items-center">
            <div className="d-flex flex-row ">
              <input
                key={`${key || id || label}-${uniqueId()}`}
                type="checkbox"
                className="form-check-input"
                id={id || key}
                name={key}
                checked={value}
                onChange={handleChange}
                disabled={!edit}
              />
            </div>
            <label
              className="d-flex flex-row form-check-label"
              htmlFor={id || key}
            >
              <Trans>{label || key}</Trans>
            </label>
          </div>
        ) : (
          <input
            type={type || 'text'}
            className="form-control"
            id={id || key}
            key={`${key || id || label}-${uniqueId()}`}
            name={key}
            value={type === 'number' ? Number(value || 0) : value}
            onChange={handleChange}
            disabled={!edit}
          />
        )}
        {error && <div className="text-danger">{error}</div>}
      </div>
    );
  };

  return (
    <div className="container dynamic-form">
      <div className="text-center btns-footer mb-3">
        {edit ? (
          <div className="form-group col-12">
            <button
              type="button"
              className="btn btn-app-red mx-1"
              onClick={handleCancel}
            >
              <Trans keys={'cancel'} />
            </button>
            <button
              type="button"
              className="btn btn-app-green mx-1"
              onClick={handleSave}
            >
              <Trans keys={'save'} />
            </button>
          </div>
        ) : (
          userAllowedToEdit && (
            <div className="form-group col-12">
              <button
                type="button"
                className="btn btn-app-blue"
                onClick={() => onEdit(true)} // call the callback function
              >
                <Trans keys={'edit'} />
              </button>
            </div>
          )
        )}
      </div>
      <form>
        <div className={'row'}>{Object.keys(fields).map(renderField)}</div>
      </form>
      {/* </div> */}
    </div>
  );
}

DynamicForm.propTypes = propTypes;
DynamicForm.defaultProps = defaultProps;

export default DynamicForm;
