import React, { useEffect, useState } from 'react'
import './SlotTimeModal.css'
import { Icon } from '@mdi/react';
import { rebuildWeek } from '../../../../../utils/week_utils'
import { Trans } from '../../../../../lang/Trans'
import { useWorkey } from '../../../../../WorkeyContext'
import { toast } from 'react-hot-toast';
import { makeApiRequest } from '../../../../../utils/utils';

const SlotTimeModal = ({ currentWeek, setCurrentWeek, show, timeModalSlotId, toggleTimeModal }) => {
  const { globalState, setGlobalState } = useWorkey()
  const [slot, setSlot] = useState(currentWeek?.slots?.find(s => s.id == timeModalSlotId))
  const [applyToAll, setApplyToAll] = useState(false)

  const [role, setRole] = useState(globalState.user.branch.roles.find(r => r?.id == slot?.role_id))
  const [slotRoleIndex, setSlotRoleIndex] = useState(currentWeek.slots.filter(s => s?.shift_id == slot?.shift_id && s?.role_id == slot?.role_id).findIndex(s => s?.role_id == slot?.role_id))
  const [shiftIndex, setShiftIndex] = useState(currentWeek.shifts.filter(s => s?.id == slot?.shift_id).findIndex(s => s?.id == slot?.shift_id))
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    getData()
  }, [timeModalSlotId])

  const getData = async () => {
    if (currentWeek?.slots) {
      let get_slot = currentWeek.slots.find(s => s.id == timeModalSlotId);
      await setSlot(get_slot);
      await setRole(globalState.user.branch.roles.find(r => r?.id == get_slot?.role_id));

      let shift_index = null;
      let slot_shift
      currentWeek.days?.forEach((day, day_index) => {
        day.shifts?.forEach((shift, day_shift_index) => {
          if (shift.id === get_slot.shift.id) {
            shift_index = day_shift_index;
            slot_shift = shift
          }

        });
      });
      let slot_role_index = currentWeek.shifts.find(s => s?.id == get_slot?.shift_id).slots.filter(s => s?.role_id == get_slot?.role_id).findIndex(s => s?.id == get_slot?.id);

      await setShiftIndex(shift_index);
      await setSlotRoleIndex(slot_role_index);
    }
  }

  const save = async (t = false) => {
    if (currentWeek.status === 'published') {
      if (!t) {
        return toast((t) => <div>
          <div><Trans keys={'time_modal.saving_will_publish'} /></div>
          <div>
            <div className='btn btn-sm btn-success' onClick={() => save(t)}><Trans keys={'yes'} /></div>
            <div className='btn btn-sm btn-danger' onClick={() => toast.dismiss(t.id)}><Trans keys={'no'} /></div>
          </div>
        </div>
          , { duration: 5000, position: 'top-left', className: 'app-red-txt' })
      } else {
        toast.dismiss(t.id)
      }
    }
    let new_current_week = { ...currentWeek }
    let new_slots
    if (applyToAll) {
      let relevante_shifts = currentWeek.days.map(d => d.shifts[shiftIndex])
      let relevante_slots = relevante_shifts.map(shift => shift?.slots?.filter(s => s.role_id == slot.role_id)[slotRoleIndex])
      new_slots = [...relevante_slots]
    } else {
      new_slots = [{ ...slot }]
    }
    new_slots = new_slots.filter(s => !!s)
    for (let index = 0; index < new_slots.length; index++) {
      let new_slot = new_slots[index];
      let total_expected_hours = Number(((new Date(`1970-01-01T${slot.expected_end}:00Z`) - new Date(`1970-01-01T${slot.expected_start}:00Z`)) / 3600000).toFixed(2));
      new_slot.expected_start = slot.expected_start
      new_slot.expected_end = slot.expected_end
      if (new_slot.id === slot.id) {
        new_slot.start = slot.start
        new_slot.end = slot.end
        new_slot.user_id = slot.user_id
        new_slot.total_hours = Number(((new Date(`1970-01-01T${slot.end}:00Z`) - new Date(`1970-01-01T${slot.start}:00Z`)) / 3600000).toFixed(2));
        if (new_slot.total_hours < 0 || total_expected_hours < 0) {
          return toast(<Trans keys={'time_modal.errors.start_bigger_then_end'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
        }
      }
      new_slots[index] = {
        id: new_slot.id,
        shift_id: new_slot.shift_id,
        role_id: new_slot.role_id,
        start: new_slot.start,
        end: new_slot.end,
        expected_start: new_slot.expected_start,
        expected_end: new_slot.expected_end,
        total_hours: new_slot.total_hours || null,
        user_id: new_slot.user_id,
        total_pay: new_slot.total_pay,
        data: new_slot.data
      }
    }
    // return
    // let slot_index = await new_current_week.slots.findIndex(s => s.id == slot.id)
    // const req = await makeApiRequest(globalState, setGlobalState, `/weeks/${currentWeek.id}`, 'PUT', {
    //   slots: new_slots,
    //   // shifts: shifts
    // });

    // const res = req[1]
    // const data = req[0]
    let slots = [...new_current_week.slots]
    for (let index = 0; index < slots.length; index++) {
      let new_slot = new_slots.find(s => s.id == slots[index].id);
      if(new_slot) {
        slots[index] = new_slot;
      }
      
    }
    new_current_week['slots'] = slots
    // if (res.status === 200 || res.status === 201) {
      new_current_week = await rebuildWeek(new_current_week, globalState)
      await setCurrentWeek(new_current_week)
      toggleTimeModal()
    // } else {
      // toast(<Trans keys={'time_modal.errors.save_failed'} />, { duration: 5000, position: 'top-left', className: 'app-red-txt' })
    // }
  }

  if (show && slot) {
    return (
      <div className='slot-time-modal overlay'>
        <div className='card'>
          <div className='header'>
            <div className='close-btn' onClick={() => toggleTimeModal()}>X</div>
          </div>
          <div className='body'>
            <div className='d-flex justify-content-center w-100'><Trans keys={'expected_time'} /></div>
            <div className='time-display'>
              <div>
                <div><Trans keys={'time_modal.expected_end_title'} /></div>
                <div className='time-input'><input className='form-control' type={"time"} value={slot.expected_end || '00:00'} onChange={(e) => setSlot({ ...slot, expected_end: e.target.value })} /></div>
              </div>
              <div className='seperator' />
              <div>
                <div><Trans keys={'time_modal.expected_start_title'} /></div>
                <div className='time-input'><input className='form-control' type={"time"} value={slot.expected_start || '00:00'} onChange={(e) => setSlot({ ...slot, expected_start: e.target.value })} /></div>
              </div>
            </div>
            <div className="d-flex flex-row mb-2">
              <input className="form-check-input pointer" type="checkbox" value="" id="applyToAll"
                checked={applyToAll} onChange={() => setApplyToAll(!applyToAll)} />
              <label className="form-check-label d-flex flex-wrap mx-3" for="applyToAll"
                onClick={() => setApplyToAll(!applyToAll)}>
                שנה עבור כל העובדים מסוג {role?.name} במשבצת {slotRoleIndex + 1} במשמרת {shiftIndex + 1}
              </label>
            </div>
            <div className='d-flex justify-content-center w-100'><Trans keys={'actual_time'} /></div>
            <div className='time-display'>
              <div>
                <div><Trans keys={'time_modal.end_title'} /></div>
                <div className='time-input'><input className='form-control' type={"time"} value={slot.end || '00:00'} onChange={(e) => setSlot({ ...slot, end: e.target.value })} /></div>
              </div>
              <div className='seperator' />
              <div>
                <div><Trans keys={'time_modal.start_title'} /></div>
                <div className='time-input'><input className='form-control' type={"time"} value={slot.start || '00:00'} onChange={(e) => setSlot({ ...slot, start: e.target.value })} /></div>
              </div>
            </div>
          </div>
          <div className='footer'>
            <div className='btn btn-danger' onClick={() => toggleTimeModal()}><Trans keys={"cancel"} /></div>
            <div className='btn btn-success' onClick={() => save()}><Trans keys={"save"} /></div>
          </div>
        </div>
      </div>
    )
  } else {
    <></>
  }
}

export default SlotTimeModal