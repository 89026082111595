import React, { useEffect, useState } from 'react'
import './Structure.css'
import DayStructure from './DayStructure'
import { fakeShiftId } from '../../../utils/structure_utils'
import StructureRoleSetting from '../display/structure/StructureRoleSetting'
import StructureRoleSettingsModal from './StructureRoleSettingsModal'
import { makeApiRequest } from '../../../utils/utils'
import { Trans } from '../../../lang/Trans'
import { useWorkey } from '../../../WorkeyContext'
import Loading from '../../loading/Loading'
import { toast } from 'react-hot-toast'
import UnavailableFeature from '../../blocks-ui/UnavailableFeature'

const Structure = ({ removeNeeded }) => {
    const { globalState, setGlobalState, setLoading } = useWorkey()
    const [weekStructure, setWeekStructure] = useState(globalState.weekStructure || {})
    const [showActions, setShowActions] = useState(true)
    const [showRoleSettingsModal, setShowRoleSettingsModal] = useState(false)
    // update weekStructure on component load or when state changes and add 7 days to weekStructure if empty

    const resetWeekStructure = async (force = false) => {

        // TODO - popup
        if (force) {
            let ok = window.confirm("this will reset the week structure, are you sure?")
            if (!ok) { return }
        }
        let week = globalState.weekStructure
        if (!week?.days) {
            week = { ...globalState.user?.branch?.data.week_structure } || {}
        }
        if (force) { week = {} }
        if (!week.days) {
            week.days = []
            for (let i = 0; i < 7; i++) {
                let shift_fake_id = fakeShiftId(i + 1, week.days[i + 1]?.shifts.length)
                week.days.push({ shifts: [{ id: shift_fake_id, slots: { shift_id: shift_fake_id, role_id: null } }] })
            }
        }
        let state = { ...globalState }
        state.weekStructure = week
        await setGlobalState(state)

    }



    const storeUserWeekStructure = (week_structure) => {
        let new_state = { ...globalState }
        new_state.weekStructure = week_structure
        setGlobalState(new_state)
    }

    const addShiftForAllDays = () => {
        let days = [...weekStructure.days]
        for (let i = 0; i < 7; i++) {
            let fake_shift_id = fakeShiftId(i + 1, days[i].shifts.length)
            days[i].shifts.push({ id: fake_shift_id, slots: { shift_id: fake_shift_id, role_id: null } })
        }
        let week = { ...weekStructure, days }
        let state = { ...globalState }
        state.weekStructure = week
        setGlobalState(state)
    }

    const removeLastShiftForAllDays = () => {
        let days = [...weekStructure.days]
        for (let i = 0; i < 7; i++) {
            days[i].shifts.pop()
        }
        let week = { ...weekStructure, days }
        let state = { ...globalState }
        state.weekStructure = week
        setGlobalState(state)
    }

    const saveStruectureToDB = async () => {
        setGlobalState({ ...globalState, loading: true })
        let state = { ...globalState }
        let branch = state.user.branch
        branch ||= {}
        branch['data'] ||= {}
        branch['data']['week_structure'] = state.weekStructure
        delete branch['id']
        delete branch['roles']
        delete branch['positions']
        delete branch['employees']
        delete branch['branch']

        branch.data = JSON.stringify(branch.data)
        const req = await makeApiRequest(globalState, setGlobalState, `/branches/${globalState.user.branch_id}`, 'PUT', branch)
        const res = req[1]
        const data = req[0]
        if (res.status === 201 || res.status === 200) {
            // state.weekStructure = null
            state.user.branch = data
            state.loading = false
            setGlobalState(state)
            toast.error(data.error || <Trans keys={'toast.updated.schedule_structure'} />);
            if (removeNeeded) {
                removeNeeded('schedule_structure')
            }
        } else {
            toast.error(data.error || <Trans keys={'toast.feild_to_update.schedule_structure'} />);
        }

    }

    useEffect(() => {
        resetWeekStructure()
    }, [])

    useEffect(() => {
        setWeekStructure(globalState.weekStructure)
    }, [globalState.weekStructure])

    if (!globalState.user.branch?.active_subscriptions.find(subscription => subscription.subscription_type === 'menual_schedule')) {
        return <div>
            <UnavailableFeature />
        </div>
    }


    return (
        <div className='structure'>
            <div className='card pb-5'>
                <div className='d-flex flex-row justify-content-center card-header'>
                    {!showActions && <div className={`btn btn-sm btn-dark ${showActions ? 'hidden' : 'unflod-btn'}`} onClick={() => setShowActions(true)}>פתח</div>}
                    <div><Trans keys={"build_schedule"} /></div>
                </div>
                <div className='card-body' >
                    {showRoleSettingsModal && <StructureRoleSettingsModal show={showRoleSettingsModal} handleClose={() => setShowRoleSettingsModal(false)} />}
                    <div>
                        <div className={`card structure-actions ${!showActions && 'folded-container'}`}
                            onClick={() => !showActions ? setShowActions(!showActions) : {}}>
                            {/* <div className='d-flex justify-content-evenly'> */}
                            <div className='btn btn-secondary fold-btn' onClick={() => setShowActions(!showActions)}>קפל תפריט</div>
                            <div className='btn btn-success' onClick={addShiftForAllDays}>הוספת משמרת לכל הימים</div>
                            <div className='btn btn-danger' onClick={removeLastShiftForAllDays}>הסרת משמרת מכל הימים</div>
                            <div className='btn btn-info' onClick={() => setShowRoleSettingsModal(!showRoleSettingsModal)}>שינוי מספר עובדים לפי תפקיד לכל המשמרות</div>
                            <div className='btn btn-primary' onClick={() => saveStruectureToDB()}>שמור</div>
                            <div className='btn btn-dark' onClick={() => resetWeekStructure(true)}>איפוס</div>
                            {/* </div> */}
                        </div>
                    </div>
                    <div className='days'>
                        {/* <div className='card'>
                            <div className='card-header'>תפקידים</div>
                        </div> */}
                        {weekStructure?.days?.map((day, day_i) => {
                            return (
                                <DayStructure
                                    key={`day_structure_${day_i}`}
                                    day_i={day_i}
                                    weekStructure={weekStructure}
                                    setWeekStructure={storeUserWeekStructure}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Structure