import React, { useState } from 'react'
import './ProfileAuthorizedActionsCard.css'
import { Trans } from '../../../../lang/Trans'
import './ProfileAuthorizedActionsCard.css'
import { useWorkey } from '../../../../WorkeyContext'
import { makeApiRequest } from '../../../../utils/utils'
const ProfileAuthorizedActionsCard = ({ user }) => {
    const { globalState, setGlobalState } = useWorkey()
    const [currentUser, setCurrentUser] = useState(user)
    const [editMode, setEditMode] = useState(false)
    const onSave = async() => {
        const req = await makeApiRequest(globalState, setGlobalState, `users/${currentUser.id}`, 'PUT', currentUser)
        const res = req[1]
        const data = req[0]
        setEditMode(false)
        let branch_employees = globalState.user.branch?.employees
        let employeeIndex = branch_employees.findIndex((e) => e.id == currentUser.id)
        branch_employees[employeeIndex] = data.user
        setGlobalState({ ...globalState, user: { ...globalState.user, branch: { ...globalState.user.branch, employees: branch_employees } } })
    }

    const onCancel = () => {
        setCurrentUser(user)
        setEditMode(false)
    }

    const changeAuthorizedAction = (e, action_name) => {
        // return
        let current_user = { ...currentUser }
        current_user.data.authorized_actions[action_name] = e.target.checked
        setCurrentUser(current_user)
    }

    const explains = {
        manage_employee: "צירוף/הסרת עובד, "
    }

    return (
        <div className='profile-authorized-actions-container'>
             <div className='text-center mb-2'><Trans keys={`authorized_actions.title`} /></div>
            <div className='btns'>
                {editMode && (<>
                    <div className='btn btn-sm btn-app-green mx-2' onClick={() => onSave()}>
                        <Trans keys={'save'} />
                    </div>
                    <div className='btn btn-sm btn-app-red mx-2' onClick={() => onCancel()}>
                        <Trans keys={'cancel'} />
                    </div>
                </>)}
                {!editMode && <div className='btn btn-sm btn-app-blue mx-2' onClick={() => setEditMode(true)}>
                    <Trans keys={'edit'} />
                </div>}
                <div className='mt-3'>
                    {Object.keys(globalState.user.data.authorized_actions).map((action_name) => {
                        
                        if(action_name.includes("own") || action_name.includes("sys") || (action_name === "branch_manager" && !globalState.user.data.authorized_actions["branch_manager"])) {
                            return null
                        }
                        return (
                            <div class="d-flex flex-row mx-2">
                                <input class="form-check-input" type="checkbox" value="" id={`authorized_action_${action_name}`}
                                    checked={currentUser.data.authorized_actions[action_name]}
                                    disabled={!editMode}
                                    onChange={(e) => changeAuthorizedAction(e, action_name)} />
                                <label class={`form-check-label mx-3 ${!editMode ? 'fw-bold' : ''}`} for={`authorized_action_${action_name}`}>
                                    <Trans keys={`authorized_actions.${action_name}`} />
                                </label>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ProfileAuthorizedActionsCard

