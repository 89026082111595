import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import { dashboardCardsOptions } from '../../utils/dashboard_utils'
import { Icon } from '@mdi/react';

import {  IconSize, isNewUser } from '../../utils/utils';
import { useWorkey } from '../../WorkeyContext';
import WelcomeNewUser from '../welcome/welcome-new-user/WelcomeNewUser';
import Telegram from '../telegram/Telegram';
import { mdiClose } from '@mdi/js';
import User from '../../utils/user_utils';

const Dashboard = ({ state }) => {
  const { globalState, setGlobalState } = useWorkey()
  const userUtils = new User(globalState)

  const [clicked, setClicked] = useState(null)
  const initUserList = () => {
    let list =['Telegram']
    if(userUtils.allowedTo('manage_employees')) {
      list.push('QuickInviteEmployee')
    }
    return list
    
  }


  const [userList, setUserList] = useState(initUserList())
  const [optionList, setOptionList] = useState(Object.keys(dashboardCardsOptions())?.filter(item => !userList.includes(item)) || [])
  const [addNewCardModal, setAddNewCardModal] = useState(false)
  const toggleNewCardModal = () => {
    setAddNewCardModal(!addNewCardModal)
  }

  const addWidget = async (widgetId) => {
    let new_list = [...userList, widgetId]
    setUserList(new_list)
    setOptionList(Object.keys(dashboardCardsOptions())?.filter(widget => !new_list.includes(widget)) || [])
  }

  const findCallBack = (item) => {
    switch (item) {
      case "NewCardModal":
        return toggleNewCardModal;
      default:
        return () => { }
    }
  }

  const widgetWrapper = (item, selected) => {
    if (item === "NewCardModal") {
      return <></>
    }
    return (
      <div key={item} id={item} className={`${clicked === item ? "clicked" : "pointer"} widget card ${selected ? '' : addNewCardModal ? 'not-selected' : "hidden"}`} onClick={() => findCallBack(item)}
        draggable={addNewCardModal}
        onDragEnter={(e) => {
        }}
        onDragLeave={(e) => {

        }}
        >
        {clicked === item && <Icon path={mdiClose} size={1} className='pointer' onClick={() => setClicked(null)} />}
        <div className='widget-body' onClick={() => setClicked(item)}>
          {dashboardCardsOptions(findCallBack(item), selected)[item]}
        </div>
        <div className='show'>
          <div className={selected ? 'hidden' : "btn btn-app-dark-blue"} onClick={() => addWidget(item)}>הוסף</div>
        </div>
      </div>)
  }


  const telegram = <></>

  return (
    globalState.user?.id && !isNewUser(globalState) ?
      <div className='dashboard'>
        <div className='d-flex flex-row justify-content-center flex-wrap'>
          {/* {widgetWrapper(toggleNewCardModal)} */}
          <div className={`align-self-center btn btn-app-${addNewCardModal ? 'red':'dark-blue'}`}
          onClick={() => setAddNewCardModal(!addNewCardModal)}>{`${addNewCardModal ? 'ביטול' : "עריכת ווידגיטים"}`}</div>

          {dashboardCardsOptions(findCallBack("NewCardModal"), true)["NewCardModal"]}
          {userList.map((item) => {
            return widgetWrapper(item, true)
          })}
          {
            optionList.map((item) => {
              return addNewCardModal ? widgetWrapper(item) : <></>
            })
          }

          {/* {newCardBtn()} */}
        </div>
      </div>
      : <WelcomeNewUser />
  )
}

export default Dashboard