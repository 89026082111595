import React from 'react'
import EmployerSystemSettings from './EmployerSystemSettings'
import BranchPage from '../branch-page/BranchPage'
import { Trans } from '../../../lang/Trans'
import BackToPickTypeBtn from '../../welcome/welcome-new-user/BackToPickTypeBtn'
import { useWorkey } from '../../../WorkeyContext'

const SystemSettingsWrapper = ({ activateUser, userType = null, setUserType, employerType = null, setEmployerType }) => {
    const { globalState } = useWorkey()
    return (
        <>
            {globalState.user.plan === 'free' && <div className='top'>
                <BackToPickTypeBtn callback={() => setEmployerType(null)} />
            </div>}
            {employerType === "corporation" && (
                <div className='fw-bold text-center m-5'>דף זה בקרוב יעלה, כרגע לא ניתן לשייך חשבון לרשת</div>
            )}
            {employerType === "branch" && (
                <BranchPage activateUser={activateUser} />
            )}
        </>
    )
}

export default SystemSettingsWrapper