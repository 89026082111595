import React, { useState } from 'react'
import './UserSignup.css'
import { makeApiRequest, userDisplayName } from '../../../utils/utils'
import { useWorkey } from '../../../WorkeyContext'
import ProfileAvatarCard from '../profile/profile-avatar-card/ProfileAvatarCard'
import ProfileInfoCard from '../profile/profile-info-card/ProfileInfoCard'
import { Trans } from '../../../lang/Trans'
import User from '../../../utils/user_utils'
import { toast } from 'react-hot-toast'

const UserSignup = () => {
    const { globalState, setGlobalState } = useWorkey()
    const [avatarEditMode, setAvatarEditMode] = useState(true)
    const userUtils = new User(globalState)

    const completeUserSignup = async () => {
        let user = { ...globalState.user }
        delete user.token
        delete user.branch
        user.status = userUtils.status_list['completed_user_setup']

        const req = await makeApiRequest(globalState, setGlobalState, `/users/${user.id}`, "PUT", user);
        const res = req[1]
        const data = req[0]
        if ((res.status === 201 || res.status === 200) && data.user.id) {
            data.user.token = data.token
            setGlobalState({
                ...globalState,
                user: {
                    ...globalState.user,
                    ...data.user
                }
            })
        } else {
            toast.error(<Trans keys={'toast.feild_to_update.complete_user_setup'} />);
        }
    }

    return (
        <div className='user-signup-page-layout'>
            <div className='main-actions-btns card'>
                <div className='btn btn-sm btn-app-green' onClick={() => completeUserSignup()}>
                    <Trans keys={'complete_user_setup_btn_text'} />
                </div>
                <div className={`btn btn-sm btn-app-blue mx-2 avatar-btn ${!avatarEditMode ? 'show-avatar' : ''}`} onClick={() => setAvatarEditMode(true)}>
                    <Trans keys={'want_to_change_image'} />
                </div>
            </div>
            <div className='user-sections'>
                <div className='large-sections'>
                    <div className='profile-info-container'>
                        <ProfileInfoCard
                            showEdit={true}
                            user={globalState.user}
                        />
                    </div>
                </div>
                <div className={`profile-avatar-container card ${avatarEditMode ? "show-avatar" : ''}`}>
                    <ProfileAvatarCard
                        avatarType={'avatar'}
                        user={globalState.user}
                        location={'setup'}
                        showEdit={true}
                    />
                    <div className={`hide-avatar-btn ${avatarEditMode ? 'show-avatar' : ''}`}>
                        <div className={`btn btn-sm btn-app-light-blue w-50 algin-self-center mx-auto`} onClick={() => setAvatarEditMode(false)}>
                            <Trans keys={'hide'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserSignup