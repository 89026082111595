import './App.css';
import './Colors.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/sidebar/Sidebar';
import TopNavbar from './components/content_navbar/TopNavbar';
import { useEffect, useRef } from 'react';
import { isSmallScreen, setupRunner } from './utils/utils';
import '../node_modules/react-interactive-tutorials/dist/react-interactive-tutorials.css';
import { Toaster } from 'react-hot-toast';
import Tour from 'reactour'

import {
  BrowserRouter as Router,
} from "react-router-dom";
import AppRoutes from './Routes';
import Loading from './components/loading/Loading';
import _ from 'lodash';
import { useWorkey } from './WorkeyContext';
import { steps } from './assets/tutorial/tutorial';

export const DEV = process.env.NODE_ENV === "development"
export const PROD = process.env.NODE_ENV === "production"
export const STAGE = process.env.NODE_ENV === "production" && window.location.pathname.startsWith("staging")

function App() {
  const { globalState, setGlobalState } = useWorkey(); // use globalState from the context instead of the state variable

  useEffect(() => {
    setTimeout(() => {
      if(globalState?.user?.branch_id)
      window.document.getElementById('begin-tutorial-nav-item').click()
    }, 1000 * 5)
  }, [])

  useEffect(() => {
    setupRunner(globalState, setGlobalState, { branch: true })
  }, [globalState?.user?.id, globalState?.user?.branch_id, globalState?.user?.status])

  // const shouldRunTutorial = () => { return globalState.user?.status === "active" && globalState.user?.id && globalState.user?.tutorial && !globalState.user?.tutorial?.disabled && !globalState.user?.tutorial?.target_id }
  // useEffect(() => {
  //   let intervalId = null;
  //   if (shouldRunTutorial()) {
  //     intervalId = setInterval(startTourial, 5 * 1000);
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [shouldRunTutorial()]);

  // const appRef = useRef(null);

  // useEffect(() => {
  //   const handleFullscreen = () => {
  //     if (appRef.current) {
  //       if (appRef.current.requestFullscreen) {
  //         appRef.current.requestFullscreen();
  //       } else if (appRef.current.mozRequestFullScreen) {
  //         appRef.current.mozRequestFullScreen();
  //       } else if (appRef.current.webkitRequestFullscreen) {
  //         appRef.current.webkitRequestFullscreen();
  //       } else if (appRef.current.msRequestFullscreen) {
  //         appRef.current.msRequestFullscreen();
  //       }
  //     }
  //   };

  //   if (isSmallScreen()) {
  //     handleFullscreen();
  //   }
  // }, [globalState.user?.id]);

  const closeTour = () => {
    setGlobalState({ ...globalState, isTourOpen: false });
  }

  return (
    <div id="App" className={`App ${globalState.local}`}>
      <Tour
        steps={steps[globalState?.isTourOpen]}
        rounded={5}
        showNumber={false}
        showCloseButton={true}
        lastStepNextButton={<div
          onClick={closeTour}
          className='btn btn-sm btn-app-blue'>סיימנו</div>}
        isOpen={globalState?.isTourOpen || false}
        onRequestClose={closeTour} />
      <>
        <Router>
          {/* <Chat /> */}
          <Sidebar />
          <div className={`content ${!globalState.user?.id ? "only" : ""} ${globalState.largeSidebar ? 'sm' : 'xl'}`}>
            <Toaster />
            <TopNavbar />
            <div className='content-body'>
              <AppRoutes />
              {(Object.keys(globalState.fetchingData).length > 0) && <Loading />}
            </div>
          </div>
        </Router>
      </>
      {/* <TutorialLayout /> */}
    </div>
  );
}

export default App;
