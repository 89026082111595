import { langsGeneral } from "./langs_general"

let HE = {
    he: "עברית",
    missing_translation: "תרגום חסר עבור",
    missing_lang: "לא נמצאה שפה",
    unknown: "לא ידוע",
    please_sign_in: "נא להתחבר",
    not_authorized_for_this_page: "הגישה לדף זה הינה למשתמשים מורשים בלבד",
    not_selected: "לא נבחר",
    sign_in: "כניסה",
    signup: "הרשמה",
    logout: "התנתקות",
    yes: "כן",
    no: "לא",
    new: "חדש",
    back: "חזור",
    owner: "בעלים",
    main_role_not_found: "לא נמצא תפקיד ראשי בשם",
    employee: "עובד",
    employer: "מעסיק",
    authorized_actions: "הרשאות",
    employees_form: {
        EMPTY_NAME_ERROR: 'השם לא יכול להיות ריק',
        EMPTY_ROLES_ERROR: 'לא ניתן לבחור תפקיד נוסף אם לא נבחר תפקיד ראשי',
        EMPTY_MAIN_ROLE_ERROR: 'התפקיד הראשי לא יכול להיות ריק אם יש תפקידים',
        INVALID_ROLES_ERROR: 'תפקידים יכולים להיות רק "כן" או "לא"',
        INVALID_AUTHORIZED_ACTIONS_ERROR: 'פעולות מורשות יכולות להיות רק "כן" או "לא"',
        INVALID_EMAIL_ERROR: 'פורמט האימייל אינו חוקי',
        NO_CHANGES: "אין שינויים לשמור",
        CHANGE_NOT_ALLOWED: "לא ניתן לשנות שדה זה לעובד שנרשם עם משתמש אישי",
        INVALID_MOBILE_ERROR: 'פורמט הטלפון הנייד אינו חוקי (0541234567)',
        INVALID_GENDER_ERROR: 'המין יכול להיות רק "זכר" או "נקבה"',
        UPLOAD_SUCCESS: "העלאת מידע בוצעה בהצלחה",
        ERROR: "העלאת מידע נכשלה",
    },
    upload_image: "העלאת תמונה",
    branch_page: {
        company_name: "שם החברה",
        company_number: "מספר החברה",
    },
    city: "עיר",
    personal_info: "מידע אישי",
    employee_gender: {
        unknown: "עובד",
        female: "עובדת",
        male: "עובד"
    },
    employer_gender: {
        unknown: "מעסיק",
        female: "מעסיקה",
        male: "מעסיק"
    },
    employer: "מעסיק",
    branch: "סניף",
    branch_explain: "משתמש אחד שינהל סניף בודד. יכול להיות משוייך לרשת",
    corporation: "רשת",
    corporation_explain: "משתמש אחד שינהל תחתיו מספר סניפים",
    general: "כללי",
    general_employee: "עובד כללי",
    show: "הצגה",
    change: "שנה",
    active: "פעיל",
    work_day: "יום עבודה",
    langs: "שפות",
    text_404: "הדף שחיפשת לא נמצא",
    loading: "רגע... חושבים",
    hello: "שלום",
    build_schedule: "עריכת סידור",
    create_schedule: "יצירת סידור",
    schedule_structure: "מבנה סידור",
    build_schedule_for_this_week: "בניית סידור לשבוע זה",
    schedule: "סידור עבודה",
    save_changes: "שמירת שינויים",
    display_order: "סדר תצוגה",
    saved: "נשמר",
    plan: {
        this_feature_is_not_available: "חלק זה במערכת אינו זמין במנוי שלך. על מנת להשתמש בשירות זה יש לשדרג למנוי פרימיום"
    },
    edit: "עריכה",
    save: "שמירה",
    structure_saved: "מבנה הסידור נשמר בהצלחה!",
    no_schedule: "טרם פורסם סידור לשבוע זה",
    wage_percent: "אחוז שכר",
    total_work_time: "זמן עבודה",
    time_modal: {
        errors: {
            start_bigger_then_end: "זמן ההתחלה לא יכול להיות אחרי זמן הסיום"
        },
        expected_start_title: "זמן התחלה מתוכנן",
        expected_end_title: "זמן סיום מתוכנן",
        start_title: "זמן התחלה בפועל",
        end_title: "זמן סיום בפועל",
        saving_will_publish: "שמירת השינויים תהפוך אותם לפומביים כיוון שהסידור מפורסם. האם להמשיך?"
    },
    ok: "אישור",
    coupon: "יש לי קופון",
    got_discount: "איזה כיף! קיבלת הנחה של ",
    coupon_not_found:"מצטערים אבל לא הצלחנו לאשר את הקופון שלך, יש לבדוק את הקוד ובמידת הצורך לפנות לתמיכה",
    status: "סטטוס",
    requests: "בקשת משמרות",
    settings: "הגדרות",
    next_week: "שבוע קדימה",
    current_week: "השבוע",
    prev_week: "שבוע אחורה",
    per_month: "לחודש",
    for_yearly_plan: "במנוי שנתי",
    month_free: "(חודשיים מתנה)",
    day_1: "א",
    day_2: "ב",
    day_3: "ג",
    day_4: "ד",
    day_5: "ה",
    day_6: "ו",
    day_7: "ש",
    shift: "משמרת",
    day: "יום",
    day_off: "יום חופש!",
    add_shift: "הוספת משמרת",
    slot_status_off: "כיבוי תצוגה חכמה",
    slot_status_on: "הדלקת תצוגה חכמה",
    remove_shift: "הסרת משמרת",
    action_cant_be_undone: "פעולה זו אינה ניתנת לביטול! האם להמשיך?",
    shift_settings: "הגדרות משמרת",
    cancel: "ביטול",
    close: "סגירה",
    cancel_changes: "ביטול שינויים",
    employees: "עובדים",
    system_settings: "הגדרות מערכת",
    main: "ראשי",
    total: `סה"כ`,
    actions: "פעולות",
    date: "תאריך",
    work_time_end: "זמן סיום",
    work_time_start: "זמן התחלה",
    empty: "ריק",
    delete: "מחיקה",
    add_shifts: "הוספת משמרות",
    remove_shifts: "הסרת משמרות",
    add_slots: "הוספת תקן למשמרת",
    remove_slots: "הסרת תקנים",
    shift: "משמרת",
    submit: "שליחה",
    day: "יום",
    role: "תפקיד",
    roles: "תפקידים",
    main_role: "תפקיד ראשי",
    additional_roles: "תפקידים נוספים",
    as: "בתור",
    self: "עצמי",
    toast: {
        success: "הפעולה בוצעה בהצלחה",
        error: "אירעה שגיאה",
        record_deleted: "רשומה נמחקה בהצלחה",
        record_saved: "רשומה נשמרה בהצלחה",
        record_updated: "רשומה עודכנה בהצלחה",
        employee_created: "עובד נוסף בהצלחה",
    },
    quick_add_employee: {
        title: "הוספת עובדים מהירה",
        via_whatsapp: "whatsapp",
        via_email: "email"
    },
    schedule_setup_flow: {
        explain: 'לפני שנוכל לערוך את הסידור, יש כמה דברים חשובים שצריך לעשות... למשל:',
        we_need: 'צריך',
        also: 'גם',
        we_need_roles: 'שיהיה לפחות תפקיד אחד במערכת',
        we_need_schedule_structure: 'להגדיר את מבנה הסידור',
        next_step: 'לשלב הבא',
        i_am_done: 'סיימתי, בוא נעשה סידור'

    },
    tutorial: {
        title: "מדריך למשתמש",
        next: "הבא",
        skip: "דלג",
        no_need: "אין צורך בהדרכה",
        i_had_enough: "הספיק לי, תודה",
        dont_show: "אל תראה לי את זה יותר",
        got_it: "הבנתי",
        close: "סגור",
        take_me_there: "קח אותי לשם",
        show_me: "רוצה לראות הדגמה",
        remind_me: "הזכר לי בפעם אחרת",

    },
    profile_page: {
        wages_card: {
            title: "שכר מינימום לפי תפקיד"
        }
    },
    not_set: "לא הוגדר",
    wage_percent: "אחוז מהטיפים",
    minimum_wage: "שכר מינימום",
    schedule_page: {
        create_new_schedule: "סידור חדש",
        tips: "טיפים",
        edit_schedule: "עריכת סידור",
        create_new_confirmation_msg: "המשך יצור סידור חדש בהתאם למבנה הסידור המוגדר במערכת. האם להמשיך?",
        publish: "פרסם סידור",
        unpublish: "הסר סידור",
        are_you_sure_published: "אתה עומד לפרסם את הסידור. פעולה זו גם תבצע שמירה של כל השינויים אם יש כאלה, האם להמשיך?",
        are_you_sure_draft: "אתה עומד לבטל את פירסום הסידור, האם להמשיך?",
        are_you_sure_save_changes: "סידור זה פורסם, שמירת השינויים תהפוך אותם לפומביים. האם להמשיך?",
        success: {
            update: {
                draft: 'ביטול פרסום הסידור בוצע בהצלחה',
                published: 'הסידור פורסם',
            }
        }
    },
    tips: {
        title: "טיפים",
        total: 'סה"כ טיפים',
        total_time: 'סה"כ זמן עבודה במשמרת',
        recalculate: "חישוב חלוקת טיפים",
        didactions: 'סה"כ ניכויים',
        publish: "פרסם",
    },
    total_per_hour: 'סה"כ לשעה',
    replacements_page: {
        title: "החלפות",
        requester: "מבקש",
        shift_date: 'תאריך',
        shift_title: 'משמרת',
        status: 'סטטוס בקשה',
        reject: "סירוב",
        approve: "אישור",
        cancel: "ביטול",
        pending: "ממתין",
        approved: "אושר",
        offer_replacement: "התנדב",
        rejected: "לא אושר",
        expired: "פג תוקף",
        canceled: 'בוטל ע"י העובד',
        no_options: "אין מחליפים",
        no_replacements: "אין החלפות",
        approval_confirmation_msg: "אתה עומד לאשר חילוף זה, האם לאשר?"
    },
    "tutorial_start_title": "מדריך למשתמש",
    "tutorial_start_body": "אנחנו יודעים שחוויות חדשות יכולות להיות מבלבלות לפעמים, אז רצינו קצת לעזור ולעשות סדר בדברים. מדי פעם נצוץ פה אם תרצו כדי להסביר דברים שנראה לנו שכדאי שתדעו, אבל בסוף זו בחירה שלכם",
    "toggle_sidebar_title": "תפריט צד",
    "toggle_sidebar_body": "לכל עמוד יש תפריט משלו, הוא קטן כדי שיהיה נוח, אבל עם הכפתור הזה סידרנו שתוכלו להגדיל או להקטין את התפריט מתי שנוח לכם",
    "schedule_nav_item_title": "",
    "schedule_nav_item_body": "",
    "settings_nav_item_title": "",
    "settings_nav_item_body": "",
    wages: {
        title: "שכר",
        from_date: "מתאריך",
        to_date: "עד תאריך",
        for_employee: "עבור עובד",
        wage_type: "סוג שכר",
        tips: "טיפים",
        salery: "שכר",
        didactions: "ניכויים",
        all: "הכל",
    },
    requests_page: {
        title: "הגשת בקשת משמרות",
        requests_saved: {
            title: "נשמר",
            message: "בקשת המשמרות הוגשה בהצלחה!",
        },
        no_schedule: "עוד לא ניתן להגיש משמרות לשבוע זה"
    },
    sidebar: {
        settings: {
            branch: "הגדרות עסק",
            user: "הגדרות משתמש",
        },
        schedule: {
            replacements: "החלפות"
        }
    },
    side_tools: {
        ask_replacment: {
            btn_title: "בקש החלפה",
            confirm_btn: "כן",
            are_you_sure: "אתה עומד לבקש החלפה. רק מנהל יוכל לבטל בקשה זו. להמשיך?",
        }
    },
    navigation: {
        btns: {
            full_screen: "מסך מלא",
            exit_full_screen: "יציאה ממסך מלא",
            replacements: "החלפות"
        }
    },
    email: "דואר אלקטרוני",
    username: "שם משתמש",
    name: "שם",
    username: "שם משתמש",
    first_name: "שם פרטי",
    last_name: "שם משפחה",
    mobile: "טלפון",
    birth_date: "תאריך לידה",
    want_to_change_image: "שינוי תמונה",
    hide: "הסתר",
    authorized_actions: {
        title: "ניהול הרשאות",
        manage_schedule: "ניהול סידור",
        view_schedule: "צפייה בסידור",
        manage_employee: "ניהול עובדים",
        branch_manager: "מנהל סניף",
    },
    contact_us: {
        title: "צור קשר",
        subject: "נושא",
        other: "אחר",
        message: "הודעה",
        subjects: {
            bug: "תקלה",
            subscription: "מנוי",
            new_feature: "הצעות לפיצ'רים",
        },
        mobile_error: "מספר טלפון לא תקין",
        email_error: "דואר אלקטרוני לא תקין",
        subject_error: "יש למלא נושא",
        message_error: "יש למלא הודעה",
        message_sent: "הודעה נשלחה בהצלחה",
    },
    branch_manager: "מנהל סניף",
    scheduling_settings: {
        title: "הגדרות שיבוץ",
        max_days: "מקסימום ימים",
        min_days: "מינימום ימים",
        max_shifts: "מקסימום משמרות",
        min_shifts: "מינימום משמרות",
        min_shifts_requests: "מינימום בקשת משמרות",
        min_days_requests: "מינימום בקשת ימים",
        allow_double_shifts: "ניתן לשבץ כפולות",
    },
    user_page: {
        no_roles_selected: "לא נבחרו תפקידים משניים",
        edit_roles: "בחירת תפקידים",
        branch_management: {
            quit: "התפטרות",
            fire: "פיטורים",
            hire: "גייס",
            apply: "הגשת מועמדות",
        },
        schedule_settings: {
            title: 'הגדרות שיבוץ'
        }

    },
    gender: {
        unknown: "לא נבחר",
        title: "מגדר",
        male: "זכר",
        female: "נקבה",
    },
    fake_employee: {
        first_name: "עובד",
        last_name: 'חדש'
    },
    login: {
        title: "התחברות",
        new_or_exisiting: "משתמש חדש / משתמש קיים?",
        doesnt_matter: "לא באמת משנה, עשינו את זה קל",
        login_here: "כאן מתחברים",
        welcome: "ברוך הבא!",
        before_you_join: "לפני שאתה מתחבר למערכת חשוב לנו שתדע",
        you_are_about_to_join: "הוזמנת למערכת להצטרף לעובדים של",
        i_know_let_me_in: "אני עובד שם, תן להכנס",
        dont_know_them_sign_in: "לא מכיר אותם, רוצה להתחבר בלי להצטרף לחברה"
    },
    welcome: {
        title: "ברוכים הבאים",
        all: {},
        new_user: {
            greeting: "איזה כיף שבחרתם להצטרף אלינו! אנחנו ממש כמה צעדים מלהתחיל לעבוד. אבל קודם, יש כמה דברים שאנחנו ממש צריכים לדעת",
            this_is_you: "אם כל הפרטים נכונים ומלאים, אפשר להמשיך"
        },
    },
    complete_user_setup_btn_text: "קדימה לעבודה!",
    complete_branch_setup_btn_text: "בוא נתחיל!",
    you: {
        unknown: "אתה",
        female: "את",
        male: "אתה",
    },
    min_assingments_shifts: "מינימום משמרות בשבוע",
    min_assingments_days: "מינימום ימים בשבוע",
    max_assignments_shifts: "מקסימום משמרות בשבוע",
    max_assignments_days: "מקסימום ימים בשבוע",
    min_requests_shifts: "מינימום משמרות להגשה",
    min_requests_days: "מינימום ימים להגשה",
    expected_time: "זמן מתוכנן",
    actual_time: "זמן בפועל",
    employees_settings_lists: {
        wage_type: "סוג שכר",
        wage_percent: "אחוז שכר מהטיפים",
        minimum_wage: "שכר מינימום",
        roles: {
            title: "תפקידים",
            add: "הוספת תפקיד",
            remove: "הסרת תפקיד",
            name: "שם תפקיד",
            tag: "הצעות",
            resturant: {
                manager: 'מנהל',
                waiter: 'מלצר',
                bartender: 'ברמן',
                host: 'מארחת',
                cold_kitchen: 'פס קר',
                hot_kitchen: 'פס חם',
                checker: "צ'קר",
            }

        },
        employees: {
            title: "עובדים",
            create: "צור עובד",
            invite: "הזמנת עובד",
        }
    },
    employees_list: "עובדים",
    wage_type: "סוג שכר",
    roles_list: "תפקידים",
    schedule_settings: "הגדרות סידור",
    general_actions: "פעולות כלליות",
    select: "בחירה",
    page_not_for_mobile: 'מסך זה אינו מותאם לצפיה במובייל, ניתן לפתוח מסך זה במחשב',
    langsGeneral
}

export default HE