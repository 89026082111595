import React from 'react';
import { Form, FormGroup, FormLabel, FormControl, Badge, Button } from 'react-bootstrap';
import { Trans } from '../../../lang/Trans';
import './BranchPage.css'
import { useNavigate } from 'react-router-dom';
const BranchInfo = ({ branch, isEditable, setBranchData, handleChange, errors }) => {
    const navigate = useNavigate();
    if(!branch) {navigate('/settings/branch');return null}

    const { name, company_number, city, ownerName, corporationName } = branch;
    
    return (
        <div className="branch-info-card card col p-3 d-flex flex-wrap align-self-stretch">
            <FormGroup className="col-xl-5 mx-1">
                <FormLabel htmlFor="name"><Trans keys={"branch_page.company_name"} /></FormLabel>
                <FormControl type="text" id="name"
                    value={name}
                    onChange={handleChange}
                    disabled={!isEditable}
                    isInvalid={!!errors.name}
                    name="name"
                />
                {errors.name && <Badge className='bg-danger'>{errors.name}</Badge>}
            </FormGroup>
            <FormGroup className="col-xl-5 mx-1">
                <FormLabel htmlFor="company_number"><Trans keys={"branch_page.company_number"} /></FormLabel>
                <FormControl type="text" id="company_number"
                    value={company_number}
                    onChange={handleChange}
                    disabled={!isEditable}
                    isInvalid={!!errors.company_number}
                    name="company_number"
                />
                {errors.company_number && <Badge className='bg-danger'>{errors.company_number}</Badge>}
            </FormGroup>
            <FormGroup className="col-xl-5 mx-1">
                <FormLabel htmlFor="city"><Trans keys={"city"} /></FormLabel>
                <FormControl type="text" id="city"
                    value={city}
                    onChange={handleChange}
                    disabled={!isEditable}
                    isInvalid={!!errors.city}
                    name="city"
                />
                {errors.city && <Badge className='bg-danger'>{errors.city}</Badge>}
            </FormGroup>
            <FormGroup className="col-xl-5 mx-1">
                <FormLabel htmlFor="owner"><Trans keys={"owner"} /></FormLabel>
                <FormControl type="text" id="owner"
                    value={ownerName}
                    disabled={true}
                />
            </FormGroup>
            {corporationName && <FormGroup className="col-xl-5 mx-1">
                <FormLabel htmlFor="corporation"><Trans keys={"corporation"} /></FormLabel>
                <FormControl type="text" id="corporation"
                    value={corporationName}
                    readOnly={true}
                    name="corporationName"
                />
            </FormGroup>}
        </div>
    );
};

export default BranchInfo;
