import { Icon } from '@mdi/react';
import { mdiArrowLeftBold, mdiArrowRightBold, mdiCalendarCheck, mdiCalendarEdit, mdiWindowMaximize, mdiWindowMinimize } from '@mdi/js';
import React, { useEffect, useState } from 'react'

import infoIcon from '../../../../assets/images/cursors/infoIcon.svg'
import sbIcon from '../../../../assets/images/cursors/sbIcon.svg'
import starIcon from '../../../../assets/images/cursors/starIcon.svg'
import traingingIcon from '../../../../assets/images/cursors/traingingIcon.svg'

import './Navigation.css'
import { Trans } from '../../../../lang/Trans';
import { useWorkey } from '../../../../WorkeyContext';
import { IconSize, isSmallScreen, makeApiRequest } from '../../../../utils/utils';
import ActionAs from './actions-as/ActionAs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import DatePicker from "react-datepicker";
import { isWeekDay } from '../../../../utils/scheduling_utils';
import moment from 'moment';

const Navigation = (props) => {
    const { globalState, setGlobalState } = useWorkey()
    const {
        mark,
        setMark,
        setActAsUser,
        saveWeek,
        location,
        createNewWeek,
        currentWeek,
        setCurrentWeek,
        toggleToolsMenu,
        edit,
        toolsboxMenu,
        fullScreen,
        setFullScreen,
        setShowTips } = props;
    const [selectedDate, setSelectedDate] = useState(null);
    const [minimize, setMinimize] = useState(false)
    useEffect(() => {
        if (currentWeek?.days?.[0]?.date) {
            setSelectedDate(moment(currentWeek?.days?.[0]?.date).format('yyyy-MM-DD'))
        }
    }, [currentWeek?.id, currentWeek?.days?.[0]?.date])

    const showSelectedCurrentWeek = () => {
        let new_selected = moment(selectedDate).startOf('week').format('yyyy-MM-DD')
        setSelectedDate(new_selected)
        changeWeekRef(moment(new_selected).week() - moment().week())

    }


    const changeWeekRef = async (direction) => {
        const newState = { ...globalState };
        const week_ref = (newState.weekRef || 0) + direction;
        newState.weekRef = (direction === 0) ? 0 : week_ref;
        await setGlobalState(newState);
        // let new_date
        // if(direction === 0) {
        //     new_date = new Date(lastFirstDay)
        // } else {
        //     new_date = newDate.setDate(newDate.getDate() + (7 * direction))
        // }
        // await setSelectedDate(new_date)
    }

    const togglePublish = async (t = false) => {
        let new_status = currentWeek.status === "published" ? "draft" : "published"
        if (!t) {
            toast((t) => <div>
                <div><Trans keys={`schedule_page.are_you_sure_${new_status}`} /></div>
                <div className='d-flex flex-row justify-content-center mt-2'>
                    <div className='btn btn-sm btn-app-green mx-2' onClick={() => togglePublish(t)}><Trans keys="yes" /></div>
                    <div className='btn btn-sm btn-app-red mx-2' onClick={() => toast.dismiss(t.id)}><Trans keys={"cancel"} /></div>
                </div>
            </div>, { duration: 10 * 1000, position: 'top-right' })
        } else {
            toast.dismiss(t.id)
            const req = await makeApiRequest(globalState, setGlobalState, `/weeks/${currentWeek.id}`, 'PUT', {
                status: new_status
            })
            const data = req[0]
            const res = req[1]
            if (res.status === 200 || res.status === 201) {
                setCurrentWeek({ ...currentWeek, status: data.status })
                toast.success(<Trans keys={`schedule_page.success.update.${new_status}`} />, { duration: 5000, position: 'top-right', className: 'app-green-txt' })
            } else {
                toast.error(data.error ||<Trans keys={ 'server_error'} />, { duration: 5000, position: 'top-right', className: 'app-red-txt' })
            }
        }
    }



    return (
        <div className='navigation-tools-container'>
            {minimize ?
                <div><div className='minimize-btn' onClick={() => setMinimize(false)}><Icon path={mdiArrowLeftBold} size={IconSize} /></div></div>
                :
                <>
                    {/* <Icon path={minimize ? mdiWindowMaximize : mdiWindowMinimize} size={1} onClick={() => setMinimize(!minimize)} /> */}
                    {location === 'requests' && <div className='action-as'><ActionAs setActAsUser={setActAsUser} /></div>}
                    <div className='tools-btns'>
                        {location === 'schedule' && isSmallScreen() ? <div className='full-screen-btn btn btn-sm btn-secondary' onClick={() => setFullScreen(!fullScreen)}>
                            <Trans keys={`navigation.btns.${fullScreen ? 'exit_' : ''}full_screen`} />
                        </div> : null}
                        {currentWeek && edit ? <>
                            {!mark &&
                                <>
                                    <div className='btn btn-sm btn-success save-changes'
                                        onClick={() => saveWeek()}>
                                        <Trans keys={"save_changes"} />
                                    </div>
                                    <div className={`btn btn-sm ${toolsboxMenu ? 'btn-dark' : 'btn-app-yellow text-dark'}`} onClick={() => toggleToolsMenu()}>
                                        <Icon path={toolsboxMenu ? mdiCalendarCheck : mdiCalendarEdit} size={IconSize} />
                                    </div>
                                    <div className='btn btn-sm btn-app-blue' onClick={() => togglePublish()}><Trans keys={`schedule_page.${currentWeek?.status === "published" ? "unpublish" : "publish"}`} /></div>
                                    {currentWeek?.status !== "published" && <div className='btn btn-sm btn-app-light-blue' onClick={() => createNewWeek('POST')}><Trans keys={'schedule_page.create_new_schedule'} /></div>}
                                    {currentWeek?.status === "published" && <div className='btn btn-sm btn-app-dollar-green' onClick={() => setShowTips(true)}><Trans keys={'schedule_page.tips'} /></div>}
                                </>
                            }
                            {mark && <>
                                <div className='btn btn-sm btn-secondary text-light fw-bold btn-mark'
                                    onClick={() => setMark('sb')} >
                                    <img src={sbIcon} className='text-light' width={'18px'} />
                                    {/* <Icon path={sbIcon} size={IconSize} /> */}
                                </div>
                                <div className='btn btn-sm btn-info text-light fw-bold btn-mark'
                                    onClick={() => setMark('info')} >
                                    <img src={infoIcon} className='text-light' width={'18px'} />
                                    {/* <Icon path={sbIcon} size={IconSize} /> */}
                                </div>
                                <div className='btn btn-sm btn-app-blue text-light fw-bold btn-mark'
                                    onClick={() => setMark('training')}>
                                    <img src={traingingIcon} className='text-light' width={'18px'} />
                                </div>
                            </>}
                            {true && <div className={`btn btn-sm ${mark ? 'btn-dark' :'btn-app-red'} text-light btn-mark`} onClick={() => setMark(mark? false : 'star')}>
                                <img src={starIcon} width={'18px'} />
                            </div>}
                        </> : <></>}
                        {/* {location !== 'requests' && currentWeek?.replacements?.length > 0 && <div className='btn btn-sm btn-app-dark-blue' onClick={toggleReplacements}><Trans keys={'navigation.btns.replacements'} /></div>} */}
                    </div>

                    <div className='dates'>
                        {true ?
                            <div className='date-picker-container'>
                                <div className='date-picker-input'>
                                    <input
                                        type="date"
                                        autoFocus={false}
                                        locale={'he'}
                                        value={selectedDate || moment().format('yyyy-MM-DD')}
                                        className='from-control ms-2'
                                        onChange={(date) => { setSelectedDate(date.target.value) }} />
                                    <div className='btn btn-sm btn-primary pick-date' onClick={() => showSelectedCurrentWeek()}>{<Trans keys={"show"} />}</div>
                                </div>
                            </div>
                            : <></>}

                        <div className='navigation-btns'>
                            <div className='btn btn-sm btn-outline-primary' onClick={() => changeWeekRef(-1)}>
                                <Icon path={mdiArrowRightBold} size={IconSize} />
                            </div>
                            <div className='btn btn-sm btn-outline-primary' onClick={() => changeWeekRef(0)}><Trans keys={"current_week"} /></div>
                            <div className='btn btn-sm btn-outline-primary' onClick={() => changeWeekRef(1)}>
                                <Icon path={mdiArrowLeftBold} size={IconSize} />
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    )
}

export default Navigation