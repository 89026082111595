import React, { useState } from 'react'
import './ProfileInfoCard.css'
import { Trans } from '../../../../lang/Trans'
import Select from 'react-select'
import { makeApiRequest } from '../../../../utils/utils'
import { useWorkey } from '../../../../WorkeyContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
const ProfileInfoCard = ({ user, showEdit }) => {
  const { globalState, setGlobalState } = useWorkey()
  const [userInfo, setUserInfo] = useState(user)
  const [editMode, setEditMode] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const navigate = useNavigate()
  const genderOptions = [
    { value: "unknown", label: <Trans keys={"unknown"} /> },
    { value: "female", label: <Trans keys={"gender.female"} /> },
    { value: "male", label: <Trans keys={"gender.male"} /> },
  ]
  const validateUser = (user) => {
    let errors = {}
    const { email, first_name, last_name, username, gender, mobile, birth_date } = userInfo;
    if (!email || email.length < 1) {
      errors['email'] = 'errors.required'
    } else {
      // Todo: validate email
    }
    if (!first_name) {
      errors['first_name'] = <Trans keys={"errors.required"} />
    } else {
      // Todo: validate first_name
    }

    if (last_name && last_name.length > 0) {
      // Todo: validate last_name is longer then 2 characters
    }

    if (username && username.length > 0) {
      // Todo: validate username is longer then 2 characters
    }
    if (gender !== 'unknown' && gender !== 'male' && gender !== 'female') {
      errors['gender'] = <Trans keys={"errors.gender_type_error"} />
    }
    if (mobile && mobile.length > 0) {
      // Todo: validate mobile is longer then 2 characters
    }
    if (birth_date && birth_date.length > 0) {
      // Todo: validate birth_date is a valid date and over 16 years old
    }
    setFormErrors(errors)
    return Object.keys(errors).length < 1

  };
  const save = async () => {
    setFormErrors({})
    let fakeUser = !userInfo.id
    delete userInfo.token
    delete userInfo.branch
    delete userInfo.tutorial
    if (validateUser(userInfo)) {
      const path = fakeUser ? `/users/${globalState.user.id}/fake` : `/users/${userInfo.id}`
      const req = await makeApiRequest(globalState, setGlobalState, path, "PUT", userInfo)
      const res = req[1]
      const data = req[0]
      if (res.status === 201) {
        if (data.user.status === 'fake') {
          setGlobalState({ ...globalState, user: { ...globalState.user, branch: { ...globalState.user.branch, employees: [...globalState.user.branch.employees, data.user] } } })
          toast.success(<Trans keys={'toast.employee_created'} />)
          navigate(`/user/${data.user.id}`)
        } else {
          setUserInfo(data.user)
          toast.success(<Trans keys={'toast.record_saved'} />)
        }
        setEditMode(false)
      } else {
        toast.error(data.error || <Trans keys={'toast.feild_to_update.user_data'} />);
      }
    }
  }

  const cancel = () => {
    setUserInfo(user)
    setEditMode(false)
  }
  return (
    <>
      <div className='user-section-container card p-3'>
        <div className='text-center mb-2'><Trans keys={'personal_info'} /></div>
        <div className='user-section-header d-flex flex-row justify-content-center'>
          {(showEdit && !editMode) && <div className='btn btn-sm btn-app-blue' onClick={() => setEditMode(true)}>
            <Trans keys={'edit'} />
          </div>}
          {(editMode) && <div className='btn btn-sm btn-app-red' onClick={() => cancel()}>
            <Trans keys={'cancel'} />
          </div>}
          {(editMode) && <div className='btn btn-sm btn-app-green' onClick={() => save()}>
            <Trans keys={'save'} />
          </div>}
        </div>
        <div className='user-profile-info-form mt-3'>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" aria-describedby="email"
                disabled={userInfo.status !== 'fake' || !editMode} required={true}
                value={userInfo.email} onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })} />
              {formErrors?.email && <div id="email" className="form-text app-red-txt"><Trans keys={formErrors.email} /></div>}
              {/* <div id="email_error" className="form-text"><Trans keys={'well_never_sher_you_email'} /></div> */}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="username" className="form-label"><Trans keys={'username'} /></label>
              <input type="text" className="form-control" id="username" aria-describedby="username"
                disabled={!editMode}
                value={userInfo.username} onChange={(e) => setUserInfo({ ...userInfo, username: e.target.value })} />
              {formErrors?.username && <div id="username_error" className="form-text app-red-txt"><Trans keys={formErrors.username} /></div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="first_name" className="form-label"><Trans keys={'first_name'} /></label>
              <input type="text" className="form-control" id="first_name" aria-describedby="first_name"
                disabled={!editMode}
                value={userInfo.first_name} onChange={(e) => setUserInfo({ ...userInfo, first_name: e.target.value })} />
              {formErrors?.first_name && <div id="first_name_error" className="form-text app-red-txt"><Trans keys={formErrors.first_name} /></div>}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="last_name" className="form-label"><Trans keys={'last_name'} /></label>
              <input type="text" className="form-control" id="last_name" aria-describedby="last_name"
                disabled={!editMode}
                value={userInfo.last_name} onChange={(e) => setUserInfo({ ...userInfo, last_name: e.target.value })} />
              {formErrors?.last_name && <div id="last_name_error" className="form-text app-red-txt"><Trans keys={formErrors.last_name} /></div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="mobile" className="form-label"><Trans keys={'mobile'} /></label>
              <input type="telephone" className="form-control" id="mobile" disabled={!editMode}
                value={userInfo.mobile} onChange={(e) => setUserInfo({ ...userInfo, mobile: e.target.value })} />
              {formErrors?.mobile && <div id="mobile_error" className="form-text app-red-txt"><Trans keys={formErrors.mobile} /></div>}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="birth_date" className="form-label"><Trans keys={'birth_date'} /></label>
              <input type="date" className="form-control" id="birth_date" aria-describedby="birth_date"
                disabled={!editMode}
                value={userInfo.birth_date}
                onChange={(e) => setUserInfo({ ...userInfo, birth_date: e.target.value })} />
              {formErrors?.birth_date && <div id="birth_date_error" className="form-text app-red-txt"><Trans keys={formErrors.birth_date} /></div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="gender" className="form-label"><Trans keys={'gender.title'} /></label>
              <Select options={genderOptions}
                isDisabled={!editMode}
                value={genderOptions.find(option => option.value === userInfo.gender)}
                onChange={(selectedOptions) => setUserInfo({ ...userInfo, gender: selectedOptions.value })} />
              {formErrors?.gender && <div id="gender_error" className="form-text app-red-txt"><Trans keys={formErrors.gender} /></div>}
            </div>
          </div>
        </div>
      </div>
    </>


  )
}

export default ProfileInfoCard