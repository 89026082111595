import React, { useState, useEffect } from 'react';
import PlanSelection from './PlanSelection';
import './PlanPicker.css';
import { useWorkey } from '../../WorkeyContext';
import { toast } from 'react-hot-toast';
import { makeApiRequest } from '../../utils/utils';
import { trans } from '../../lang/translation';
import moment from 'moment';

const PlanPicker = () => {
    const { globalState, setGlobalState } = useWorkey();
    const featurese = {
        menual_schedule: { label: 'ממשק חכם לשיבוץ ידני', price: 1, },
        requests: { label: 'הגשת משמרות עובדים עם עדיפויות והתחייבויות', price: 1 },
        auto_schedule: { label: 'שיבוץ אוטומטי המתחשב בצרכי המערכת והעובדים', price: 3, },
        times: { label: 'מערכת ניהול שכר עובדים', price: 1, },
        replacements: { label: 'ממשק החלפות עובדים באישור מנהל', price: 1, },
        wages: { label: 'מערכת ניהול טיפים', price: 1, },
    };

    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [maxEmployees, setMaxEmployees] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [discount, setDiscount] = useState(0);

    const CODES = [
        { code: 'OMRI_DEV', discount: 50 }, { code: 'workey_oldies', discount: 100 }
    ]

    useEffect(() => {
        setMaxEmployees(globalState.user.branch.max_employees || null);
        setSelectedFeatures(globalState.user.branch.active_subscriptions.filter(subscription => !subscription.expiration_date).map((subscription) => subscription.subscription_type));
    }, [])

    const handleFeatureSelect = (e) => {
        const selectedFeature = e.target.value;
        if (selectedFeatures.includes(selectedFeature)) {
            if (selectedFeature === 'menual_schedule') {
                if (!selectedFeatures.includes('auto_schedule') && !selectedFeatures.includes('times') &&
                    !selectedFeatures.includes('replacements') && !selectedFeatures.includes('wages')) {
                    setSelectedFeatures(selectedFeatures.filter(feature => feature !== selectedFeature));
                }
            } else {
                setSelectedFeatures(selectedFeatures.filter(feature => feature !== selectedFeature));
            }
        } else {
            if ((selectedFeature === 'auto_schedule' || selectedFeature === 'times' || selectedFeature === 'replacements' || selectedFeature === 'wages')
                && !selectedFeatures.includes('menual_schedule')) {
                setSelectedFeatures([...selectedFeatures, selectedFeature, 'menual_schedule', 'requests']);
            } else {
                setSelectedFeatures([...selectedFeatures, selectedFeature]);
            }

        }
    };

const calculateTotalPrice = () => {
    const selectedFeatures = globalState.user.branch.active_subscriptions
        .filter(subscription => !subscription.expiration_date)
        .map(subscription => subscription.subscription_type);

    if (selectedFeatures.every(feature => feature === selectedFeatures[0])) {
        if (globalState.user.branch.subscriptions_price && globalState.user.branch.subscriptions_price > 0) {
            return globalState.user.branch.subscriptions_price;
        }
    }

    let price = 0;

    if (selectedFeatures.includes('menual_schedule') && maxEmployees > 10) {
        price += featurese['menual_schedule'].price;
    }

    ['auto_schedule', 'times', 'replacements', 'wages'].forEach(feature => {
        if (selectedFeatures.includes(feature)) {
            price += featurese[feature].price;
        }
    });

    let totalPrice = (price * (maxEmployees || 0)).toFixed();

    if (totalPrice > 399) {
        totalPrice = 399;
    } else if (totalPrice > 0 && totalPrice < 89) {
        totalPrice = 89;
    } else {
        totalPrice = totalPrice * (maxEmployees || 0);
    }

    return ((totalPrice * ((100 - discount) / 100))).toFixed();
};

    const handleSubmit = async () => {
        const req = await makeApiRequest(globalState, setGlobalState, `/branches/${globalState.user.branch_id}/subscriptions`, 'POST', {
            subscriptions: selectedFeatures, max_employees: maxEmployees, subscriptions_price: calculateTotalPrice(),
        })
        const res = req[1]
        const data = req[0]
        if (res.status === 200) {
            setGlobalState({ ...globalState, user: { ...globalState.user, branch: { ...globalState.user.branch, active_subscriptions: data } } })
            toast.success((t) => trans(globalState, 'toast.success'))
        } else {
            toast.error((t) => trans(globalState, 'toast.error'))
        }

    }


    const findSubscription = (type) => {
        let subscription = globalState.user.branch.active_subscriptions.find((subscription) => subscription.subscription_type === type && !subscription.expiration_date);
        if (!subscription) {
            subscription = globalState.user.branch.active_subscriptions.find((subscription) => subscription.subscription_type === type);
        }
        return subscription || null
    }

    const checkCoupon = () => {
        let found_coupon = CODES.find(code => code.code === coupon)
        if (found_coupon) {
            setDiscount(found_coupon.discount);
            toast.success((t) => trans(globalState, 'got_discount') + found_coupon.discount + '%' + '!')
        } else {
            toast.error((t) => trans(globalState, 'coupon_not_found'))
        }
    }
    const insertCoupon = () => {
        toast((t) => {
            return <div className='p-5'>
                <div>
                    <input type='text' className='form-control' placeholder={trans(globalState, 'coupon_code')} onChange={e => setCoupon(e.target.value)} />
                </div>
                <div className='d-flex flex-row justify-content-around mt-3'>
                    <div className='btn btn-app-red' onClick={() => {
                        setCoupon(null);
                        toast.dismiss(t.id);
                    }}>{trans(globalState, 'cancel')}</div>
                    <div className='btn btn-app-blue' onClick={() => { toast.dismiss(t.id); checkCoupon() }}>{trans(globalState, 'ok')}</div>
                </div>
            </div>
        })
    }

    return (
        <div className='plan-picker m-3'>
            <div className='p-5 card'>
                <form>
                    <div className='form-group d-flex flex-row col-6 align-items-baseline'>
                        <label htmlFor='maxEmployees' className='col-6'>מספר מקסימלי של עובדים:</label>
                        <select id='maxEmployees' name='maxEmployees' required className='form-control' onChange={e => setMaxEmployees(e.target.value)}>
                            <option value='select'>בחר</option>
                            <option value='10' selected={maxEmployees === 10}>10</option>
                            <option value='20' selected={maxEmployees === 20}>20</option>
                            <option value='30' selected={maxEmployees === 30}>30</option>
                            <option value='40' selected={maxEmployees === 40}>40</option>
                            <option value='50' selected={maxEmployees === 50}>50</option>
                            <option value='60' selected={maxEmployees === 60}>60</option>
                            <option value='70' selected={maxEmployees === 70}>70</option>
                            <option value='80' selected={maxEmployees === 80}>80</option>
                            <option value='90' selected={maxEmployees === 90}>90</option>
                            <option value='100' selected={maxEmployees === 100}>100</option>
                            <option value='0' selected={maxEmployees === 0}>100+</option>
                        </select>
                    </div>

                    <div className='form-group'>
                        <label htmlFor='features'>תוספים:</label>
                        {Object.entries(featurese).map(([key, value]) => (
                            <div className='form-check-reverse' key={key}>
                                <input
                                    type='checkbox'
                                    id={key}
                                    name='features'
                                    value={key}
                                    checked={selectedFeatures.includes(key) || value.check}
                                    className='form-check-input'
                                    onChange={handleFeatureSelect}
                                />
                                <label htmlFor={key} className='form-check-label'>{value.label} {findSubscription(key)?.expiration_date &&
                                    <span className='fw-bold'> - בתוקף עד: {moment(findSubscription(key).expiration_date).format('DD/MM/YYYY')}</span>}</label>
                            </div>
                        ))}
                    </div>

                    <div className='form-group text-center'>
                        <label htmlFor='totalPrice'>מחיר לחודש:</label>
                        <span id='totalPrice' className='mx-2'>{calculateTotalPrice()} ₪</span>
                    </div>
                    <div className='text-center fs-6 text-muted'>* חיוב ראשון יחסי ולאחר מכן בעבור כל חודש קלנדרי</div>
                    <div className='text-center mt-3'>
                        <div className='mx-2 btn btn-app-light-blue' onClick={() => insertCoupon()}>{trans(globalState, 'coupon')}</div>
                        <div className='mx-2 btn btn-app-blue' onClick={handleSubmit}>{trans(globalState, 'ok')}</div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PlanPicker