export const initialTools = {
    add_shifts: false,
    remove_shifts: false,
    shift_settings: false,
    add_slots: false,
    remove_slots: false,
    slot_status_off: false
}

export const toolsSettings = {
    add_shifts: {
        color: "app-green"
    },
    remove_shifts: {
        color: "app-red"
    },
    shift_settings: {
        color: "secondary"
    },
    add_slots: {
        color: "app-green"
    },
    remove_slots: {
        color: "app-red"
    },
    slot_status_off: {
        color: "dark"
    }
}