import React, { useEffect } from 'react'
import './Loading.css'
import { Trans } from '../../lang/Trans'
import logo from '../../assets/images/logo.svg'
import Brain from './Brain'
const Loading = ({ transperent }) => {
  return (
    <>
      <div className='loading-screen'>
        <div className='logo-continer'><img className='logo' src={logo} /></div>
          <div className='loading-text-container fw-bold'><Trans keys={'loading'} /></div>
          <Brain/>        
        

      </div>
    </>
  )
}

export default Loading