import { useWorkey } from "../WorkeyContext";
import State from "./current_week_utils";
import { makeApiRequest, moment } from "./utils";
export const rebuildWeek = async (week, globalState) => {
    let new_week = { ...week }

    if (new_week.days) {

        for (let day_i = 0; day_i < new_week.days?.length; day_i++) {
            new_week.days[day_i]['shifts'] ||= []
            const day = new_week.days[day_i];
            new_week.days[day_i]['shifts'] = new_week.shifts?.filter((shift) => shift.day_id == day.id)
            for (let shift_i = 0; shift_i < day?.['shifts']?.length; shift_i++) {
                let shift = day['shifts'][shift_i];
                shift = await getShiftAssignmentOptions(globalState, new_week, shift)
                new_week.days[day_i]['shifts'][shift_i]['slots'] ||= []
                new_week.days[day_i]['shifts'][shift_i]['slots'] = new_week?.slots?.filter((slot) => slot.shift_id == shift.id)
            }
        }
    }

    return new_week
}

const getShiftAssignmentOptions = async (globalState, currentWeek, shift) => {
    let roles = [...globalState.user.branch?.roles || []]
    const employees = [...globalState.user.branch?.employees || []]
    let main_employees = {}
    let not_main_employees = {}
    let other_employees = {}
    await roles.forEach(role => {
        // let slots = shift.slots?.filter(s => s.role_id == role.id)
        // const employees = [...global_employees].filter(e => !slots?.find(s => s.user_id == e.id))
        main_employees[role.id] = employees.filter(e => e.main_role?.id == role.id).filter(e => currentWeek.requests?.find(req => req.shift_id === shift.id && req.user_id === e.id)).map(e => e.id)
        not_main_employees[role.id] = employees.filter(e => e.roles?.find(r => r.id == role.id))?.filter(e => currentWeek.requests?.find(req => req.shift_id === shift.id && req.user_id === e.id)).map(e => e.id)
        other_employees[role.id] = employees.filter(e => currentWeek.requests?.find(req => req.shift_id === shift.id && req.user_id === e.id)).filter(e => !e.roles.find(r => r.id != role.id)).map(e => e.id)
    })


    
    shift['main_employees'] = main_employees
    shift['not_main_employees'] = not_main_employees
    shift['other_employees'] = other_employees
    return shift
}

export const addShift = async (currentWeek, setCurrentWeek, globalState, day, setGlobalState) => {
    const req = await makeApiRequest(globalState, setGlobalState, '/shifts', "POST", newShift(day, globalState))
    const data = req[0]
    const res = req[1]
    if (res.status === 201) {
        let new_state = { ...globalState }
        currentWeek.shifts.push(data)
        currentWeek.days[currentWeek.days.findIndex(d => d.id == data.day_id)].shifts.push(data)
        await setCurrentWeek(currentWeek)
        // setTimeout(() => {
        const element = document.getElementsByClassName('smart-schedule-display')[0]; // Replace 'myDiv' with the ID of your div element
        // Scroll the div to the bottom
        element.scrollTo({
            top: element.scrollHeight,
            behavior: 'smooth'
        });
        // }, 3500)

    }
}

export const newShift = (day, globalState) => {
    const num = day.shifts.length
    return {
        id: `day_id_${day.id}_shift_num_${num}`,
        branch_id: globalState.user.branch?.id,
        day_id: day.id,
        slots: [],
        data: {}
    }
}

export const newSlot = (shift, role) => {
    if (!role?.id) {
        console.error(role)
    }
    const num = shift?.slots?.length || 0
    return {
        id: `${shift?.id}_shift_num_${num}`,
        shift_id: shift?.id,
        user_id: null,
        role_id: role.id,
        start: null,
        expected_start: "00:00",
        end: null,
        expected_end: "01:00",
        total_pay: null,
        data: {},
    }
}

export const changeShiftSlotsNumber = (globalState, new_q, shift, shift_num, role, day_num, setGlobalState) => {
    let new_shifts = { ...shift }
    for (let index = 0; index < new_q; index++) {
        if (new_shifts.slots.length < new_q) {
            new_shifts.slots.push(newSlot(new_shifts, role))
        }
    }

    let state = { ...globalState }
    state.currentWeek.days[day_num].shifts[shift_num] = new_shifts
    setGlobalState(state)
}


export const getDayType = (day_num, get_class = false) => {
    let day_type = 0
    if (day_num === 6 || day_num === 5) {
        day_type = 2
    } else if (day_num % 2 === 0) {
        day_type = 1;
    }
    if (get_class) {
        switch (day_type) {
            case 0:
                return 'schedule-day-bgc'
            case 1:
                return 'schedule-day-odd-bgc'
            case 2:
                return 'schedule-day-weekend-bgc'
            default:
                return 'schedule-day-bgc'
        }
    }
    return day_type
};

export const selectedWeekRef = (weekFirstDay, selectedDate) => {
    return Number(((moment(selectedDate) - moment(weekFirstDay)) / 1000 / 60 / 60 / 24 / 7).toFixed(0))
};
